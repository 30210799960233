import React, { useContext, useEffect, useState } from "react";
import "./application-areas-step.component.scoped.scss";
import { Box } from "@material-ui/core";
import TabContentHeadComponent from "../tab-content-head/tab-content-head.component";
import SelectionBox from "../selection-box/selection-box.component";
import { icons } from "../../../../../statics/icons";
import { AIArea } from "../../../types/AI-areas";
import { CreateUseCaseContext } from "../../../../../store";
import { useTranslation } from "react-i18next";
import { Operation } from "../../../types/operations";

const ApplicationAreasStepComponent = (props: any) => {
  const { onError } = props;
  const [t] = useTranslation();
  const { setUseCase, useCase, areas, operations } = useContext(
    CreateUseCaseContext
  );
  const [areaHover, setAreaHover] = useState(-1);
  const [operationHover, setOperationHover] = useState(-1);

  const customizeAreas = (id: number | string) => {
    const existingArea = useCase.areasIds.find(
      (areaId: number) => areaId === id
    );

    if (existingArea !== undefined) {
      const areasCopy = [...useCase.areasIds];
      const newAreas = areasCopy.filter((areaId: number) => areaId !== id);
      setUseCase({ ...useCase, areasIds: [...newAreas] });
    } else {
      const areasIdsCopy = [...useCase.areasIds];
      const area = areas.find((area: AIArea) => area.id === id);
      if (area) areasIdsCopy.push(area.id);
      setUseCase({ ...useCase, areasIds: [...areasIdsCopy] });
    }
  };

  useEffect(() => {
    if (!useCase.areasIds.length || !useCase.operationId) {
      onError(true);
    } else {
      onError(false);
    }
  }, [useCase, onError]);

  return (
    <Box className="content">
      <TabContentHeadComponent
        title={t("USECASE_CREATE.AI_APPLICATION_TITLE")}
        subTitle={t("USECASE_CREATE.AI_APPLICATION_SUB_TITLE")}
      />
      <Box className="mb-4 mx-3">
        <Box className="items">
          {areas.map((item: AIArea, index: number) => (
            <Box
              onMouseOver={() => setAreaHover(index)}
              onMouseLeave={() => setAreaHover(-1)}
            >
              <SelectionBox
                key={item.id}
                title={item.name}
                subTitle={item.description}
                active={
                  areaHover === index ||
                  useCase.areasIds.some((areaId: number) => areaId === item.id)
                }
                // icon={icons.categoryPrimary}
                // activeIcon={icons.categoryWhite}
                icon={
                  item.iconKey
                    ? icons.PAGES.SETTINGS.ICON_OPTIONS[item.iconKey]
                    : icons.categoryPrimary
                }
                activeIcon={
                  item.iconKey
                    ? icons.PAGES.SETTINGS.ICON_OPTIONS[`${item.iconKey}_WHITE`]
                    : icons.categoryWhite
                }
                itemKey={item.id}
                onBoxClick={(id: number | string) => customizeAreas(id)}
              />
            </Box>
          ))}
        </Box>
        <span className="hint">
          {t("USECASE_CREATE.APPLICATION_AREA_HINT")}
        </span>
      </Box>
      <TabContentHeadComponent
        title={t("USECASE_CREATE.AI_OPERATION_TITLE")}
        subTitle={t("USECASE_CREATE.AI_OPERATION_SUB_TITLE")}
        logo={icons.PAGES.SETTINGS.ICON_OPTIONS.HR}
      />
      <Box className="mb-4 mx-3">
        <Box className="items mt-2">
          {operations.map((item: Operation, index: number) => {
            return (
              <Box
                onMouseOver={() => setOperationHover(index)}
                onMouseLeave={() => setOperationHover(-1)}
              >
                <SelectionBox
                  key={item.id}
                  title={item.name}
                  subTitle={item.description}
                  active={
                    useCase.operationId === item.id || operationHover === index
                  }
                  icon={
                    item.iconKey
                      ? icons.PAGES.SETTINGS.ICON_OPTIONS[item.iconKey]
                      : icons.categoryPrimary
                  }
                  activeIcon={
                    item.iconKey
                      ? icons.PAGES.SETTINGS.ICON_OPTIONS[
                          `${item.iconKey}_WHITE`
                        ]
                      : icons.categoryWhite
                  }
                  itemKey={item.id}
                  onBoxClick={(id: number | string) =>
                    setUseCase({ ...useCase, operationId: id as number })
                  }
                />
              </Box>
            );
          })}
        </Box>
        <span className="hint">{t("USECASE_CREATE.OPERATION_AREAS_HINT")}</span>
      </Box>
    </Box>
  );
};

export default ApplicationAreasStepComponent;
