import "./sidenav-items.component.scoped.scss";
import React, { useState, useEffect, useContext } from "react";
import { history } from "react-router-guard";
import { useTranslation } from "react-i18next";
import { CommonService } from "../../services/common/common.service";
import { sideNavItems } from "../../statics/sidenav-items";
import { GlobalContext } from "../../store";
import { icons } from "../../statics/icons";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { ConfirmDialog } from "../../dialogs/confirm-dialog/confirm-dialog";
import { config } from "../../config";
import { UserService } from "../../services/user/user.service";
import { LocalStorageService } from "../../services/local-storage/local-storage";

function getFaviconEl() {
  return document.getElementById("favicon");
}

export const SideNavItemsComponent = () => {
  const commonService: CommonService = new CommonService();
  const userService: UserService = new UserService();
  const localStorageService = new LocalStorageService();
  const [t] = useTranslation();
  const [activeIndex, setActiveIndex] = useState(999);
  const [activeSettings, setActiveSettings] = useState(false);
  const [activeUsers, setActiveUsers] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [sideMenuItemHover, setSideMenuItemHover] = useState(-1);
  const { fullNav, user, setFullNav, setUserTenants } = useContext(
    GlobalContext
  );

  const setActiveTab = () => {
    const routeName = history.location.pathname;
    if (routeName.includes("settings")) {
      setActiveSettings(true);
      setActiveUsers(false);
      setActiveIndex(999);
      commonService.setTitle(t("settings"));
      return;
    }
    if (routeName.includes("users")) {
      setActiveUsers(true);
      setActiveIndex(999);
      setActiveSettings(false);
      commonService.setTitle(t("users"));
      return;
    }
    if (routeName.includes("case/")) {
      // setActiveIndex(1);
      // setActiveSettings(false);
      // setActiveUsers(false);
      commonService.setTitle(t("cases"));
      return;
    }
    sideNavItems.forEach((item, index) => {
      const { title } = item;
      if (routeName.includes(title.toLowerCase())) {
        setActiveIndex(index);
        setActiveSettings(false);
        setActiveUsers(false);
        commonService.setTitle(t(title));
      }
    });
  };

  const openNav = () => {
    setFullNav(true);
    document.getElementsByTagName("body")[0].classList.add("full-nav");
  };

  const closeNav = () => {
    setFullNav(false);
    document.getElementsByTagName("body")[0].classList.remove("full-nav");
  };

  const logout = () => {
    setShowLogoutModal(true);
  };

  const handleLogout = (value: any) => {
    setShowLogoutModal(false);
    if (value) {
      const token = localStorageService.getData("token");
      userService
        .logout({ refresh_token: token.refresh_token })
        .then((res: any) => {
          if (res.message === "Success") {
            const lang = localStorageService.getData("lang");
            localStorageService.clearAll();
            localStorageService.setData("lang", lang);
            const favicon: any = getFaviconEl();
            favicon.href = "/icons/logos/compliance2b.svg";
            history.push("/login");
            setUserTenants([]);
          }
        });
    }
  };

  const navigate = (path: string) => {
    history.push(path);
  };

  useEffect(() => {
    setActiveTab();
    history.listen(() => {
      setActiveTab();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={`sidenav-container ${fullNav ? "full" : ""}`}>
        <div className="sidenav-items">
          {sideNavItems.map((item, index) => {
            const { path, name, icon, iconHover, roles } = item;
            const type = user.type;
            const role = user.role;
            return (
              <div
                key={index}
                className={
                  "sidenav-item " + (activeIndex === index ? "active" : "")
                }
                onMouseOver={() => setSideMenuItemHover(index)}
                onMouseLeave={() => setSideMenuItemHover(-1)}
                onClick={navigate.bind(null, path)}
                style={{
                  display:
                    roles && (roles.includes(role) || roles.includes(type))
                      ? "flex"
                      : "none",
                }}
              >
                <img
                  className="nav-icon"
                  alt={name}
                  src={
                    activeIndex === index || sideMenuItemHover === index
                      ? iconHover
                      : icon
                  }
                />
                {fullNav && <div className="nav-text">{t(name)}</div>}
              </div>
            );
          })}
        </div>
        <div className="sidenav-items">
          {(user.type === "superAdmin" || user.role === "coordinator") && (
            <div
              onMouseOver={() => setSideMenuItemHover(8)}
              onMouseLeave={() => setSideMenuItemHover(-1)}
              className={
                "sidenav-item custom-item " + (activeUsers ? "active" : "")
              }
              onClick={navigate.bind(null, "/users")}
            >
              <img
                className="nav-icon"
                alt="users"
                src={
                  activeUsers || sideMenuItemHover === 8
                    ? icons.users
                    : icons.usersLight
                }
              />
              {fullNav && <div className="nav-text">{t("users")}</div>}
            </div>
          )}
          <div
            onMouseOver={() => setSideMenuItemHover(9)}
            onMouseLeave={() => setSideMenuItemHover(-1)}
            className={
              "sidenav-item custom-item " + (activeSettings ? "active" : "")
            }
            onClick={navigate.bind(null, "/settings")}
          >
            <img
              className="nav-icon"
              alt="settings"
              src={
                activeSettings || sideMenuItemHover === 9
                  ? icons.settings
                  : icons.settingsLight
              }
            />
            {fullNav && <div className="nav-text">{t("settings")}</div>}
          </div>
          <div className="sidenav-item custom-item" onClick={logout}>
            <img className="nav-icon" alt="logout" src={icons.offLight} />
            {fullNav && <div className="nav-text">{t("logout")}</div>}
          </div>
          <div style={{ padding: "0 5px" }}>
            <hr className="separator" />
          </div>
          <div className="expand">
            {!fullNav && <ChevronRightIcon onClick={openNav} />}
            {fullNav && (
              <div className="expand-full" onClick={closeNav}>
                <ChevronLeftIcon />
                <div className="nav-text">{t("collapse")}</div>
              </div>
            )}
          </div>
          <div className="version">
            <p>V{config.appVersion}</p>
          </div>
        </div>
      </div>
      {showLogoutModal && (
        <ConfirmDialog
          show={showLogoutModal}
          onClose={handleLogout}
          dialogTitle={t("confirm logout")}
          dialogBody={t("are you sure you want to logout")}
          confirmBtnText={t("logout")}
          cancelBtn={true}
        />
      )}
    </>
  );
};
