import { icons } from "./icons";
export const CountCards = [
  {
    id: "1",
    title: "TOTAL_AI_APPLICATIONS",
    icon: icons.trackReportPrimary,
  },
  {
    id: "2",
    title: "ACTIVE_AI_APPLICATIONS",
    icon: icons.inProgressPrimary,
  },
  {
    id: "3",
    title: "OPEN_TASKS",
    icon: icons.todoPrimary,
  },
];
