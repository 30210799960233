import React from "react";
import { CaseProvider } from "../../../store";
import { CommonService } from "../../../services/common/common.service";
import { useTranslation } from "react-i18next";
import PrintCaseContentComponent from "../../../modules/case/components/print/print-case-content/print-case-content.component";

export default function PrintCasePage(props: any) {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  commonService.setTitle(t("Print Case"));
  return (
    <CaseProvider>
      <PrintCaseContentComponent />
    </CaseProvider>
  );
}
