import React, { useContext, useEffect, useState } from "react";
import "./AI-classification-step.component.scoped.scss";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import TabContentHeadComponent from "../tab-content-head/tab-content-head.component";
import { AITechnique } from "../../../types/AI-techniques";
import { MachineLearningType } from "../../../types/machine-learning-types";
import { AIAlgorithm } from "../../../types/AI-algorithm";
import SelectionBox from "../selection-box/selection-box.component";
import { icons } from "../../../../../statics/icons";
import { CreateUseCaseContext } from "../../../../../store";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextEditorComponent from "../../../../../components/text-editor/text-editor.component";
import { htmlTagRegex } from "../../../../../statics/validators";

const AIClassificationStepComponent = (props: any) => {
  const { onError } = props;
  const [t] = useTranslation();
  const {
    useCase,
    setUseCase,
    algorithms,
    machineLearningTypes,
    techniques,
    categories,
  } = useContext(CreateUseCaseContext);

  const [textEditor, setTextEditor] = useState(useCase.description);
  const [algorithmHover, setAlgorithmHover] = useState(-1);

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const item = e.target.name as "techniquesIds" | "machineLearningIds";
    const id = Number(e.target.value);
    if (e.target.checked) {
      const itemIdsCopy = [...useCase[item]];
      itemIdsCopy.push(id);
      setUseCase({ ...useCase, [item]: [...itemIdsCopy] });
    } else {
      const itemIdsCopy = [...useCase[item]];
      const newAreas = itemIdsCopy.filter((itemId: number) => itemId !== id);
      setUseCase({ ...useCase, [item]: [...newAreas] });
    }
  };

  useEffect(() => {
    if (
      !useCase.techniquesIds.length ||
      !useCase.machineLearningIds.length ||
      !useCase.description.replace(htmlTagRegex, "").length ||
      useCase.description.replace(htmlTagRegex, "").length > 800 ||
      !useCase.algorithmId ||
      !useCase.categoryId
    ) {
      onError(true);
    } else {
      onError(false);
    }
  }, [useCase, onError]);

  useEffect(() => {
    return () => {
      setTextEditor(useCase.description);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box className="content">
      <TabContentHeadComponent
        title={t("USECASE_CREATE.AI_CLASSIFICATION_TITLE")}
        subTitle={t("USECASE_CREATE.AI_CLASSIFICATION_SUB_TITLE")}
      />
      <Box className="mb-4">
        <Typography variant="h5" color="secondary" className="mb-3">
          {t("USECASE_CREATE.AI_TECHNIQUES_APPROACHES")}
        </Typography>
        <Box className="techniques mx-3">
          {techniques.map((item: AITechnique) => {
            return (
              <FormControlLabel
                key={item.id}
                className="checkbox-global"
                style={{ marginBottom: "0px" }}
                control={
                  <Checkbox
                    checked={useCase.techniquesIds.some(
                      (id: number) => id === item.id
                    )}
                    value={item.id}
                    onChange={handleCheckbox}
                    size="small"
                    name={"techniquesIds"}
                  />
                }
                label={item.name}
              />
            );
          })}
        </Box>
      </Box>
      <Box className="mb-4">
        <Typography variant="h5" color="secondary" className="mb-3">
          {t("USECASE_CREATE.MACHINE_LEARNING_TYPES")}
        </Typography>
        <Box className="machine-types mx-3">
          {machineLearningTypes.map((item: MachineLearningType) => {
            return (
              <FormControlLabel
                key={item.id}
                className="checkbox-global"
                style={{ marginBottom: "0px" }}
                control={
                  <Checkbox
                    checked={useCase.machineLearningIds.some(
                      (id: number) => id === item.id
                    )}
                    value={item.id}
                    onChange={handleCheckbox}
                    size="small"
                    name={"machineLearningIds"}
                  />
                }
                label={item.name}
              />
            );
          })}
        </Box>
      </Box>
      <Box className="mb-4">
        <Typography variant="h5" color="secondary" className="mb-3">
          {t("DESCRIPTION")}
        </Typography>
        <Box className="mx-3">
          <TextEditorComponent
            id="description"
            value={textEditor}
            onChange={(e: any) =>
              setUseCase({
                ...useCase,
                description: e,
              })
            }
            error={useCase.description.replace(htmlTagRegex, "").length > 800}
          />

          {/* <TextField
            id="filled-multiline-flexible"
            label={t("DESCRIPTION")}
            value={useCase.description}
            onChange={(e: any) =>
              setUseCase({
                ...useCase,
                description: e.target.value.replace(/^\s+/g, ""),
              })
            }
            variant="filled"
            className="text-area-multiline w-100"
            fullWidth={true}
            rows={10}
            multiline
            inputProps={{
              maxLength: 800,
            }}
          /> */}

          <Box className="hint d-flex justify-content-between align-items-center">
            <span>{t("USECASE_CREATE.CLASSIFICATION_DESCRIPTION_HINT")}</span>
            <span>
              {useCase.description.replace(htmlTagRegex, "").length}/{800}{" "}
              {t("character")}
            </span>
          </Box>
        </Box>
      </Box>
      <Box className="mb-4">
        <Typography variant="h5" color="secondary" className="mb-3">
          {t("USECASE_CREATE.AI_ALGORITHM")}
        </Typography>
        <Box className="items mx-3">
          {algorithms.map((item: AIAlgorithm, index: number) => {
            return (
              <Box
                onMouseOver={() => setAlgorithmHover(index)}
                onMouseLeave={() => setAlgorithmHover(-1)}
              >
                <SelectionBox
                  key={item.id}
                  title={item.name}
                  subTitle={item.description}
                  // icon={icons.categoryPrimary}
                  // activeIcon={icons.categoryWhite}
                  icon={
                    item.iconKey
                      ? icons.PAGES.SETTINGS.ICON_OPTIONS[item.iconKey]
                      : icons.categoryPrimary
                  }
                  activeIcon={
                    item.iconKey
                      ? icons.PAGES.SETTINGS.ICON_OPTIONS[
                          `${item.iconKey}_WHITE`
                        ]
                      : icons.categoryWhite
                  }
                  active={
                    useCase.algorithmId === item.id || algorithmHover === index
                  }
                  itemKey={item.id}
                  onBoxClick={(id: number | string) =>
                    setUseCase({ ...useCase, algorithmId: id as number })
                  }
                />
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box className="mb-4">
        <Typography variant="h5" color="secondary" className="mb-3">
          {t("SELECT_RISK_LEVEL")}
        </Typography>
        <Box className="mx-3 w-50">
          <FormControl className="select-input select-grey w-100">
            <InputLabel id="category">{t("RISK_LEVELS")}</InputLabel>
            <Select
              labelId="category"
              id="category-select"
              name="categoryId"
              value={useCase.categoryId || ""}
              onChange={(e: any) =>
                setUseCase({ ...useCase, categoryId: e.target.value })
              }
              IconComponent={ExpandMoreIcon}
            >
              {categories.map((item: any, index: any) => {
                return (
                  <MenuItem value={item.id} key={index}>
                    <img
                      className="mr-2"
                      width={20}
                      src={
                        item.iconKey
                          ? icons.PAGES.SETTINGS.ICON_OPTIONS[item.iconKey]
                          : icons.categoryPrimary
                      }
                      alt=""
                    />
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default AIClassificationStepComponent;
