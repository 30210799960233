import React, { useContext, useEffect, useState } from "react";
import "./files-section.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { CaseContext } from "../../../../../../../store";
import FilesDropZone from "../../../../../../../components/files-drop-zone/files-drop-zone.component";
import FilesTable from "../files-table/files-table.component";
import { Box, Typography } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import { icons } from "../../../../../../../statics/icons";
import { CasesService } from "../../../../../../../services/cases/cases.service";
import { FileSizes } from "../../../../../../../statics/file-sizes";
import axios from "axios";
import { dummyFiles } from "../../../../../../../statics/dummy-files";
import { turncate } from "../../../../../../../helpers";
import withReactContent from "sweetalert2-react-content";
import { alertToastConfig } from "../../../../../../../statics/alert-config";
import Swal from "sweetalert2";
import { PDFFile } from "../../../../../../../statics/file-types";

export const FilesSectionComponent = () => {
  const {
    caseDetails,
    filesLoading,
    caseFiles,
    setFilesLoading,
    setCaseFiles,
    setSelectedFile,
  } = useContext(CaseContext);
  const [t] = useTranslation();
  const [files, setFiles] = useState([]);
  const [saving, setSaving] = React.useState(false);

  const maxFiles = 5;
  const emptyArr: any = [];
  const casesService = new CasesService();
  const [filesListProgress, setFilesListProgress] = React.useState(emptyArr);
  // const inputFileRef = useRef(null);

  const submit = async (submittedFiles: any) => {
    if (!saving && submittedFiles.length <= maxFiles) {
      setSaving(true);

      const filesSavedOrCanceled: any[] = [];
      const filesProgress: any[] = [];
      const filesCanceled: any[] = [];
      const filesUploaded: any[] = [];

      if (submittedFiles.length) {
        submittedFiles.forEach((file: any, index: number) => {
          casesService
            .requestSignedUrl(
              file.name,
              getFileInfo(file.type).fileType,
              "files",
              caseDetails.internalId
            )
            .then((response: any) => {
              const { signedRequest, url } = response;
              const cancelTokenSource = axios.CancelToken.source();
              submittedFiles[index].cancelTokenSource = cancelTokenSource;
              submittedFiles[index].url = url;
              casesService
                .uploadToSignedURL(
                  signedRequest,
                  submittedFiles[index],
                  cancelTokenSource.token,
                  (event: any) => {
                    filesProgress[index] = Math.round(
                      (100 * event.loaded) / event.total
                    );
                    setFilesListProgress([
                      ...filesListProgress,
                      ...filesProgress,
                    ]);
                  }
                )
                .then((uploadResponse: any) => {
                  if (uploadResponse === "canceled") {
                    filesSavedOrCanceled.push(file);
                    filesCanceled.push(file);
                    resetData();
                    return;
                  }
                  const type = getFileInfo(file.type).fileType;
                  const name = file.name.split("." + type)[0];
                  const size = `${(file.size / 1024 / 1000).toFixed(2)} MB`;
                  const payload = {
                    caseId: caseDetails.id,
                    name,
                    type,
                    size,
                    url: file.url,
                  };

                  // push uploaded files to save in communication or notes tabs
                  filesUploaded.push(payload);
                  casesService
                    .saveFileAndComment(payload, "", caseDetails.id)
                    .then((success: any) => {
                      filesSavedOrCanceled.push(file);
                      if (
                        filesSavedOrCanceled.length === submittedFiles.length
                      ) {
                        resetData();
                      }
                    });
                });
            });
        });
      }
    }
  };

  const resetData = () => {
    setSaving(false);
    setFilesListProgress([]);
    fetchCaseFiles(caseDetails.id);
  };

  async function fetchCaseFiles(id: number) {
    setFilesLoading(true);
    setCaseFiles(dummyFiles);
    const caseFiles = await casesService.caseFiles(id);
    if (caseFiles.length) setSelectedFile(caseFiles[0]);
    else setSelectedFile(null);
    setTimeout(() => {
      setCaseFiles(caseFiles);
      setFilesLoading(false);
    }, 100);
  }

  // const clickUpload = () => {
  //   if (!saving) {
  //     const element: any = inputFileRef.current;
  //     element.click();
  //   }
  // };

  const validateFiles = async (files: any[]) => {
    const filteredFilesList: any = [];
    const errorsMsg: any[] = [];
    for (const file of files) {
      const { fileType, maxSize } = getFileInfo(file.type);
      const fileName = file.name.split("." + fileType)[0];
      if (!fileType.includes("pdf")) {
        errorsMsg.push(
          `<div class='alert-file-name'>${turncate(
            fileName,
            20
          )}</div> is not allwed, only PDF files`
        );
      } else if (file.size > maxSize) {
        errorsMsg.push(
          `<div class='alert-file-name'>${turncate(
            fileName,
            20
          )}</div> is bigger than max ${fileType.toUpperCase()} file size (${
            maxSize / 1024 / 1000
          } MB)`
        );
      } else {
        const newFile: any = new File(
          [file],
          file.name.replace(/[&/\\#, +()$~%'":*?<>{}]/g, "-"),
          { type: file.type }
        );
        if (fileType.includes("pdf")) {
          filteredFilesList.push(newFile);
        }
      }
    }
    showAlertMsg(errorsMsg);
    const submittedFiles = [...filteredFilesList];
    submit(submittedFiles);
  };

  const showAlertMsg = async (queues: any[]) => {
    const MySwal = withReactContent(Swal);
    for (const message of queues) {
      await MySwal.fire({
        icon: "error",
        title: message,
        ...alertToastConfig,
      });
    }
  };

  const getFileInfo = (type: string): any => {
    switch (type) {
      case "video/mp4":
        return { fileType: "mp4", maxSize: FileSizes.mp4.maxSize };
      case "image/jpeg":
        return { fileType: "jpg", maxSize: FileSizes.jpg.maxSize };
      case "image/png":
        return { fileType: "png", maxSize: FileSizes.png.maxSize };
      case "application/pdf":
        return { fileType: "pdf", maxSize: FileSizes.pdf.maxSize };
      default:
        return { fileType: "", maxSize: 1 };
    }
  };

  useEffect(() => {
    if (!saving && files && files.length) {
      validateFiles(files);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return (
    <FilesDropZone
      onDropFiles={setFiles}
      isSaving={saving}
      acceptTypes={PDFFile}
    >
      <div className="files-tab mb-2">
        {caseFiles && caseFiles.length ? (
          <FilesTable
            files={caseFiles}
            loading={filesLoading}
            mainProcessorId={caseDetails.mainProcessorId}
          />
        ) : (
          <Box>
            <Box className="upload-area">
              <Box component="span">
                <ImageIcon style={{ fontSize: "4rem", color: "#697588" }} />
              </Box>
              <Typography variant="h5" style={{ color: "#697588" }}>
                Drag and Drop
              </Typography>
            </Box>
            <div className="info mt-2">
              <div className="info-icon">
                <img src={icons.info} alt="" />
              </div>
              <Typography className="info-note">
                {t(
                  "HERE_YOU_CAN_ADD_PDF_DOCUMENT_WHICH_DESCRIBES_YOUR_AI_TOOL_IN_DETAILS"
                )}
              </Typography>
            </div>
          </Box>
        )}
      </div>

      {/* <div className="sticky-container">
        <FilesUploadSectionComponent
          tab="files"
          droppedFiles={files}
          label={t("add file and description to document internal processing")}
          onUploadingFiles={setIsSaving}
        />
      </div> */}
    </FilesDropZone>
  );
};
