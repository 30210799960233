import "./chart-doughnut.component.scoped.scss";
import React, { useEffect, useRef, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import Chart from "chart.js";

interface ChartDoughnutComponentProps {
  labels: any[];
  name: string;
  data: any[];
  colors: any[];
}

export const ChartDoughnutComponent = (props: ChartDoughnutComponentProps) => {
  const [label, setLabel] = useState("");
  const [value, setValue] = useState("");
  const chartContainer: any = useRef(null);

  const originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
  Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
    draw: function (...args: any[]) {
      originalDoughnutDraw.apply(this, args);

      const chart = this.chart.chart;
      const ctx = chart.ctx;
      const width = chart.width;
      const height = chart.height;
      const lineHeight = 30;
      ctx.restore();

      ctx.font = "normal normal 300 12px Roboto";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "rgb(128, 128, 128)";

      const label = chart.config.data.label;
      let labelX = Math.round((width - ctx.measureText(label).width) / 2);
      let labelY = height / 2;

      const words = label.split(" ");
      let line = "";
      const lines: any = [];
      const elementWidth = chart.innerRadius * 2;

      for (let n = 0; n < words.length; n++) {
        const testLine = line + words[n] + " ";
        const metrics = ctx.measureText(testLine);
        const testWidth = metrics.width;
        if (testWidth > elementWidth && n > 0) {
          lines.push(line);
          line = words[n] + " ";
        } else {
          line = testLine;
        }
      }

      // Move the center up depending on line height and number of lines
      labelY -= (lines.length / 2) * lineHeight;

      for (let n = 0; n < lines.length; n++) {
        labelX = Math.round((width - ctx.measureText(lines[n]).width) / 2);
        ctx.fillText(lines[n], labelX, labelY);
        labelY += lineHeight;
      }
      //Draw text in center
      ctx.fillText(
        line.length > 18 ? line.substr(0, 13) + "..." : line,
        Math.round(
          (width -
            ctx.measureText(
              line.length > 18 ? line.substr(0, 13) + "..." : line
            ).width) /
            2
        ),
        labelY - 15
      );

      ctx.font = "normal normal 400 36px Roboto";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "rgb(74, 74, 74)";

      const value = chart.config.data.value;
      const valueX = Math.round((width - ctx.measureText(value).width) / 2);
      ctx.fillText(value, valueX, labelY + 15);

      ctx.save();
    },
  });

  const data = {
    labels: [...props.labels],
    datasets: [
      {
        data: [...props.data],
        backgroundColor: [...props.colors],
        hoverBackgroundColor: [...props.colors],
        hoverBorderColor: [...props.colors],
        borderColor: [...props.colors],
        hoverBorderWidth: 10,
      },
    ],
    label: label ? label : props.labels.length ? props.labels[0] : "",
    value: value ? value : props.data.length ? props.data[0] + "%" : "",
  };
  const options = {
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    animation: {
      animateScale: true,
    },
    tooltips: { enabled: false },
    // tooltips: {
    //   callbacks: {
    //     // this callback is used to create the tooltip label
    //     label: function(tooltipItem: any, data: any) {
    //       // get the data label and data value to display
    //       const label = data.labels[tooltipItem.index];
    //       setLabel(label);
    //       setValue(
    //         `${
    //           data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
    //         }%`
    //       );
    //       return `${label}: ${
    //         data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
    //       }%`;
    //     },
    //   },
    // },
    onClick: function (evt: any) {
      const item = chartContainer.current.chartInstance.getElementAtEvent(evt);

      if (item.length) {
        const label = data.labels[item[0]._index];
        setLabel(label);
        setValue(`${data.datasets[0].data[item[0]._index]}%`);
      }
    },
  };
  useEffect(() => {
    Chart.pluginService.register({
      beforeDraw: function (chart: any) {},
    });
  }, []);

  return (
    <div style={{ width: "160px", height: "160px" }}>
      <Doughnut data={data} options={options} ref={chartContainer} />
    </div>
  );
};
