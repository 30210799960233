import React, { createContext, useReducer } from "react";
import GlobalReducer from "../reducers/global.reducer";
import { LocalStorageService } from "../../services/local-storage/local-storage";
import { User } from "../../types/user";
import { WorkspaceForm } from "../../types/workspace-form";
import { OnboardingContactForm } from "../../types/onboarding-contact-form";
const localStorageService: LocalStorageService = new LocalStorageService();

const initialState: any = {
  lang: localStorageService.getData("lang") || "de",
  fullNav: false,
  user: new User(),
  userTenants: [],
  helpData: {},
  helpContent: "",
  notifications: [],
  unseenNotifications: [],
  activeOnboardingStep: 0,
  workspaceForm: new WorkspaceForm(),
  contactInfoForm: new OnboardingContactForm(),
  OnboardingLands: [],
  OnboardingBranches: [],

  OnboardingAreas: [],
  OnboardingAlgorithms: [],
  OnboardingOperations: [],

  OnboardingTechniques: [],
  OnboardingMachineLearning: [],

  OnboardingCategories: [],
  reporterImage: {},
  workspaceImage: {},
  branches: [],
  horizontalIcons: false,
  encodeStatus: 0,
};

export const GlobalContext = createContext(initialState);
export const GlobalProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(GlobalReducer, initialState);

  function setLang(lang: string) {
    dispatch({
      type: "SET_LANG",
      payload: { lang },
    });
  }

  function setHelpData(helpData: any) {
    dispatch({
      type: "SET_HELP_DATA",
      payload: { helpData },
    });
  }

  function setHelpContent(helpContent: string) {
    dispatch({
      type: "SET_HELP_CONTENT",
      payload: { helpContent },
    });
  }
  function setFullNav(fullNav: string) {
    dispatch({
      type: "SET_FULL_NAV",
      payload: { fullNav },
    });
  }

  function setUser(user: User) {
    dispatch({
      type: "SET_USER",
      payload: { user },
    });
  }

  function setNotifications(notifications: any) {
    dispatch({
      type: "SET_NOTIFICATIONS",
      payload: { notifications },
    });
  }

  function setUnseenNotifications(unseenNotifications: any) {
    dispatch({
      type: "SET_UNSEEN_NOTIFICATIONS",
      payload: { unseenNotifications },
    });
  }

  function setUserTenants(userTenants: any) {
    dispatch({
      type: "SET_USER_TENANTS",
      payload: { userTenants },
    });
  }

  function setActiveOnboardingStep(activeOnboardingStep: any) {
    dispatch({
      type: "SET_ACTIVE_STEP",
      payload: { activeOnboardingStep },
    });
  }

  function setWorkspaceForm(workspaceForm: WorkspaceForm) {
    dispatch({
      type: "SET_WORKSPACE_FORM",
      payload: { workspaceForm },
    });
  }

  function setContactInfoForm(contactInfoForm: OnboardingContactForm) {
    dispatch({
      type: "SET_CONTACTINFO_FORM",
      payload: { contactInfoForm },
    });
  }

  function setOnboardingLands(OnboardingLands: any) {
    dispatch({
      type: "SET_ONBOARDING_LANDS",
      payload: { OnboardingLands },
    });
  }

  function setOnboardingBranches(OnboardingBranches: any) {
    dispatch({
      type: "SET_ONBOARDING_BRANCHES",
      payload: { OnboardingBranches },
    });
  }

  function setOnboardingAreas(OnboardingAreas: any) {
    dispatch({
      type: "SET_ONBOARDING_AREAS",
      payload: { OnboardingAreas },
    });
  }
  function setOnboardingAlgorithms(OnboardingAlgorithms: any) {
    dispatch({
      type: "SET_ONBOARDING_ALGORITHMS",
      payload: { OnboardingAlgorithms },
    });
  }

  function setOnboardingOperations(OnboardingOperations: any) {
    dispatch({
      type: "SET_ONBOARDING_OPERATION",
      payload: { OnboardingOperations },
    });
  }

  function setOnboardingTechniques(OnboardingTechniques: any) {
    dispatch({
      type: "SET_ONBOARDING_TECHNIQUES",
      payload: { OnboardingTechniques },
    });
  }
  function setOnboardingMachineLearning(OnboardingMachineLearning: any) {
    dispatch({
      type: "SET_ONBOARDING_MACHINE_LEARNING",
      payload: { OnboardingMachineLearning },
    });
  }

  function setOnboardingCategories(OnboardingCategories: any) {
    dispatch({
      type: "SET_ONBOARDING_CATEGORIES",
      payload: { OnboardingCategories },
    });
  }

  function setReporterImage(reporterImage: any) {
    dispatch({
      type: "SET_REPORTER_IMAGE",
      payload: { reporterImage },
    });
  }

  function setWorkspaceImage(workspaceImage: any) {
    dispatch({
      type: "SET_WORKSPACE_IMAGE",
      payload: { workspaceImage },
    });
  }

  function setBranches(branches: any[]) {
    dispatch({
      type: "SET_BRANCHES",
      payload: { branches },
    });
  }

  function setHorizontalIcons(horizontalIcons: boolean) {
    dispatch({
      type: "SET_HORIZONTAL_ICONS",
      payload: { horizontalIcons },
    });
  }

  function setEncodeStatus(encodeStatus: boolean) {
    dispatch({
      type: "SET_ENCODE_STATUS",
      payload: { encodeStatus },
    });
  }

  return (
    <GlobalContext.Provider
      value={{
        ...state,
        setFullNav,
        setLang,
        setUser,
        setHelpData,
        setHelpContent,
        setNotifications,
        setUnseenNotifications,
        setUserTenants,
        setActiveOnboardingStep,
        setWorkspaceForm,
        setContactInfoForm,
        setOnboardingLands,
        setOnboardingBranches,

        setOnboardingAreas,
        setOnboardingAlgorithms,
        setOnboardingOperations,
        setOnboardingTechniques,
        setOnboardingMachineLearning,

        setOnboardingCategories,
        setWorkspaceImage,
        setReporterImage,
        setBranches,
        setHorizontalIcons,
        setEncodeStatus,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
