import React, { useState } from "react";
import "./workflow-add-todo.component.scoped.scss";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment, { Moment } from "moment";
import "moment/locale/de";
import PersonIcon from "@material-ui/icons/Person";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import { LangService } from "../../../../../../../../services/lang/lang.service";
import { TodoService } from "../../../../../../../../services/todo/todo.service";
import { TodoForm } from "../../../../../../../../types/todo";
import { showToastMsg } from "../../../../../../../../helpers";
import { AddUserDialog } from "../../../../../../../../dialogs/add-user/add-user";
import { icons } from "../../../../../../../../statics/icons";

export default function WorkflowAddTodo(props: any) {
  const { caseQuestionId, updateTodoList, question } = props;
  const [t] = useTranslation();
  const langService: LangService = new LangService();
  const todoService: TodoService = new TodoService();
  const locale = langService.getCurrentLang();
  const dateToday = new Date();
  dateToday.setDate(dateToday.getDate() + 1);
  const emptyObj: any = {};
  moment.locale(locale);

  const [todo, setTodo] = useState(new TodoForm());
  const [selectedUser, setSelectedUser] = useState(emptyObj);

  const [deadline, setDeadline]: any = useState(null);

  const [saving, setSaving] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);

  const handleDeadlineChange = async (date: Moment) => {
    setDeadline(date);
    setTodo({
      ...todo,
      deadline:
        date && date.isValid() && !date.isBefore()
          ? moment(date).format("YYYY-MM-DD")
          : "",
    });
  };

  const handleSelectedUser = (value: any) => {
    if (value.length) {
      setSelectedUser(value[0]);
      setTodo({
        ...todo,
        responsibleId: value[0].id,
      });
    }
  };

  const addTodo = async () => {
    setSaving(true);
    const data = { ...todo };
    data.caseQuestionId = caseQuestionId;
    const response = await todoService.add(data);
    if (response.message === "Success") {
      showToastMsg("success", t("task added successfully"));
      updateTodoList(response.data);
      setTodo(new TodoForm());
      setSelectedUser({});
      setDeadline(null);
    } else {
      showToastMsg("error", "Some thing went wrong!!");
    }
    setSaving(false);
  };

  return (
    <>
      <Box className="content mb-4">
        <Box className="tag">{t("NEW_TASK")}</Box>

        <Box className="todo-content">
          <Box className="left-section">
            <Box className="item">
              <Typography variant="body1">{t("QUESTION")}</Typography>
              <Box className="d-grid" style={{ gap: "1rem" }}>
                <Box className="icon-wrap">
                  <img src={icons.noteAddLight} alt="TODO" />
                </Box>
                <Typography variant="h5">{question}</Typography>
              </Box>
            </Box>

            <Box className="item mt-4">
              <Typography variant="body1">{t("TASK")}</Typography>
              <Box className="form">
                <TextField
                  id="filled-multiline-flexible"
                  value={todo.task}
                  onChange={(e: any) =>
                    setTodo({ ...todo, task: e.target.value })
                  }
                  variant="filled"
                  className="text-area-multiline bg-light w-100"
                  fullWidth={true}
                  rows={5}
                  multiline
                  placeholder={t("describe this task")}
                />
              </Box>
            </Box>

            <Box className="item mt-4">
              <Typography variant="body1">{t("RESPONSIBLE")}</Typography>
              <Box className="user-section w-50">
                <TextField
                  className="w-100"
                  variant="filled"
                  type="text"
                  label={t("add responsible person")}
                  value={
                    selectedUser && selectedUser.firstName
                      ? selectedUser.firstName + " " + selectedUser.lastName
                      : ""
                  }
                  disabled
                  autoComplete="off"
                  name="responsible-person"
                  InputProps={{
                    className: "p-0 bg-white",
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          className="toggle-password"
                          aria-label="responsible-person"
                          onClick={() => setShowUserModal(true)}
                        >
                          <PersonIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
            <Box className="item mt-4">
              <Typography variant="body1">{t("DUE_DATE")}</Typography>
              <Box className="date-section w-50">
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  libInstance={moment}
                  locale={locale}
                >
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="DD/MM/YYYY"
                    autoOk
                    value={deadline}
                    onChange={(date: any): any => handleDeadlineChange(date)}
                    className="date-picker-standard w-100"
                    invalidDateMessage={t("invalid date format")}
                    label={t("SELECT_DUE_DATE")}
                    minDate={dateToday}
                    keyboardIcon={<EventOutlinedIcon />}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="text-right mt-4">
          <Button
            className="btn-primary-theme"
            variant="contained"
            size="small"
            color="primary"
            onClick={addTodo}
            disabled={
              saving || !todo.task || !todo.deadline || !todo.responsibleId
            }
          >
            <img src={icons.checkWhite} alt="" width={20} className="mr-2" />
            {t("CREATE_TASK")}
          </Button>
        </Box>
      </Box>

      <AddUserDialog
        show={showUserModal}
        onClose={() => setShowUserModal(false)}
        isEditable={true}
        isTodo={true}
        onSelectUser={handleSelectedUser}
        user={selectedUser && selectedUser.id ? selectedUser : null}
      />
    </>
  );
}

/* <Box className="todo-content">
<Box className="info">
  <Box className="user-section">
    <TextField
      className="w-100"
      variant="filled"
      type="text"
      label={t("add responsible person")}
      value={
        selectedUser && selectedUser.firstName
          ? selectedUser.firstName + " " + selectedUser.lastName
          : ""
      }
      disabled
      autoComplete="off"
      name="responsible-person"
      InputProps={{
        className: "p-0 bg-white",
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              className="toggle-password"
              aria-label="responsible-person"
              onClick={() => setShowUserModal(true)}
            >
              <PersonIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  </Box>
  <Box className="date-section">
    <MuiPickersUtilsProvider
      utils={MomentUtils}
      libInstance={moment}
      locale={locale}
    >
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="DD/MM/YYYY"
        autoOk
        value={deadline}
        onChange={(date: any): any => handleDeadlineChange(date)}
        className="date-picker-standard w-100"
        invalidDateMessage={t("invalid date format")}
        label={t("SELECT_DUE_DATE")}
        minDate={dateToday}
        keyboardIcon={<EventOutlinedIcon />}
      />
    </MuiPickersUtilsProvider>
  </Box>
</Box>
<Box className="form">
  <TextField
    id="filled-multiline-flexible"
    value={todo.task}
    onChange={(e: any) => setTodo({ ...todo, task: e.target.value })}
    variant="filled"
    className="text-area-multiline bg-light w-100"
    fullWidth={true}
    rows={5}
    multiline
    placeholder={t("describe this task")}
  />
</Box>
<Box className="action">
  <Button
    className="btn-primary-theme w-100"
    variant="contained"
    color="primary"
    onClick={addTodo}
    disabled={saving || !todo.task || !todo.userId || !todo.deadline}
  >
    {t("create")}
  </Button>
</Box>
</Box> */
