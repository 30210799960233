import React, { useContext, useEffect, useState } from "react";
import "./classification-tab-content.component.scoped.scss";
import { Box, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CaseContext } from "../../../../../store";
import { AITechnique } from "../../../types/AI-techniques";
import { MachineLearningType } from "../../../types/machine-learning-types";
import { AIAlgorithm } from "../../../types/AI-algorithm";
import SelectionBox from "../../create/selection-box/selection-box.component";
import { icons } from "../../../../../statics/icons";
import { Skeleton } from "@material-ui/lab";
import TextEditorComponent from "../../../../../components/text-editor/text-editor.component";
import { htmlTagRegex } from "../../../../../statics/validators";

const ClassificationTabContentComponent = () => {
  const [t] = useTranslation();

  const {
    caseDetails,
    algorithms,
    machineLearningTypes,
    techniques,
    classificationLoading,
    setUpdatedCase,
    updatedCaseDetails,
  } = useContext(CaseContext);

  const [editMode, setEditMode] = useState(false);
  const [selectionBoxHover, setAlgorithmHover] = useState(-1);

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const attrIds = e.target.name as "techniquesIds" | "machineLearningIds";
    const attr = attrIds === "techniquesIds" ? "techniques" : "machineLearning";
    const id = Number(e.target.value);

    if (e.target.checked) {
      const itemsIdCopy = [...updatedCaseDetails[attrIds]];
      itemsIdCopy.push(id);

      const itemsCopy = [...updatedCaseDetails[attr]];
      if (attr === "techniques") {
        itemsCopy.push(
          techniques.find((technique: AITechnique) => technique.id === id)
        );
      } else {
        itemsCopy.push(
          machineLearningTypes.find(
            (machineLearning: MachineLearningType) => machineLearning.id === id
          )
        );
      }
      setUpdatedCase({
        ...updatedCaseDetails,
        [attrIds]: [...itemsIdCopy],
        [attr]: [...itemsCopy],
      });
    } else {
      const itemsIdCopy = [...updatedCaseDetails[attrIds]];
      const newItemsId = itemsIdCopy.filter((itemId: number) => itemId !== id);

      const itemsCopy = [...updatedCaseDetails[attr]];
      const newItems = itemsCopy.filter((item: any) => item.id !== id);

      setUpdatedCase({
        ...updatedCaseDetails,
        [attrIds]: [...newItemsId],
        [attr]: [...newItems],
      });
    }
  };

  // const ignoreUpdate = () => {
  //   updatedCaseDetails.description = caseDetails.description;
  //   setUpdatedCase(updatedCaseDetails);
  //   setEditMode(false);
  // };

  const onChangeTextEditor = (description: string) => {
    setUpdatedCase({
      ...updatedCaseDetails,
      description,
    });
  };

  useEffect(() => {
    setEditMode(false);
  }, [caseDetails]);

  return (
    <Box className="classification-tab">
      <Box className="head">
        <Typography variant="h5" className="title">
          {t("USECASE_DETAILS.AI_CLASSIFICATION")}
        </Typography>
        <Typography variant="body2" className="sub-title">
          ({caseDetails.techniquesIds.length} {t("AI_TECHNIQUES")} /{" "}
          {caseDetails.machineLearningIds.length} {t("MACHINE_LEARNING")})
        </Typography>
      </Box>
      <Box className="content">
        <Box>
          <Box className="mb-4">
            <Typography variant="h5" color="secondary" className="mb-3">
              {t("USECASE_CREATE.AI_TECHNIQUES_APPROACHES")}
            </Typography>

            <Box className="techniques mx-3">
              {techniques.map((item: AITechnique, index: number) => {
                return (
                  <Box key={index}>
                    {classificationLoading ? (
                      <Skeleton variant="text" height={30} width="100%" />
                    ) : (
                      <FormControlLabel
                        className="checkbox-global"
                        style={{ marginBottom: "0px" }}
                        control={
                          <Checkbox
                            checked={updatedCaseDetails.techniquesIds.some(
                              (id: number) => id === item.id
                            )}
                            value={item.id}
                            onChange={handleCheckbox}
                            size="small"
                            name={"techniquesIds"}
                          />
                        }
                        label={item.name}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box className="mb-4">
            <Typography variant="h5" color="secondary" className="mb-3">
              {t("USECASE_CREATE.MACHINE_LEARNING_TYPES")}
            </Typography>
            <Box className="machine-types mx-3">
              {machineLearningTypes.map(
                (item: MachineLearningType, index: number) => {
                  return (
                    <Box key={index}>
                      {classificationLoading ? (
                        <Skeleton variant="text" height={30} width="100%" />
                      ) : (
                        <FormControlLabel
                          className="checkbox-global"
                          style={{ marginBottom: "0px" }}
                          control={
                            <Checkbox
                              checked={updatedCaseDetails.machineLearningIds.some(
                                (id: number) => id === item.id
                              )}
                              value={item.id}
                              onChange={handleCheckbox}
                              size="small"
                              name={"machineLearningIds"}
                            />
                          }
                          label={item.name}
                        />
                      )}
                    </Box>
                  );
                }
              )}
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className="mb-4">
            <Typography variant="h5" color="secondary" className="mb-3">
              {t("USECASE_CREATE.AI_ALGORITHM")}
            </Typography>
            <Box className="items mx-3">
              {algorithms.map((item: AIAlgorithm, index: number) => {
                return (
                  <Box
                    key={index}
                    onMouseOver={() => setAlgorithmHover(index)}
                    onMouseLeave={() => setAlgorithmHover(-1)}
                  >
                    {classificationLoading ? (
                      <Skeleton variant="text" height={60} width="100%" />
                    ) : (
                      <SelectionBox
                        title={item.name}
                        subTitle={item.description}
                        icon={
                          item.iconKey
                            ? icons.PAGES.SETTINGS.ICON_OPTIONS[item.iconKey]
                            : icons.categoryPrimary
                        }
                        activeIcon={
                          item.iconKey
                            ? icons.PAGES.SETTINGS.ICON_OPTIONS[
                                `${item.iconKey}_WHITE`
                              ]
                            : icons.categoryWhite
                        }
                        active={
                          updatedCaseDetails.algorithmId === item.id ||
                          selectionBoxHover === index
                        }
                        itemKey={item.id}
                        onBoxClick={(id: number | string) => {
                          const newAlgorithm = algorithms.find(
                            (item: AIAlgorithm) => item.id === id
                          );
                          setUpdatedCase({
                            ...updatedCaseDetails,
                            algorithmId: id as number,
                            algorithm: newAlgorithm,
                          });
                        }}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box className="mt-4">
            {/* <Box className="d-flex justify-content-between align-items-center"> */}
            <Typography variant="h5" color="secondary" className="mb-3">
              {t("DESCRIPTION")}
            </Typography>
            <Box className="mx-3">
              {editMode ? (
                <Box>
                  <TextEditorComponent
                    id="description"
                    value={caseDetails.description}
                    onChange={onChangeTextEditor}
                    error={
                      updatedCaseDetails.description.replace(htmlTagRegex, "")
                        .length > 800
                    }
                  />
                  <Box className="hint d-flex justify-content-between align-items-center">
                    <span>
                      {t("USECASE_CREATE.CLASSIFICATION_DESCRIPTION_HINT")}
                    </span>

                    <span>
                      {
                        updatedCaseDetails.description.replace(htmlTagRegex, "")
                          .length
                      }
                      /{800} {t("character")}
                    </span>
                  </Box>
                </Box>
              ) : (
                <>
                  {classificationLoading ? (
                    <Skeleton variant="text" height={200} width="100%" />
                  ) : (
                    <Box
                      className={`details-section ${
                        editMode ? "edit-mode" : ""
                      }`}
                      onClick={setEditMode.bind(null, true)}
                    >
                      <Typography
                        variant="body1"
                        dangerouslySetInnerHTML={{
                          __html: caseDetails.description,
                        }}
                      ></Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ClassificationTabContentComponent;
