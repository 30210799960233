const questions = () => {
  const questions = [1, 2, 3].map(() => {
    return {
      question: "",
    };
  });
  return questions;
};

export const dummyQuestions = [
  {
    id: 0,
    status: "Planning / Strategy",
    questions: questions(),
  },
  {
    id: -1,
    status: "Concept",
    questions: questions(),
  },
  {
    id: -2,
    status: "Data acquisition and preparation",
    questions: questions(),
  },
  {
    id: -3,
    status: "Model development",
    questions: questions(),
  },
  {
    id: -4,
    status: "Model training",
    questions: questions(),
  },
  {
    id: -5,
    status: "Model validation",
    questions: questions(),
  },
  {
    id: -6,
    status: "Model testing",
    questions: questions(),
  },
  {
    id: -7,
    status: "Deployment and integration",
    questions: questions(),
  },
  {
    id: -8,
    status: "Release / Go-life",
    questions: questions(),
  },
  {
    id: -9,
    status: "Monitoring (incl. controls?)",
    questions: questions(),
  },
  {
    id: -10,
    status: "Maintenance - Keep the system up to date",
    questions: questions(),
  },
  {
    id: -11,
    status: "Retirement and/or redefinition",
    questions: questions(),
  },
  {
    id: -12,
    status: "Redefinition",
    questions: questions(),
  },
];
