import axios from "axios";
import { config } from "../../config";
import { deleteEmptyProp } from "../../helpers";

export class UsersService {
  /*********** List Users ***********/
  public list = async (filter?: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const params = deleteEmptyProp(filter);
      const response = await axios.get(serverUrl + "users", { params });
      return response.data;
    } catch (error) {
      return null;
    }
  };

  public exportList = async (filter?: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const params = deleteEmptyProp(filter);
      const response = await axios.get(serverUrl + "users/download-csv-file", {
        params,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  };

  public activate = async (id: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(`${serverUrl}users/${id}/activate`);
      return response.data;
    } catch (error) {
      return null;
    }
  };

  public deactivate = async (id: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(`${serverUrl}users/${id}/deactivate`);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  public delete = async (id: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(`${serverUrl}users/${id}/delete`);
      return response.data;
    } catch (error) {
      return null;
    }
  };

  public renewTokenValidity = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "users/invite-user-again",
        data
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };
}
