import { localEnvironmentConfig } from "./environment.local";
import { environmentConfig } from "./environment";

declare global {
  interface Window {
    env: {
      REACT_APP_API_URL: string;
      REACT_APP_ENVIRONMENT: string;
      REACT_APP_COMPANY: string;
    };
  }
}

const apiUrl = window.env.REACT_APP_API_URL || process.env.REACT_APP_API_URL;
export const config = apiUrl ? environmentConfig : localEnvironmentConfig;
