import React, { useContext, useEffect, useState } from "react";
import "./coordinators-content.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { icons } from "../../../../../statics/icons";
import { showToastMsg } from "../../../../../helpers";
import { SettingsService } from "../../../../../services/settings/settings.service";
import { GlobalContext } from "../../../../../store";
import CoordinatorsSearch from "../coordinators-search/coordinators-search.component";

export default function CoordinatorsContent() {
  const [t] = useTranslation();
  const settingsService: SettingsService = new SettingsService();
  const { helpData, setHelpContent } = useContext(GlobalContext);

  const emptyArr: any[] = [];

  const [saving, setSaving] = useState(false);
  const [coordinators, setCoordinators] = useState(emptyArr);
  const [coordinatorsChange, setCoordinatorsChange] = useState(false);
  const [coordinatorsError, setCoordinatorsError] = useState(emptyArr);

  useEffect(() => {
    getCoordinators();
    setHelpContent(helpData.AssignmentOfSuperUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [helpData]);

  async function getCoordinators() {
    const res = await settingsService.listCoordinators();
    const mappedCoordinators = mapCoordinators(res);
    setCoordinators(mappedCoordinators);
  }

  const mapCoordinators = (coordinators: any) => {
    const coordinatorsNum = 6;
    const coordinatorsHandel = [];
    for (let i = 0; i < coordinatorsNum; i++) {
      coordinatorsHandel.push(coordinators[i] ? coordinators[i] : {});
    }
    setCoordinatorsError(Array(coordinators.length).fill(false));
    return coordinatorsHandel;
  };

  const handleSelectCoordinator = (user: any, index: number) => {
    const existedCoordinator = coordinators.find(
      (ele: any, i: number) => user.id && ele.id === user.id && index !== i
    );
    if (existedCoordinator) {
      handleError(true, index);
      return;
    }
    setCoordinatorsChange(true);
    const updatedCoordinators = [...coordinators];
    updatedCoordinators[index] = user;
    setCoordinators(updatedCoordinators);
  };

  const handleError = (err: boolean, index: number) => {
    const errors = [...coordinatorsError];
    errors[index] = err;
    setCoordinatorsError(errors);
  };

  const handleRequest = () => {
    const ids: any = [];
    coordinators.forEach((item) => {
      if (item.id) ids.push(item.id);
    });
    return ids;
  };

  const submit = async () => {
    if (coordinatorsError.includes(true) || !coordinatorsChange) {
      return;
    }
    const coordinatorsIds = await handleRequest();
    updateCoordinators(coordinatorsIds);
  };

  async function updateCoordinators(coordinatorsIds: any) {
    setSaving(true);
    await settingsService.assignCoordinators({ usersIds: coordinatorsIds });
    setSaving(false);
    setCoordinatorsChange(false);
    showToastMsg("success", t("Coordinators updated successfully"));
  }

  return (
    <div className="coordinators">
      <div className="section-info">
        <h3>{t("coordinator appointment")}</h3>
        <p>{t("coordinators description")}</p>
      </div>
      <section className="coordinators-section mb-3">
        <div className="row">
          <div className="col-12">
            <div className="coordinators-card">
              <div className="card-content">
                <div className="mt-3 mb-4">
                  <div className="coordinators-content row no-gutters">
                    {coordinators.map((coordinator: any, index: number) => {
                      return (
                        <div className="w-47 mb-2" key={index}>
                          <div className=" d-flex justify-content-between align-items-center">
                            <div className="coordinator-col">
                              <div className="coordinator-name trim-2-lines">
                                {t("coordinator") + " " + (index + 1)}
                              </div>
                            </div>
                            <div className="user-col">
                              <CoordinatorsSearch
                                key={index}
                                coordinator={coordinator}
                                error={coordinatorsError[index]}
                                onCoordinatorChange={(value: any) =>
                                  handleSelectCoordinator(value, index)
                                }
                                onError={(err: boolean) =>
                                  handleError(err, index)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="d-flex justify-content-between mb-4">
        <div className="d-flex">
          <div className="info-icon">
            <img src={icons.info} alt="" />
          </div>
          <div className="attention-text">
            <b>{t("attention")}</b> {t("coordinators attention message")}
          </div>
        </div>
        <div className="footer mt-3">
          <Button
            className="btn-primary-theme btn-save"
            variant="contained"
            color="primary"
            onClick={submit}
            disableRipple={!coordinatorsChange}
            disabled={
              saving || coordinatorsError.includes(true) || !coordinatorsChange
            }
          >
            <CheckIcon /> {t("save changes")}
          </Button>
        </div>
      </div>
    </div>
  );
}
