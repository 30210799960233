import React from "react";
import HeaderComponent from "../../../components/header/header.component";
import { CaseProvider } from "../../../store";
import { CommonService } from "../../../services/common/common.service";
import { useTranslation } from "react-i18next";
import { CaseContentComponent } from "../../../modules/case/components/details/case-content/case-content.component";

const CasePage = (props: any) => {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  const caseId = props.match.params.id;
  commonService.setTitle(t("Case Manager"));
  return (
    <CaseProvider>
      <HeaderComponent />
      <CaseContentComponent caseId={caseId} />
    </CaseProvider>
  );
};

export default CasePage;
