import React, { useEffect } from "react";
import { CreateUseCaseProvider } from "../../../store";
import CreateCaseContentComponent from "../../../modules/case/components/create/create-case-content/create-case-content.component";
import { history } from "react-router-guard";
import { PAGE_ROLES } from "../../../modules/case/types/AI-roles";

const CreateCasePage = (props: any) => {
  useEffect(() => {
    const queryParam = props.location.search;
    if (
      queryParam !== `?role=${PAGE_ROLES.PRODUCTS}` &&
      queryParam !== `?role=${PAGE_ROLES.INVENTORY}` &&
      queryParam !== `?role=${PAGE_ROLES.USER}`
    ) {
      history.push(`/landscape`);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <CreateUseCaseProvider>
      <CreateCaseContentComponent />
    </CreateUseCaseProvider>
  );
};

export default CreateCasePage;
