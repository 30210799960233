export const emailRegex = /^[a-zA-Z0-9][a-zA-Z0-9._-]{1,30}@[a-zA-Z0-9._-]+[a-zA-z0-9]+\.[a-zA-Z]{2,6}$/;
export const passwordRegex = /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*[!@#~`´,:;“"'‘><$%{}^.?/|[\]&§*)(+=._-\s])[äöüÄÖÜßa-zA-Z0-9!@#~`´,:;“"'‘><$%{}^.?/|[\]&§*)(+=._-\s]{8,15}$/;
export const specialChar = /^(?=.*[!@#~`´,:;“"'‘><$%{}^.?/|[\]&§*)(+=._-\s])[äöüÄÖÜßa-zA-Z0-9!@#~`´,:;“"'‘><$%{}^.?/|[\]&§*)(+=._-\s]{1,}$/;
export const capitalChar = /^(?=.*?[A-Z])[äöüÄÖÜßa-zA-Z0-9!@#~`´,:;“"'‘><$%{}^.?/|[\]&§*)(+=._-\s]{1,}$/;
export const char = /^(?=.*?[a-z])[äöüÄÖÜßa-zA-Z0-9!@#~`´,:;“"'‘><$%{}^.?/|[\]&§*)(+=._-\s]{1,}$/;
export const digitChar = /^(?=.*?[0-9])[äöüÄÖÜßa-zA-Z0-9!@#~`´,:;“"'‘><$%{}^.?/|[\]&§*)(+=._-\s]{1,}$/;
export const telephoneRegex = /^([+]{0,1}[0-9-]{7,15})$/;
export const digitOnly = /^[0-9]{1,30}$/;
export const nameRegex = /^[a-zA-ZäöüÄÖÜß_-]{1,30}$/;
export const charactersOnlyRegex = /^[a-zA-Z_]{1,30}$/;
export const stringRegex = /^[a-zA-Z0-9äöüÄÖÜß._-\s]{1,30}$/;
export const domainRegex = /^[a-zA-Z0-9._-]{1,30}$/;
export const configurationRegex = /^[a-z0-9]{1,30}$/;
export const colorHexRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
export const urlRegex = /^((ftp|http|https):\/\/)?(www\.)?([A-z0-9-_]+)\.([A-z]{2,})/;
export const htmlTagRegex = /(<([^>]+)>)/gi;
