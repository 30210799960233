import packageJson from "../../package.json";
const URL = window.location.host.split(".");
URL.shift();
const domain = URL.join(".");
const serverUrl = window.env.REACT_APP_API_URL || process.env.REACT_APP_API_URL;
const environment =
  window.env.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT;
const company = window.env.REACT_APP_COMPANY || process.env.REACT_APP_COMPANY;

export const environmentConfig = {
  environment,
  domain,
  serverUrl,
  company,
  appVersion: packageJson.version,
  predictUrl:
    "https://case-classsifier-staging.compliance2b.com/api/tasks/predict",
};
