import React, { useContext, useState } from "react";
import { Box, Button, Chip, Grid, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import { icons } from "../../../../../statics/icons";
import "./company-details.component.scoped.scss";
import { GlobalContext } from "../../../../../store";

const OnboardingCompanyDetails = ({ nextTab, prevTab }: any) => {
  const {
    OnboardingLands,
    OnboardingBranches,

    OnboardingAreas,
    OnboardingAlgorithms,
    OnboardingOperations,
    OnboardingMachineLearning,
    OnboardingTechniques,

    setOnboardingBranches,
    setOnboardingLands,

    setOnboardingAreas,
    setOnboardingAlgorithms,
    setOnboardingOperations,
    setOnboardingMachineLearning,
    setOnboardingTechniques,

    setWorkspaceForm,
    workspaceForm,
  } = useContext(GlobalContext);
  const [t] = useTranslation();
  const [land, setLand] = useState("");
  const [branch, setBranch] = useState("");
  const [area, setArea] = useState("");
  const [algorithm, setAlgorithm] = useState("");
  const [operation, setOperation] = useState("");
  const [technique, setTechnique] = useState("");
  const [machineLearning, setMachineLearning] = useState("");
  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleChange = (event: any) => {
    const value = event.target.value;
    const name = event.target.name;
    const obj: any = {};
    obj[name] = value;
    setWorkspaceForm({ ...workspaceForm, ...obj });
  };

  const handleAddValueClick = (type: string, value: string, values: any[]) => {
    if (value.length > 0) {
      const newValue = { name: value };
      const valuesCopy = [...values];
      valuesCopy.push(newValue);
      if (type === "land") {
        setOnboardingLands(valuesCopy);
        setLand("");
      } else if (type === "branch") {
        setOnboardingBranches(valuesCopy);
        setBranch("");
      } else if (type === "area") {
        setOnboardingAreas(valuesCopy);
        setArea("");
      } else if (type === "algorithm") {
        setOnboardingAlgorithms(valuesCopy);
        setAlgorithm("");
      } else if (type === "operation") {
        setOnboardingOperations(valuesCopy);
        setOperation("");
      } else if (type === "technique") {
        setOnboardingTechniques(valuesCopy);
        setTechnique("");
      } else if (type === "machineLearning") {
        setOnboardingMachineLearning(valuesCopy);
        setMachineLearning("");
      }
    }
  };

  const handleValueDelete = (type: string, name: string, values: any[]) => {
    const newValues = values.filter((item: any) => item.name !== name);
    if (type === "land") {
      setOnboardingLands(newValues);
    } else if (type === "branch") {
      setOnboardingBranches(newValues);
    } else if (type === "area") {
      setOnboardingAreas(newValues);
    } else if (type === "algorithm") {
      setOnboardingAlgorithms(newValues);
    } else if (type === "operation") {
      setOnboardingOperations(newValues);
    } else if (type === "technique") {
      setOnboardingTechniques(newValues);
    } else if (type === "machineLearning") {
      setOnboardingMachineLearning(newValues);
    }
  };

  return (
    <div>
      <h1 className="main-header px-3">About your Company</h1>
      <div className="sec-header px-3">
        Let’s get started by creating your company at pAIper.
      </div>
      <div className="section-name px-3">Company name</div>
      <div className="d-flex flex-row justify-content-start align-items-center w-100 p-3 mb-4">
        <TextField
          style={{ width: "320px", marginRight: "70px" }}
          label={t("Company name")}
          variant="filled"
          name="clientName"
          value={workspaceForm.clientName}
          // error={!profileForm.firstName ? true : false}
          // onChange={(e: any) =>
          //   setWorkspaceName(e.target.value.replace(/ /g, "_"))
          // }
          onChange={handleChange}
        />
        <div className="notes-col">
          <div className="d-flex">
            <div className="info-icon">
              <img src={icons.info} alt="" />
            </div>
            <div className="info-note">
              {t(
                "This company name is used in all lists and company related titles."
              )}
            </div>
          </div>
        </div>
      </div>

      {/* ///////// land //////////// */}
      <div className="section-name px-3">Lands</div>
      <div className="d-flex flex-wrap justify-content-start align-items-center w-100 p-3 mb-4">
        <div className="">
          <TextField
            style={{ width: "320px", marginRight: "90px" }}
            label={t("Land")}
            variant="filled"
            name="workspace name"
            value={land}
            // error={!pofileForm.firstName ? true : false}
            onChange={(e: any) => setLand(capitalize(e.target.value))}
          />
        </div>
        <div
          onClick={() => handleAddValueClick("land", land, OnboardingLands)}
          className="add-btn"
        >
          <AddIcon style={{ marginRight: "7px" }} />
          Add land
        </div>
        <div className="w-100 d-flex flex-wrap">
          {OnboardingLands.map((item: any, index: number) => {
            return (
              <div key={index} className="mt-3 mx-2">
                <Chip
                  label={item.name}
                  onDelete={handleValueDelete.bind(
                    null,
                    "land",
                    item.name,
                    OnboardingLands
                  )}
                  variant="outlined"
                />
              </div>
            );
          })}
        </div>
      </div>

      {/* ////////////branches////////// */}
      <div className="section-name px-3">Branches</div>
      <div className="d-flex flex-wrap justify-content-start align-items-center p-3 mb-4">
        <div className="">
          <TextField
            style={{ width: "320px", marginRight: "90px" }}
            label={t("Branch")}
            variant="filled"
            name="branch"
            value={branch}
            onChange={(e: any) => setBranch(capitalize(e.target.value))}
          />
        </div>
        <div
          onClick={() =>
            handleAddValueClick("branch", branch, OnboardingBranches)
          }
          className="add-btn"
        >
          <AddIcon style={{ marginRight: "7px" }} />
          Add branch
        </div>
        <div className="w-100 d-flex flex-wrap">
          {OnboardingBranches.map((item: any, index: number) => {
            return (
              <div key={index} className="mt-3 mx-2">
                <Chip
                  label={item.name}
                  onDelete={handleValueDelete.bind(
                    null,
                    "branch",
                    item.name,
                    OnboardingBranches
                  )}
                  variant="outlined"
                />
              </div>
            );
          })}
        </div>
      </div>

      {/* ////////////Areas////////// */}
      <div className="section-name px-3">AI Areas</div>
      <div className="d-flex flex-wrap justify-content-start align-items-center p-3 mb-4">
        <div className="">
          <TextField
            style={{ width: "320px", marginRight: "90px" }}
            label="AI Areas"
            variant="filled"
            name="AI-areas"
            value={area}
            onChange={(e: any) => setArea(capitalize(e.target.value))}
          />
        </div>
        <div
          onClick={() => handleAddValueClick("area", area, OnboardingAreas)}
          className="add-btn"
        >
          <AddIcon style={{ marginRight: "7px" }} />
          Add AI Area
        </div>
        <div className="w-100 d-flex flex-wrap">
          {OnboardingAreas.map((item: any, index: number) => {
            return (
              <div key={index} className="mt-3 mx-2">
                <Chip
                  label={item.name}
                  onDelete={handleValueDelete.bind(
                    null,
                    "area",
                    item.name,
                    OnboardingAreas
                  )}
                  variant="outlined"
                />
              </div>
            );
          })}
        </div>
      </div>

      {/* ////////////Algorithms////////// */}
      <div className="section-name px-3">AI Algorithms</div>
      <div className="d-flex flex-wrap justify-content-start align-items-center p-3 mb-4">
        <div className="">
          <TextField
            style={{ width: "320px", marginRight: "90px" }}
            label="AI Algorithms"
            variant="filled"
            name="AI-Algorithms"
            value={algorithm}
            onChange={(e: any) => setAlgorithm(capitalize(e.target.value))}
          />
        </div>
        <div
          onClick={() =>
            handleAddValueClick("algorithm", algorithm, OnboardingAlgorithms)
          }
          className="add-btn"
        >
          <AddIcon style={{ marginRight: "7px" }} />
          Add AI Algorithm
        </div>
        <div className="w-100 d-flex flex-wrap">
          {OnboardingAlgorithms.map((item: any, index: number) => {
            return (
              <div key={index} className="mt-3 mx-2">
                <Chip
                  label={item.name}
                  onDelete={handleValueDelete.bind(
                    null,
                    "algorithm",
                    item.name,
                    OnboardingAlgorithms
                  )}
                  variant="outlined"
                />
              </div>
            );
          })}
        </div>
      </div>

      {/* ////////////Operations////////// */}
      <div className="section-name px-3">AI Operations</div>
      <div className="d-flex flex-wrap justify-content-start align-items-center p-3 mb-4">
        <div className="">
          <TextField
            style={{ width: "320px", marginRight: "90px" }}
            label="AI Operations"
            variant="filled"
            name="AI-Operations"
            value={operation}
            onChange={(e: any) => setOperation(capitalize(e.target.value))}
          />
        </div>
        <div
          onClick={() =>
            handleAddValueClick("operation", operation, OnboardingOperations)
          }
          className="add-btn"
        >
          <AddIcon style={{ marginRight: "7px" }} />
          Add AI Operation
        </div>
        <div className="w-100 d-flex flex-wrap">
          {OnboardingOperations.map((item: any, index: number) => {
            return (
              <div key={index} className="mt-3 mx-2">
                <Chip
                  label={item.name}
                  onDelete={handleValueDelete.bind(
                    null,
                    "operation",
                    item.name,
                    OnboardingOperations
                  )}
                  variant="outlined"
                />
              </div>
            );
          })}
        </div>
      </div>

      {/* ////////////Techniques////////// */}
      <div className="section-name px-3">AI Techniques</div>
      <div className="d-flex flex-wrap justify-content-start align-items-center p-3 mb-4">
        <div className="">
          <TextField
            style={{ width: "320px", marginRight: "90px" }}
            label="AI Techniques"
            variant="filled"
            name="AI-Techniques"
            value={technique}
            onChange={(e: any) => setTechnique(capitalize(e.target.value))}
          />
        </div>
        <div
          onClick={() =>
            handleAddValueClick("technique", technique, OnboardingTechniques)
          }
          className="add-btn"
        >
          <AddIcon style={{ marginRight: "7px" }} />
          Add AI Technique
        </div>
        <div className="w-100 d-flex flex-wrap">
          {OnboardingTechniques.map((item: any, index: number) => {
            return (
              <div key={index} className="mt-3 mx-2">
                <Chip
                  label={item.name}
                  onDelete={handleValueDelete.bind(
                    null,
                    "technique",
                    item.name,
                    OnboardingTechniques
                  )}
                  variant="outlined"
                />
              </div>
            );
          })}
        </div>
      </div>

      {/* ////////////Machine Learning////////// */}
      <div className="section-name px-3">AI Machine Learning</div>
      <div className="d-flex flex-wrap justify-content-start align-items-center p-3 mb-4">
        <div className="">
          <TextField
            style={{ width: "320px", marginRight: "90px" }}
            label="AI Machine Learning"
            variant="filled"
            name="AI-Machine-Learning"
            value={machineLearning}
            onChange={(e: any) =>
              setMachineLearning(capitalize(e.target.value))
            }
          />
        </div>
        <div
          onClick={() =>
            handleAddValueClick(
              "machineLearning",
              machineLearning,
              OnboardingMachineLearning
            )
          }
          className="add-btn"
        >
          <AddIcon style={{ marginRight: "7px" }} />
          Add AI Machine Learning
        </div>
        <div className="w-100 d-flex flex-wrap">
          {OnboardingMachineLearning.map((item: any, index: number) => {
            return (
              <div key={index} className="mt-3 mx-2">
                <Chip
                  label={item.name}
                  onDelete={handleValueDelete.bind(
                    null,
                    "machineLearning",
                    item.name,
                    OnboardingMachineLearning
                  )}
                  variant="outlined"
                />
              </div>
            );
          })}
        </div>
      </div>

      <div className="btns-wrapper mb-5">
        <Grid container item xs={12} spacing={2}>
          <Grid container item justify="space-between">
            <Box>
              <Button
                variant="text"
                color="primary"
                fullWidth
                onClick={() => {
                  prevTab?.();
                }}
              >
                previous
              </Button>
            </Box>
            <Box width="240px">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  nextTab();
                }}
                disabled={
                  OnboardingLands.length === 0 ||
                  OnboardingBranches.length === 0 ||
                  OnboardingAreas.length === 0 ||
                  OnboardingAlgorithms.length === 0 ||
                  OnboardingTechniques.length === 0 ||
                  OnboardingMachineLearning.length === 0 ||
                  workspaceForm.clientName.length === 0
                }
              >
                next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default OnboardingCompanyDetails;
