import React from "react";
import { Box, Typography } from "@material-ui/core";
import "./print-case-footer.component.scoped.scss";
import { icons } from "../../../../../statics/icons";

interface printCaseFooterProps {}

const PrintCaseFooterComponent = (props: printCaseFooterProps) => {
  return (
    <Box className="content">
      <Box className="d-flex align-items-center">
        <img src={icons.logo} alt="pAIper-one" />
        <Typography>© PAIper ONE GmbH</Typography>
      </Box>
      <img src="/icons/logos/pAIper.png" alt="pAIper-one" />
    </Box>
  );
};

export default PrintCaseFooterComponent;
