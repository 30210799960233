import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./AI-file-upload-section.component.scoped.scss";
import { Box, Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { icons } from "../../../../../statics/icons";
import { formatDate, turncate } from "../../../../../helpers";
import { FileSizes } from "../../../../../statics/file-sizes";
import { PDFFile, fileTypes } from "../../../../../statics/file-types";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { alertToastConfig } from "../../../../../statics/alert-config";
import { useDropzone } from "react-dropzone";
import { CreateUseCaseContext } from "../../../../../store";

const AIFileUploadSection = () => {
  const [t] = useTranslation();
  const { useCase, setUseCase } = useContext(CreateUseCaseContext);
  const inputFileRef = useRef(null);
  const [dropedFiles, setDropedFiles] = useState([]);
  const [filesList, setFilesList] = useState(useCase.files);

  const clickUpload = () => {
    const element: any = inputFileRef.current;
    element.click();
  };

  // async function getImagePreview(file: File) {
  //   return new Promise<any>((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onload = (event: any) => {
  //       const src = reader.result;
  //       resolve(src);
  //     };
  //     reader.readAsDataURL(file);
  //   });
  // }

  const validateFiles = async (files: any[]) => {
    const errorsMsg: any[] = [];
    const filteredFilesList: any = [];
    for (const file of files) {
      const { fileType, maxSize } = getFileInfo(file.type);
      if (!fileType.includes("pdf")) {
        const fileName = file.name.split("." + fileType)[0];
        errorsMsg.push(
          `<div class='alert-file-name'>${turncate(
            fileName,
            20
          )}</div> is not allwed, only PDF files`
        );
      } else if (file.size > maxSize) {
        const fileName = file.name.split("." + fileType)[0];
        errorsMsg.push(
          `<div class='alert-file-name'>${turncate(
            fileName,
            20
          )}</div> is bigger than max ${fileType.toUpperCase()} file size (${
            maxSize / 1024 / 1000
          } MB)`
        );
      } else {
        const newFile: any = new File(
          [file],
          file.name.replace(/[&/\\#, +()$~%'":*?<>{}]/g, "-"),
          { type: file.type }
        );
        if (fileType.includes("pdf")) {
          filteredFilesList.push(newFile);
        }
      }
    }
    showAlertMsg(errorsMsg);
    setFilesList([...filesList, ...filteredFilesList]);
    setUseCase({ ...useCase, files: [...filesList, ...filteredFilesList] });
  };

  const onFileChange = async (e: any) => {
    await validateFiles(e.target.files);
    const element: any = inputFileRef.current;
    element.value = "";
  };

  async function showAlertMsg(queues: any[]) {
    const MySwal = withReactContent(Swal);
    for (const message of queues) {
      await MySwal.fire({
        icon: "error",
        title: message,
        ...alertToastConfig,
      });
    }
  }

  const getFileInfo = (type: string): any => {
    switch (type) {
      case "video/mp4":
        return { fileType: "mp4", maxSize: FileSizes.mp4.maxSize };
      case "audio/mp3":
        return { fileType: "mp3", maxSize: FileSizes.mp3.maxSize };
      case "image/jpeg":
        return { fileType: "jpg", maxSize: FileSizes.jpg.maxSize };
      case "image/png":
        return { fileType: "png", maxSize: FileSizes.png.maxSize };
      case "application/pdf":
        return { fileType: "pdf", maxSize: FileSizes.pdf.maxSize };
      default:
        return { fileType: "", maxSize: 1 };
    }
  };

  const onDeleteFile = (index: number) => {
    const filesListCopy = [...filesList];
    filesListCopy.splice(index, 1);
    setFilesList(filesListCopy);
    setUseCase({ ...useCase, files: filesListCopy });
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length) {
      setDropedFiles(acceptedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    accept: fileTypes,
  });

  useEffect(() => {
    if (dropedFiles && dropedFiles.length) {
      validateFiles(dropedFiles);
    }
    // eslint-disable-next-line
  }, [dropedFiles]);

  return (
    <Box {...getRootProps({ className: "dropzone" })}>
      {isDragActive && (
        <Box className="overlay">
          <Box className="drop-img">
            <img src="/file-upload.png" alt="" />
          </Box>
          <h3 className="drop-title">{t("UPLOAD_FILES")}</h3>
          <p className="drop-text">
            {t("USECASE_CREATE.RELEASE_FILES_TO_UPLOAD_FOR_AI_SCENARIO")}
          </p>
        </Box>
      )}
      <Box className="mb-4">
        <Box className="d-flex justify-content-between align-items-center">
          <Typography variant="h5" color="secondary">
            {t("USECASE_CREATE.AI_CONCEPT_FILE")}
          </Typography>
          <input
            type="file"
            ref={inputFileRef}
            onChange={onFileChange}
            style={{ display: "none" }}
            multiple
            accept={PDFFile}
          />

          <Button
            variant="outlined"
            color="primary"
            className="d-flex align-items-center text-capitalize"
            onClick={clickUpload}
          >
            <img
              src={icons.fileUpload}
              alt="upload"
              className="mr-2"
              height="20"
            />
            {t("FILE_UPLOAD")}
          </Button>
        </Box>
        <Box className="mx-3 mt-2">
          {filesList.map((file: any, index: number) => (
            <Box className="file" key={index}>
              <Box className="title">
                <img
                  height={40}
                  src={icons[getFileInfo(file.type).fileType]}
                  alt=""
                />
                <span>{file.name}</span>
              </Box>
              <Box className="info">
                <span>
                  {file.size / 1000 > 1000
                    ? (file.size / 1000 / 1000).toFixed(1) + "MB"
                    : Math.round(file.size / 1000) + "KB"}
                </span>
                <span>
                  uploaded. {formatDate(file.lastModified, "DD.MM.yyyy HH.mm")}
                </span>
                <Box
                  className="remove"
                  onClick={onDeleteFile.bind(null, index)}
                >
                  <DeleteOutlineIcon />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
        <Box className="upload-area" onClick={clickUpload}>
          <img
            src={icons.fileUpload}
            alt="upload"
            className="mr-2"
            height="30"
          />
          <Typography variant="body1" style={{ color: "var(--grey-text)" }}>
            {t("DRAG_DROP_FILE_UPLOAD")}
          </Typography>
          <span className="hint">Max. {t("FILE_SIZE")}: 2MB</span>
        </Box>
      </Box>
    </Box>
  );
};

export default AIFileUploadSection;
