import React from "react";
import "./icons-list.component.scoped.scss";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { IconOptions, iconOptions } from "../../../types/iconOptions";
import { useTranslation } from "react-i18next";
import { IconOption } from "../../../../../types/iconOption";
import { showToastMsg } from "../../../../../helpers";

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Box>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  head: {
    boxShadow: "none",
    borderBottom: "1px solid #e3e3e3",
  },
  root: {
    flexGrow: 1,
    border: "1px solid #e3e3e3",
    backgroundColor: theme.palette.background.paper,
  },
}));

const IconsListComponent = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const copyIcon = (key: string) => {
    navigator.clipboard
      .writeText(key)
      .then(() => {
        showToastMsg("success", t("ICON_KEY_COPIED_SUCCESSFULLY"));
      })
      .catch((err) => {
        console.error("Failed to copy text!");
      });
  };

  return (
    <Box>
      <Box className={classes.root}>
        <AppBar position="static" color="inherit" className={classes.head}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {iconOptions.map((item: IconOptions, index: number) => {
              return (
                <Tab key={index} label={t(item.name)} {...a11yProps(index)} />
              );
            })}
          </Tabs>
        </AppBar>
        {iconOptions.map((item: IconOptions, index: number) => {
          return (
            <TabPanel value={value} index={index} key={index}>
              <Box className="items">
                {item.icons.map((icon: IconOption, i: number) => {
                  return (
                    <Box
                      key={i}
                      className="box-icon"
                      onClick={() => copyIcon(icon.key)}
                    >
                      <img src={icon.path} alt="" />
                      {icon.name}
                      <span className="overlay"></span>
                      <span className="copy">{t("COPY")}</span>
                    </Box>
                  );
                })}
              </Box>
            </TabPanel>
          );
        })}
      </Box>
    </Box>
  );
};

export default IconsListComponent;
