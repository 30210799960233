import React, { useState } from "react";
import "./forgot-password-content.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { InputComponent } from "../../../../components/input/input.component";
import { Button } from "@material-ui/core";
import { UserService } from "../../../../services/user/user.service";
import { emailRegex } from "../../../../statics/validators";
import { showToastMsg } from "../../../../helpers";

const ForgotPasswordContentComponent = () => {
  const userService: UserService = new UserService();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [t] = useTranslation();

  const handleEmailChange = (event: any) => {
    const value = event.target.value;
    const valid = emailRegex.test(value);
    if (valid) {
      setEmail(value);
      setEmailError("");
    } else {
      setEmailError("invalid-email");
    }
  };

  const handleConfirmClick = async () => {
    const res = await userService.sendUserForgottenPasswordEmail({
      link: `${window.location.origin}/reset-password`,
      email,
    });
    if (res.msg === "success") {
      showToastMsg("success", t("Email sent successfully"));
    } else if (res.msg === "user does not exist") {
      setEmailError("Email existiert nicht");
    } else {
      setEmailError(
        "Etwas ist schief gelaufen, bitte versuchen Sie es später noch einmal"
      );
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && emailError === "" && email.length > 0) {
      handleConfirmClick();
    }
  };

  return (
    <div className="normal-login" onKeyDown={handleKeyDown}>
      <div className="label">{t("Enter your email")}</div>
      <InputComponent
        label="Email"
        icon="user"
        type="email"
        validate={handleEmailChange}
        focused={true}
      />
      {emailError && <div className="error-msg">{t(emailError)}</div>}
      <Button
        variant="contained"
        className="btn btn-primary-theme"
        color="primary"
        onClick={handleConfirmClick}
        disabled={!!emailError || email.length === 0}
      >
        <span className="login-btn-content">{t("confirm")}</span>
      </Button>
    </div>
  );
};

export default ForgotPasswordContentComponent;
