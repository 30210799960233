import "./table-content.component.scoped.scss";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TableHeaderComponent } from "../table-header/table-header.component";
import { TableContentProps } from "../../../types/table-content-props";
import { TableRowComponent } from "../table-row/table-row.component";
import { TableFooterComponent } from "../table-footer/table-footer.component";
import { tableHeightSetter } from "../../../helpers";
import { CasesContext } from "../../../store";
import { Box } from "@material-ui/core";

export const TableContentComponent = (props: TableContentProps) => {
  const [t] = useTranslation();
  const {
    options,
    columns,
    data,
    tools,
    paginate,
    pagination,
    loading,
    reset,
    clickableRow,
    refresh,
    tableName,
  } = props;

  const { setCases, cases } = useContext(CasesContext);

  useEffect(() => {
    tableHeightSetter();
    window.onresize = function () {
      tableHeightSetter();
    };
  }, [loading, data]);

  const selectCase = (checked: boolean, row: any) => {
    for (let item of cases) {
      if (row.id === item.id) {
        item.checked = checked;
      }
    }
    setCases(cases);
  };

  const selectCases = (checked: boolean) => {
    for (let item of cases) {
      item.checked = checked;
    }
    setCases(cases);
  };

  return (
    <Box>
      <Box className="ov">
        <TableHeaderComponent
          columns={columns}
          loading={loading}
          options={options}
          tools={tools}
          selectCases={selectCases}
        />
        <Box className="table-content">
          {data && data.length === 0 && !loading && (
            <Box className="no-result">{t("no data available")}</Box>
          )}
          {data.map((row: any, index: number) => {
            return (
              <TableRowComponent
                key={index}
                data={row}
                columns={columns}
                clickableRow={clickableRow}
                refresh={refresh}
                selectedCase={selectCase}
                tableName={tableName}
              />
            );
          })}
        </Box>
      </Box>
      {!reset && (
        <TableFooterComponent paginate={paginate} pagination={pagination} />
      )}
    </Box>
  );
};
