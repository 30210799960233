import React from "react";
import "./sign-up.page.scoped.scss";
import { AuthProvider } from "../../store";
import { SignUpLayout } from "../../layouts/signup/sign-up.layout";
import { SignUpContentComponent } from "../../modules/auth/components/sign-up-content/sign-up-content.component";

const SignUpPage = (props: any) => {
  const token = props.match.params.id;
  const tenantId = props.match.params.tenantId;
  return (
    <SignUpLayout>
      <AuthProvider>
        <SignUpContentComponent token={token} tenantId={tenantId} />
      </AuthProvider>
    </SignUpLayout>
  );
};

export default SignUpPage;
