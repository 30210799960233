import React from "react";

import "./settings-head.component.scoped.scss";

export default function SettingsHead(props: any) {
  return (
    <>
      <div className="head">
        <h1 className="title">{props.title}</h1>
        <p className="sub-title">{props.subTitle}</p>
      </div>
    </>
  );
}
