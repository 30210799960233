import React from "react";
import "./selection-box.component.scoped.scss";
import { Box, Typography } from "@material-ui/core";

interface SelectionBoxProps {
  title: string;
  subTitle?: string;
  itemKey: string;
  active: boolean;
  icon: string;
  activeIcon: string;
  variant?: "contained" | "outlined";
  onBoxClick: (itemKey: string) => void;
}
const SelectionBox = (props: SelectionBoxProps) => {
  const {
    title,
    subTitle,
    active,
    icon,
    activeIcon,
    itemKey,
    onBoxClick,
    variant = "contained",
  } = props;

  return (
    <Box
      className={`content ${variant} ${active ? "active" : ""}`}
      onClick={onBoxClick && onBoxClick.bind(null, itemKey)}
    >
      {icon && activeIcon && (
        <img src={active ? activeIcon : icon} alt={title} title={title} />
      )}
      <Box className="info">
        <Typography className="title" variant="body1">
          {title}
        </Typography>
        {subTitle && (
          <Typography
            className="sub-title"
            variant="body2"
            style={{ color: active ? "#ffffff" : "var(--grey-text)" }}
          >
            {subTitle}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default SelectionBox;
