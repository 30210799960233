import { icons } from "../../../statics/icons";

export enum ANSWERS {
  YES = "YES",
  NO = "NO",
  OTHER = "OTHER",
}

export interface AnswerOptions {
  id: number;
  label: ANSWERS;
  answer: number;
  name: string;
  roles: string;
}

export const AnswerOptionsList: AnswerOptions[] = [
  {
    id: 1,
    label: ANSWERS.YES,
    answer: 1,
    name: "yesChecked",
    roles: icons.AIDeveloperPrimary,
  },
  {
    id: 2,
    label: ANSWERS.NO,
    answer: 0,
    name: "noChecked",
    roles: icons.AIDeveloperPrimary,
  },
  {
    id: 3,
    label: ANSWERS.OTHER,
    answer: 2,
    name: "otherChecked",
    roles: icons.AIDeveloperPrimary,
  },
];
