import "./chart.component.scoped.scss";
import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { chartBarColor, chartTextColor } from "../../../../statics/charts";
import Chart from "chart.js";
import { useTranslation } from "react-i18next";
import { addLeadingZero } from "../../../../helpers";

interface ChartComponentProps {
  labels: any[];
  tooltipLabel: string;
  name: string;
  data: any[];
}

export const ChartComponent = (props: ChartComponentProps) => {
  const [t] = useTranslation();
  const data = {
    labels: [...props.labels],
    datasets: [
      {
        label: props.tooltipLabel,
        backgroundColor: chartBarColor,
        borderColor: "transparent",
        borderWidth: 1,
        hoverBackgroundColor: chartBarColor,
        hoverBorderColor: "transparent",
        data: [...props.data],
      },
    ],
  };
  const options = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          if (props.tooltipLabel === t("hours")) {
            const duration: any = `${addLeadingZero(
              Math.floor(Math.ceil(tooltipItem.yLabel * 60) / 60)
            )}:${addLeadingZero(Math.ceil(tooltipItem.yLabel * 60) % 60)}`;
            return `${
              data.datasets[tooltipItem.datasetIndex].label
            }: ${duration}`;
          }
          return `${data.datasets[tooltipItem.datasetIndex].label}: ${
            tooltipItem.yLabel
          }`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            tickMarkLength: 0,
            zeroLineColor: "transparent",
            display: false,
          },
          ticks: {
            padding: 13,
            fontColor: chartTextColor,
            fontFamily: "Nunito Sans",
            fontSize: 10,
            beginAtZero: true,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            padding: 26,
            fontColor: chartTextColor,
            fontSize: 10,
            fontFamily: "Nunito Sans",
            beginAtZero: true,
            callback: function (value: any, index: any, values: any) {
              return value;
            },
          },
        },
      ],
    },
  };

  useEffect(() => {
    Chart.pluginService.register({
      beforeDraw: function (chart: any) {},
    });
  }, []);
  return (
    <div
      style={{ height: "160px", width: "100%" }}
      className="d-flex align-items-center"
    >
      <Bar data={data} options={options} />
    </div>
  );
};
