import { TableColumn } from "../../types/table-column";

export const logsColumns: TableColumn[] = [
  {
    type: "logID",
    fieldName: "WORKSPACE_ID",
    value: "prefix",
    width: 10,
    component: "logs",
  },
  {
    type: "email",
    fieldName: "PROCESSOR",
    value: "editorEmail",
    width: 20,
    component: "logs",
  },
  {
    type: "logKey",
    fieldName: "ACTION",
    value: "key",
    width: 40,
    component: "logs",
  },
  {
    type: "date",
    fieldName: "DATE",
    value: "created_at",
    width: 10,
    component: "logs",
  },
  {
    type: "time",
    fieldName: "TIME",
    value: "created_at",
    width: 10,
    component: "logs",
  },
];
