import React, { createContext, useReducer } from "react";
import CreateCaseReducer from "../reducers/create-case.reducer";
import { AIArea } from "../../modules/case/types/AI-areas";
import { AIAlgorithm } from "../../modules/case/types/AI-algorithm";
import { AITechnique } from "../../modules/case/types/AI-techniques";
import { MachineLearningType } from "../../modules/case/types/machine-learning-types";
import { Land } from "../../types/land";
import { Branch } from "../../types/branch";
import { ROLES } from "../../modules/case/types/AI-roles";
import { Operation } from "../../modules/case/types/operations";

interface CreateUseCase {
  role: ROLES | "";
  title: string;
  version: string;
  brief: string;
  description: string;
  scenarioDescription: string;
  link: string;
  algorithmId: number;
  operationId: number;
  categoryId: number;
  landId: number | null;
  branchId: number | null;
  areasIds: number[];
  techniquesIds: number[];
  machineLearningIds: number[];
  files: any[];
  users: any[];
}

const createUseCaseInitValue: CreateUseCase = {
  role: "",
  title: "",
  version: "1",
  brief: "",
  description: "",
  scenarioDescription: "",
  link: "",
  algorithmId: 0,
  operationId: 0,
  categoryId: 0,
  landId: null,
  branchId: null,
  areasIds: [],
  techniquesIds: [],
  machineLearningIds: [],
  files: [],
  users: [],
};

interface InitialState {
  useCase: CreateUseCase;
  loading: boolean;
  lands: Land[];
  branches: Branch[];
  areas: AIArea[];
  algorithms: AIAlgorithm[];
  operations: Operation[];
  techniques: AITechnique[];
  machineLearningTypes: MachineLearningType[];
  categories: any[];
  setUseCase: (useCase: CreateUseCase) => void;
  setLands: (lands: Land[]) => void;
  setBranches: (branches: Branch[]) => void;
  setAreas: (areas: AIArea[]) => void;
  setAlgorithms: (algorithms: AIAlgorithm[]) => void;
  setOperations: (operations: Operation[]) => void;
  setTechniques: (techniques: AITechnique[]) => void;
  setCategories: (categories: any[]) => void;
  setMachineLearningTypes: (
    machineLearningTypes: MachineLearningType[]
  ) => void;
}

const initialState: InitialState = {
  useCase: { ...createUseCaseInitValue },
  loading: false,
  lands: [],
  branches: [],
  areas: [],
  algorithms: [],
  operations: [],
  techniques: [],
  machineLearningTypes: [],
  categories: [],
  setUseCase: (useCase: CreateUseCase) => {},
  setLands: (lands: Land[]) => {},
  setBranches: (branches: Branch[]) => {},
  setAreas: (areas: AIArea[]) => {},
  setAlgorithms: (algorithms: AIAlgorithm[]) => {},
  setOperations: (operations: Operation[]) => {},
  setTechniques: (techniques: AITechnique[]) => {},
  setCategories: (categories: any[]) => {},
  setMachineLearningTypes: (machineLearningTypes: MachineLearningType[]) => {},
};

export const CreateUseCaseContext = createContext(initialState);
export const CreateUseCaseProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(CreateCaseReducer, initialState);

  const setUseCase = (useCase: CreateUseCase) => {
    dispatch({
      type: "SET_USE_CASE",
      payload: { useCase },
    });
  };

  const setLoading = (loading: boolean) => {
    dispatch({
      type: "SET_LOADING",
      payload: { loading },
    });
  };

  const setLands = (lands: Land[]) => {
    dispatch({
      type: "SET_LANDS",
      payload: { lands },
    });
  };

  const setBranches = (branches: Branch[]) => {
    dispatch({
      type: "SET_BRANCHES",
      payload: { branches },
    });
  };

  const setAreas = (areas: AIArea[]) => {
    dispatch({
      type: "SET_AREAS",
      payload: { areas },
    });
  };

  const setAlgorithms = (algorithms: AIAlgorithm[]) => {
    dispatch({
      type: "SET_ALGORITHMS",
      payload: { algorithms },
    });
  };

  const setOperations = (operations: Operation[]) => {
    dispatch({
      type: "SET_OPERATIONS",
      payload: { operations },
    });
  };

  const setTechniques = (techniques: AITechnique[]) => {
    dispatch({
      type: "SET_TECHNIQUES",
      payload: { techniques },
    });
  };

  const setMachineLearningTypes = (
    machineLearningTypes: MachineLearningType[]
  ) => {
    dispatch({
      type: "SET_MACHINE_LEARNING_TYPES",
      payload: { machineLearningTypes },
    });
  };

  const setCategories = (categories: any[]) => {
    dispatch({
      type: "SET_CATEGORIES",
      payload: { categories },
    });
  };

  return (
    <CreateUseCaseContext.Provider
      value={{
        ...state,
        setUseCase,
        setLoading,
        setLands,
        setBranches,
        setAreas,
        setAlgorithms,
        setOperations,
        setTechniques,
        setMachineLearningTypes,
        setCategories,
      }}
    >
      {children}
    </CreateUseCaseContext.Provider>
  );
};
