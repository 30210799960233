import axios from "axios";
import { config } from "../../config";
// const queryString = require('query-string')

export class UserService {
  public updateUserInfo = async (user: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(serverUrl + "users/me", user);
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public addUser = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(serverUrl + `users`, data);
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public updateUser = async (id: number, data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(serverUrl + `users/${id}`, data);
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public addNewUser = async (data: any, tenantId: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + `users/add-user-to-tenant?tenantId=${tenantId}`,
        data
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public addNewUserToKeyCloak = async (
    data: any,
    tenantId: any
  ): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + `users/add-user-to-keycloak?tenantId=${tenantId}`,
        data
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public verifyNewUser = async (data: any, tenantId: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "users/verify-new-user?tenantId=" + tenantId,
        data
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public resetPassword = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "users/reset-password",
        data
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public changePassword = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "users/reset-password",
        data
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };

  public addNewUserToCommonDb = async (
    data: any,
    tenantId: any
  ): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "users/add-user?tenantId=" + tenantId,
        data
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public sendUserActivationEmail = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "users/send-user-activation-email",
        data
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public getUserTenants = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "users/me/tenants");
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public login = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(serverUrl + "users/login", data);
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public logout = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(serverUrl + "users/logout", data);
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public sendUserForgottenPasswordEmail = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "users/send-user-forgotten-password-email",
        data
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public canResetForgottenPassword = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "users/can-reset-forgotten-password",
        data
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public checkTokenValidity = async (
    data: any,
    tenantId: any
  ): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "users/can-set-password?tenantId=" + tenantId,
        data
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };
}
