import React, { useContext } from "react";
import "./files-table.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@material-ui/lab";
import FilesTableRow from "../files-table-row/files-table-row.component";
import { File } from "../../../../../../../types/file";
import { GlobalContext } from "../../../../../../../store";

const FilesTable = (props: any) => {
  const { files, loading } = props;
  const [t] = useTranslation();
  const { user } = useContext(GlobalContext);

  return (
    <div className="files-table-card mr-3">
      {/* Table Columns */}
      <div className="files-table-headlines">
        <div className="table-head-row">
          <div className="file-section">
            {loading ? <Skeleton variant="text" height={15} /> : t("file")}
          </div>
          <div className="user-section">
            {loading ? <Skeleton variant="text" height={15} /> : t("uploaded")}
          </div>
          <div className="action-section">
            {loading ? <Skeleton variant="text" height={15} /> : t("ACTIONS")}
          </div>
        </div>
      </div>
      {/* Table Rows */}
      <div className="files-container">
        {files.map((file: File, index: any) => {
          return (
            <FilesTableRow
              key={index}
              id={file.id}
              index={index}
              file={file.file}
              size={file.size}
              url={file.url}
              type={file.type?.toLowerCase()}
              user={file.user}
              date={file.createdAt}
              loading={loading}
              isAuthorizedUser={
                user.keycloakUserId === file.userId ||
                user.type === "superAdmin" ||
                user.role === "coordinator"
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default FilesTable;
