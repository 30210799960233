export class TodoForm {
  public id?: number;
  public task: string = "";
  public caseQuestionId: number = 0;
  public deadline: string = "";
  public responsibleId: number = 0;
  public status?: number;
  public name?: string;
  public email?: string;
  public created_at?: any;
}
