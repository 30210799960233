import React, { useContext, useState } from "react";
import "./application-areas-tab-content.component.scoped.scss";
import { Box, Typography } from "@material-ui/core";
import SelectionBox from "../../create/selection-box/selection-box.component";
import { useTranslation } from "react-i18next";
import { CaseContext } from "../../../../../store";
import { AIArea } from "../../../types/AI-areas";
import { icons } from "../../../../../statics/icons";
import { Skeleton } from "@material-ui/lab";
import { Operation } from "../../../types/operations";

const ApplicationAreasTabContentComponent = () => {
  const [t] = useTranslation();
  const {
    setUpdatedCase,
    updatedCaseDetails,
    areas,
    loading,
    operations,
  } = useContext(CaseContext);
  const [areaHover, setAreaHover] = useState(-1);
  const [operationHover, setOperationHover] = useState(-1);

  const customizeAreas = (id: number | string) => {
    const existingArea = updatedCaseDetails.areasIds.find(
      (areaId: number) => areaId === id
    );
    if (existingArea !== undefined) {
      const areasIdCopy = [...updatedCaseDetails.areasIds];
      const newAreasId = areasIdCopy.filter((areaId: number) => areaId !== id);

      const areasCopy = [...updatedCaseDetails.areas];
      const newAreas = areasCopy.filter((area: AIArea) => area.id !== id);

      setUpdatedCase({
        ...updatedCaseDetails,
        areasIds: [...newAreasId],
        areas: [...newAreas],
      });
    } else {
      const areasIdsCopy = [...updatedCaseDetails.areasIds];
      const areasCopy = [...updatedCaseDetails.areas];
      const area = areas.find((area: AIArea) => area.id === id);
      if (area) {
        areasIdsCopy.push(area.id);
        areasCopy.push(area);
      }
      setUpdatedCase({
        ...updatedCaseDetails,
        areasIds: [...areasIdsCopy],
        areas: [...areasCopy],
      });
    }
  };

  return (
    <>
      <Typography variant="h5" color="secondary">
        {t("USECASE_CREATE.AI_APPLICATION_AREA_QUESTION")}
      </Typography>

      <Box className="content">
        <Box className="mb-4">
          <Typography
            className="mt-4 mb-3 mx-3 mb-1"
            variant="h5"
            color="secondary"
          >
            {t("USECASE_CREATE.APPLICATION_AREA")}
          </Typography>
          <Box className="items mx-3">
            {areas.map((item: AIArea, index: number) => (
              <Box
                key={index}
                onMouseOver={() => setAreaHover(index)}
                onMouseLeave={() => setAreaHover(-1)}
              >
                {loading ? (
                  <Skeleton variant="text" height={60} width="100%" />
                ) : (
                  <SelectionBox
                    title={item.name}
                    subTitle={item.description}
                    icon={
                      item.iconKey
                        ? icons.PAGES.SETTINGS.ICON_OPTIONS[item.iconKey]
                        : icons.categoryPrimary
                    }
                    active={
                      areaHover === index ||
                      updatedCaseDetails.areasIds.some(
                        (areaId: number) => areaId === item.id
                      )
                    }
                    activeIcon={
                      item.iconKey
                        ? icons.PAGES.SETTINGS.ICON_OPTIONS[
                            `${item.iconKey}_WHITE`
                          ]
                        : icons.categoryWhite
                    }
                    itemKey={item.id}
                    onBoxClick={(id: number | string) => customizeAreas(id)}
                  />
                )}
              </Box>
            ))}
          </Box>
          <span className="hint mx-3">
            {t("USECASE_CREATE.APPLICATION_AREA_HINT")}
          </span>
        </Box>
        <Box className="mb-4">
          <Typography
            className="mt-4 mb-3 mx-3 mb-1"
            variant="h5"
            color="secondary"
          >
            {t("USECASE_CREATE.OPERATION_AREAS")}
          </Typography>
          <Box className="items mx-3 mt-2">
            {operations.map((item: Operation, index: number) => {
              return (
                <Box
                  key={index}
                  onMouseOver={() => setOperationHover(index)}
                  onMouseLeave={() => setOperationHover(-1)}
                >
                  {loading ? (
                    <Skeleton variant="text" height={60} width="100%" />
                  ) : (
                    <SelectionBox
                      title={item.name}
                      subTitle={item.description}
                      icon={
                        item.iconKey
                          ? icons.PAGES.SETTINGS.ICON_OPTIONS[item.iconKey]
                          : icons.categoryPrimary
                      }
                      active={
                        updatedCaseDetails.operationId === item.id ||
                        operationHover === index
                      }
                      activeIcon={
                        item.iconKey
                          ? icons.PAGES.SETTINGS.ICON_OPTIONS[
                              `${item.iconKey}_WHITE`
                            ]
                          : icons.categoryWhite
                      }
                      itemKey={item.id}
                      onBoxClick={(id: number | string) => {
                        const newOperation = operations.find(
                          (item: Operation) => item.id === id
                        );
                        setUpdatedCase({
                          ...updatedCaseDetails,
                          operationId: id as number,
                          operation: newOperation,
                        });
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Box>
          <span className="hint mx-3">
            {t("USECASE_CREATE.OPERATION_AREAS_HINT")}
          </span>
        </Box>
      </Box>
    </>
  );
};

export default ApplicationAreasTabContentComponent;
