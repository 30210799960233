import React from "react";
import { LoginLayout } from "../../layouts/login/login.layout";
import "./login.page.scoped.sass";
import { AuthProvider } from "../../store";
import { LoginContentComponent } from "../../modules/auth/components/login-content/login-content.component";

export default function LoginPage() {
  return (
    <LoginLayout>
      <AuthProvider>
        <LoginContentComponent />
      </AuthProvider>
    </LoginLayout>
  );
}
