import React, { useEffect, useContext } from "react";
import "./coming-soon.page.scoped.scss";
import { CommonService } from "../../services/common/common.service";
import { useTranslation } from "react-i18next";
import HeaderComponent from "../../components/header/header.component";
import { GlobalContext } from "../../store";

export default function ComingSoonPage() {
  const commonService = new CommonService();
  const [t] = useTranslation();
  commonService.setTitle(t("PAGE_META_TITLES.COMING_SOON"));
  const { setHelpContent } = useContext(GlobalContext);

  useEffect(() => {
    setHelpContent("");
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <HeaderComponent />
      <div className="wrapper">
        <img src="fallback.png" className="img" alt="coming soon" />
        <h3 className="title">{t("coming soon")}...</h3>
      </div>
    </>
  );
}
