import React, { useEffect, useState } from "react";
import "./workflow-todo.component.scoped.scss";
import { Box } from "@material-ui/core";
import WorkflowAddTodo from "../workflow-add-todo/workflow-add-todo.component";
import TodoRowComponent from "../../../../../../../todo/components/todo-row/todo-row.component";

interface WorkflowTodoProps {
  answer: number;
  todoList: any[];
  workflowLoading: boolean;
  userRole: string;
  caseQuestionId: number;
  question: string;
  refresh: (itemKey: string) => void;
  onDeleteTodoList: (index: number) => void;
  onUpdateTodoList: (todo: any) => void;
}

const WorkflowTodo = (props: WorkflowTodoProps) => {
  const {
    answer,
    todoList,
    workflowLoading,
    caseQuestionId,
    userRole,
    question,
    refresh,
    onUpdateTodoList,
  } = props;

  const [data, setData] = useState(todoList);

  useEffect(() => {
    setData(todoList);
  }, [todoList]);

  return (
    <Box className="todo-wrapper px-5 py-3">
      <Box className="d-flex flex-column w-100">
        <Box className="mb-4">
          {answer === null && userRole !== "auditor" ? (
            <WorkflowAddTodo
              question={question}
              caseQuestionId={caseQuestionId}
              loading={workflowLoading}
              updateTodoList={(newTodo: string) => {
                onUpdateTodoList(newTodo);
              }}
            />
          ) : null}
        </Box>

        <Box id="todo__list__data">
          {data.map((todo: any, index: any) => {
            return (
              <TodoRowComponent refresh={refresh} key={index} data={todo} />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default WorkflowTodo;
