import React, { useContext, useState } from "react";
import { Button, LinearProgress, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CropImage from "./crop-image.component";
import { LookupsService } from "../../services/lookups/lookups.service";
import { Modal } from "react-bootstrap";
import { GlobalContext } from "../../store";
import { base64ToFile } from "../../helpers";

const UploadPhoto: React.FunctionComponent<{
  image: string | ArrayBuffer | null | undefined;
  imageType: string;
  imageName: string;
  onUploadSuccess: (fileKey: string, imageStream: string) => void;
  cropShape?: "rectangle" | "circle";
  handleClose: (res?: any) => void;
  open: boolean;
}> = ({
  handleClose,
  onUploadSuccess,
  open,
  image,
  imageType,
  imageName,
  cropShape = "circle",
}) => {
  const { t } = useTranslation();
  const { user } = useContext(GlobalContext);
  const [saving, setSaving] = useState(false);
  const lookupsService = new LookupsService();
  const [crop, setCrop] = useState<boolean>(false);
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const upload = async (imageStream: string) => {
    setSaving(true);
    setProgress(1);
    const { signedUrl, url } = await lookupsService.requestSignedUrl({
      path: "profile",
      id: user.firstName,
      fileName: imageName,
    });
    const file = await base64ToFile(imageStream, imageName, imageType);
    const uploadRes = await lookupsService.uploadToSignedURL(
      signedUrl,
      file,
      (progressEvent: { loaded: number; total: number }) => {
        const fileProgress = Math.round(
          (100 * progressEvent?.loaded) / progressEvent?.total
        );
        setProgress(fileProgress);
      }
    );
    if (uploadRes !== 0) {
      onUploadSuccess(url, imageStream);
      handleClose();
    }
    setCrop(false);
    setSaving(false);
  };

  return (
    <>
      <Modal
        show={open}
        onHide={handleClose.bind(null, false)}
        centered
        dialogClassName="modal-lg"
      >
        {progress && (
          <Box className="w-2100">
            <LinearProgress
              variant="determinate"
              value={progress}
              color="secondary"
            />
          </Box>
        )}
        <Modal.Header closeButton>
          <Modal.Title>{"upload image"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CropImage
            image={image}
            imageType={imageType}
            onImageCrop={upload}
            crop={crop}
            isSaving={saving}
            cropShape={cropShape}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={() => setCrop(true)}
            disabled={saving}
          >
            {t("CONTINUE")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadPhoto;
