import React, { useContext, useEffect } from "react";
import "./case-content.component.scoped.scss";
import CaseHeaderComponent from "../case-header/case-header.component";
import { Box } from "@material-ui/core";
import CaseTabsComponent from "../tabs/case-tabs.component";
import ScenarioTabContentComponent from "../scenario-tab-content/scenario-tab-content.component";
import { CaseTabContent } from "../../../types/case-tabs";
import ApplicationAreasTabContentComponent from "../application-areas-tab-content/application-areas-tab-content.component";
import ClassificationTabContentComponent from "../classification-tab-content/classification-tab-content.component";
import DiscussionTabContentComponent from "../discussion-tab-content/discussion-tab-content.component";
import TeamTabContentComponent from "../team-tab-content/team-tab-content.component";
import TodoTabContentComponent from "../todoList-tab-content/todoList-tab-content.component";
import { CasesService } from "../../../../../services/cases/cases.service";
import { MapperService } from "../../../../../services/mapper/mapper.service";
import { CommonService } from "../../../../../services/common/common.service";
import { CaseContext, UsersProvider } from "../../../../../store";
import { useTranslation } from "react-i18next";
import { dummyQuestions } from "../../../../../statics/dummy-questions";
import { history } from "react-router-guard";
import { showToastMsg } from "../../../../../helpers";
import { LookupsService } from "../../../../../services/lookups/lookups.service";
import { dummyUseCase } from "../../../../../statics/dummy-usecase";
import CaseActionsComponent from "../case-actions/case-actions.component";
import PrintCaseContentComponent from "../../print/print-case-content/print-case-content.component";
import { Case } from "../../../../../types/case";
import { dummyFiles } from "../../../../../statics/dummy-files";

export const CaseContentComponent: React.FunctionComponent<{
  caseId: number;
}> = ({ caseId }) => {
  const [t] = useTranslation();
  const casesService = new CasesService();
  const lookupsService = new LookupsService();
  const mapperService = new MapperService();
  const commonService: CommonService = new CommonService();
  // const emptyArr: any = [];
  // const [cachedStatuses, setCachedStatuses] = useState(emptyArr);
  const {
    setCase,
    setUpdatedCase,
    setLoading,
    setClassificationLoading,
    setWorkflowLoading,
    setCaseUsers,
    setCaseCategories,
    setLands,
    setBranches,
    setCaseStatuses,
    setCaseQuestions,
    setCaseTotalQuestions,
    setCaseTotalAnsweredQuestions,
    setActiveTab,
    setCaseDetailsUpdated,
    setAreas,
    setAlgorithms,
    setOperations,
    setTechniques,
    setMachineLearningTypes,
    setUsersLoading,
    activeTab,
    caseDetailsUpdated,
    caseDetails,
    setCaseFiles,
    setFilesLoading,
    setSelectedFile,
  } = useContext(CaseContext);

  ////////////////////////////////////////////////////////////
  const fetchCaseDetails = async (id: number) => {
    setLoading(true);
    setCase(dummyUseCase);
    const caseDetailsData = await casesService.details(id);
    if (caseDetailsData === "Not found") {
      history.push("/dashboard");
      showToastMsg("error", t("case not found"));
      return;
    }
    commonService.setTitle(caseDetailsData.internalId);
    const statuses = await casesService.listStatuses();
    const mappedStatuses = mapperService.mapStatuses(statuses);

    const { mappedCase, mappedtimelineStatuses } = mapperService.mapCase(
      caseDetailsData,
      mappedStatuses
    );
    setCase(mappedCase);
    resetChanges(mappedCase);
    setCaseStatuses(mappedtimelineStatuses);
    // setCachedStatuses(deepClone(mappedtimelineStatuses));
    setLoading(false);
  };

  const fetchCaseQuestions = async (id: number) => {
    setWorkflowLoading(true);
    setCaseQuestions(dummyQuestions);
    const caseQuestions = await casesService.caseQuestions(id);
    const {
      totalQuestions,
      totalAnsweredQuestions,
      formattedCaseQuestions,
    } = mapperService.mapCaseQuestions(caseQuestions);
    setCaseTotalQuestions(totalQuestions);
    setCaseTotalAnsweredQuestions(totalAnsweredQuestions);
    setCaseQuestions(formattedCaseQuestions);

    setTimeout(() => {
      setWorkflowLoading(false);
    }, 100);
  };

  const fetchCaseFiles = async (id: number) => {
    setFilesLoading(true);
    setCaseFiles(dummyFiles);
    const caseFiles = await casesService.caseFiles(id);
    if (caseFiles.length) setSelectedFile(caseFiles[0]);
    setTimeout(() => {
      setCaseFiles(caseFiles);
      setFilesLoading(false);
    }, 100);
  };

  const resetChanges = (mappedCase?: Case) => {
    setUpdatedCase({
      version: mappedCase?.version || caseDetails.version,
      title: mappedCase?.title || caseDetails.title,
      brief: mappedCase?.brief || caseDetails.brief,
      description: mappedCase?.description || caseDetails.description,
      scenarioDescription:
        mappedCase?.scenarioDescription || caseDetails.scenarioDescription,
      link: mappedCase?.link || caseDetails.link,
      algorithmId: mappedCase?.algorithmId || caseDetails.algorithmId,
      operationId: mappedCase?.operationId || caseDetails.operationId,
      areasIds: mappedCase?.areasIds || caseDetails.areasIds,
      techniquesIds: mappedCase?.techniquesIds || caseDetails.techniquesIds,
      machineLearningIds:
        mappedCase?.machineLearningIds || caseDetails.machineLearningIds,
      areas: mappedCase?.areas || caseDetails.areas,
      machineLearning:
        mappedCase?.machineLearning || caseDetails.machineLearning,
      techniques: mappedCase?.techniques || caseDetails.techniques,
    });
  };
  ///////////////////////START LOOKUPS DATA/////////////////////////////////
  const getUsers = async (id: number, params?: any) => {
    setCaseUsers([]);
    setUsersLoading(true);
    const usersResult = await casesService.getUsers(id, params);
    setCaseUsers(usersResult);
    setUsersLoading(false);
  };

  const getCaseCategories = async () => {
    const categories = await casesService.getAssignedCategories();
    setCaseCategories(categories);
  };

  const getAlgorithms = async () => {
    setAlgorithms(Array.from({ length: 6 }));
    const algorithms = await lookupsService.listAIAlgorithms();
    setAlgorithms(algorithms);
  };

  const getOperations = async () => {
    setOperations(Array.from({ length: 6 }));
    const operations = await lookupsService.listOperation();
    setOperations(operations);
  };

  const getTechniques = async () => {
    setTechniques(Array.from({ length: 8 }));
    const techniques = await lookupsService.listAITechniques();
    setTechniques(techniques);
  };

  const getMachineLearning = async () => {
    setMachineLearningTypes(Array.from({ length: 8 }));
    const machineLearningTypes = await lookupsService.listMachineLearningTypes();
    setMachineLearningTypes(machineLearningTypes);
  };

  const getLands = async () => {
    const lands = await lookupsService.listLands();
    setLands(lands);
  };

  const getAreas = async () => {
    setAreas(Array.from({ length: 12 }));
    const areas = await lookupsService.listAIAreas();
    setAreas(areas);
  };

  const getBranches = async () => {
    const branches = await lookupsService.listBranches();
    setBranches(branches);
  };

  // const getCaseAmounts = async () => {
  //   const amounts = await casesService.getCaseAmounts();
  //   const mappedAmounts = mapperService.mapAmounts(amounts);
  //   setCaseAmounts(mappedAmounts);
  // };

  // const getCaseRelations = async () => {
  //   const relations = await casesService.getCaseRelations();
  //   const mappedRelations = await mapperService.mapRelations(relations);
  //   setCaseRelations(mappedRelations);
  // };
  ///////////////////////END LOOKUPS DATA/////////////////////////////////

  ///////////////////////START TABS DATA/////////////////////////////////
  const fetchDiscussionTab = async () => {
    fetchCaseQuestions(caseId);
  };

  const fetchClassificationTab = async () => {
    setClassificationLoading(true);
    await Promise.all([getAlgorithms(), getTechniques(), getMachineLearning()]);
    setClassificationLoading(false);
  };
  ///////////////////////END TABS DATA/////////////////////////////////

  useEffect(() => {
    resetChanges();
    if (activeTab === 1) {
      // Scenario Tab
      // fetchScenarioTab();
    } else if (activeTab === 3) {
      // Classification tab
      fetchClassificationTab();
    } else if (activeTab === 4) {
      // Discussion Tab
      fetchDiscussionTab();
    } else if (activeTab === 5) {
      // Team Tab
      getUsers(caseId);
    }
    // eslint-disable-next-line
  }, [activeTab]);

  useEffect(() => {
    if (caseDetailsUpdated) {
      fetchCaseDetails(caseId);
      setCaseDetailsUpdated(false);
    }
    // eslint-disable-next-line
  }, [caseDetailsUpdated]);

  useEffect(() => {
    fetchCaseDetails(caseId);
    fetchCaseFiles(caseId);
    getUsers(caseId);
    getCaseCategories();
    // getCaseAmounts();
    // getCaseRelations();
    getLands();
    getBranches();
    getAreas();
    getOperations();
    // eslint-disable-next-line
  }, []);
  ////////////////////////////////////////////////////////////

  const caseTabContent: CaseTabContent[] = [
    {
      tab: 1,
      component: <ScenarioTabContentComponent />,
    },
    {
      tab: 2,
      component: <ApplicationAreasTabContentComponent />,
    },
    {
      tab: 3,
      component: <ClassificationTabContentComponent />,
    },
    {
      tab: 4,
      component: <DiscussionTabContentComponent />,
    },
    {
      tab: 5,
      component: (
        <UsersProvider>
          <TeamTabContentComponent sortUsers={getUsers} />
        </UsersProvider>
      ),
    },
    {
      tab: 6,
      component: <TodoTabContentComponent />,
    },
    // {
    //   tab: 7,
    //   component: <Box></Box>,
    // },
  ];
  const tabContent = caseTabContent[activeTab - 1].component;

  return (
    <>
      <Box className="content">
        <Box className="header-content">
          <CaseHeaderComponent />
        </Box>
        <Box className="case-content mb-0">
          <Box className="info">
            <Box className="tabs">
              <CaseTabsComponent
                onChangeTab={(tab: number) => setActiveTab(tab)}
              />
            </Box>
            <Box style={{ padding: "20px 15px" }}>{tabContent}</Box>
          </Box>
          <Box className="case-actions">
            <CaseActionsComponent />
          </Box>
        </Box>
      </Box>
      <Box className="print" id="print-usecase">
        <PrintCaseContentComponent />
      </Box>
    </>
  );
};
