import React from "react";
import "./ai-products-content.component.scoped.scss";
import { Box } from "@material-ui/core";
import CasesHeadComponent from "../cases-head/cases-head.component";
import { icons } from "../../../../../statics/icons";
import { useTranslation } from "react-i18next";
import CasesContent from "../cases-content/cases-content.component";
import { PAGE_ROLES } from "../../../types/AI-roles";

const AIProductsContentComponent = () => {
  const [t] = useTranslation();

  return (
    <Box>
      <CasesHeadComponent
        title={t("PAGES_HEAD.AI_PRODUCT_TITLE")}
        subTitle={t("PAGES_HEAD.AI_PRODUCT_SUB_TITLE")}
        logo={icons.PAGES.CASES.AIProducts}
        role={PAGE_ROLES.PRODUCTS}
      />
      <CasesContent role={PAGE_ROLES.PRODUCTS} />
    </Box>
  );
};

export default AIProductsContentComponent;
