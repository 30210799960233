import React, { useContext, useEffect, useState } from "react";
import "./todoList-tab-content.component.scoped.scss";
import { Box, Typography } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { CaseContext } from "../../../../../store";
import { TodoService } from "../../../../../services/todo/todo.service";
import TodoRowComponent from "../../../../todo/components/todo-row/todo-row.component";
import { useTranslation } from "react-i18next";
import { MapperService } from "../../../../../services/mapper/mapper.service";

const TodoTabContentComponent = () => {
  const [t] = useTranslation();
  const { caseDetails, setCase } = useContext(CaseContext);

  const todoService = new TodoService();
  const mapperService = new MapperService();
  const emptyObj: any = {};
  const emptyArr: any = [];
  const [todoList, setTodoList] = useState(emptyArr);
  const [counts, setCounts] = useState(emptyObj);
  const [loading, setLoading] = useState(true);

  const fetchTodos = async (id: number) => {
    setLoading(true);
    setTodoList(emptyArr);
    const response = await todoService.listByCase(id);
    const { readyTodos, counts } = mapperService.mapTodoList(response);
    setCase({
      ...caseDetails,
      tasks: counts.pending + counts.completed,
      completedTasks: counts.completed,
    });
    setCounts(counts);
    setTodoList(readyTodos);
    setLoading(false);
  };

  useEffect(() => {
    const caseId = caseDetails.id;
    fetchTodos(caseId);
    // eslint-disable-next-line
  }, []);

  const refresh = () => {
    const caseId = caseDetails.id;
    fetchTodos(caseId);
  };

  return (
    <Box className="todo-wrapper">
      <Box className="d-flex justify-content-between align-center">
        <Box className="info">
          <Typography variant="h5" color="secondary" className="d-inline mr-2">
            {t("overview of the created todos")}
          </Typography>
          <Typography
            variant="body2"
            className="d-inline"
            style={{ color: "rgb(176, 176, 176)" }}
          >
            {counts.pending + counts.completed || 0} ToDos ({counts.pending}{" "}
            {t("pending")} / {counts.completed} {t("completed")})
          </Typography>
        </Box>
      </Box>
      <Box className="todo-contain mt-4">
        {loading && todoList && !todoList.length && (
          <Box className="loader-area">
            <CircularProgress />
          </Box>
        )}
        {todoList && todoList.length === 0 && !loading && (
          <Box className="no-result">{t("no data available")}</Box>
        )}
        {todoList.map((row: any, index: number) => {
          return <TodoRowComponent refresh={refresh} key={index} data={row} />;
        })}
      </Box>
    </Box>
  );
};
export default TodoTabContentComponent;
