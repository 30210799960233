import React from "react";
import { useTranslation } from "react-i18next";

import { CommonService } from "../../../services/common/common.service";
import { LogsProvider } from "../../../store";
import { LogfileContent } from "../../../modules/settings/components/logfile/logfile-content/logfile.component";
import SettingsHead from "../../../modules/settings/shared/settings-head/settings-head.component";

export default function LogfilePage() {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  commonService.setTitle(t("logfile"));

  return (
    <LogsProvider>
      <div className="role-contain">
        <div className="content">
          <SettingsHead
            title={t("logfile")}
            subTitle={t("logfile description")}
          />
          <LogfileContent />
        </div>
      </div>
    </LogsProvider>
  );
}
