import React, { useEffect, useState } from "react";
import { RouterGuard } from "react-router-guard";
import router from "./router";
import "./app.component.scoped.scss";
// import "@mantine/tiptap/styles.css";
import { GlobalProvider } from "./store";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DashboardService } from "./services/dashboard/dashboard.service";
import { ServerMessage } from "./components/server-message/server-message.component";

function App() {
  const dashboardService: DashboardService = new DashboardService();

  const empObj: any = {};
  const [configuration, setConfiguration] = useState(empObj);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#a18dba",
        light: "#f2ecfa",
        dark: "#5e057e",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: "#5a5c4f",
        light: "#5a5c4f",
        dark: "#5a5c4f",
        contrastText: "#FFFFFF",
      },
    },
    overrides: {
      MuiInputBase: {
        input: {
          "&::placeholder": {
            color: "rgb(128, 128, 128)",
            opacity: 1,
          },
        },
      },
    },
    typography: {
      h1: {
        fontWeight: 400,
        fontSize: "1.8rem",
      },
      h2: {
        fontWeight: 400,
        fontSize: "1.7rem",
      },
      h3: {
        fontWeight: 400,
        fontSize: "1.6rem",
      },
      h4: {
        fontWeight: 400,
        fontSize: "1.5rem",
      },
      h5: {
        fontWeight: 400,
        fontSize: "1.4rem",
      },
      h6: {
        fontWeight: 400,
        fontSize: "1.3rem",
      },
      subtitle1: {
        fontWeight: 300,
        fontSize: 18,
      },
      subtitle2: {
        fontWeight: 300,
        fontSize: 16,
      },
      body1: {
        fontSize: 14,
        fontWeight: 300,
      },
      body2: {
        fontSize: 12,
        fontWeight: 300,
      },
      caption: {
        fontSize: 12,
      },
    },
  });

  const getConfiguration = async () => {
    const response = await dashboardService.getConfiguration();
    setConfiguration(response);
  };

  const close = () => {
    setConfiguration({ ...configuration, system_availability: 0 });
  };

  useEffect(() => {
    getConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GlobalProvider>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ThemeProvider theme={theme}>
          <div className="main-wrapper">
            {configuration && !!+configuration.system_availability && (
              <ServerMessage close={close} />
            )}
            <RouterGuard config={router} />
          </div>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </GlobalProvider>
  );
}

export default App;
