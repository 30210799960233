import React, { useState } from "react";
import "./workflow-add-comment.component.scoped.scss";
import { Box } from "@material-ui/core";
import WorkflowAddCommentFormComponent from "../workflow-add-comment-form/workflow-add-comment-form.component";
import FilesDropZone from "../../../../../../../../components/files-drop-zone/files-drop-zone.component";
import { imageTypes } from "../../../../../../../../statics/file-types";

const WorkflowAddComment = (props: any) => {
  const { updateComments, questionId } = props;
  const [files, setFiles] = useState([]);

  return (
    <Box className="comment-wrapper mx-1">
      <FilesDropZone onDropFiles={setFiles} acceptTypes={imageTypes}>
        <WorkflowAddCommentFormComponent
          questionId={questionId}
          droppedFiles={files}
          onAddedComment={(res: any) => updateComments(res.comment)}
        />
      </FilesDropZone>
    </Box>
  );
};

export default WorkflowAddComment;
