import React from "react";
import { Box, Typography } from "@material-ui/core";
import "./print-case-header.component.scoped.scss";

interface printCaseHeaderProps {}

const PrintCaseHeaderComponent = (props: printCaseHeaderProps) => {
  return (
    <Box className="content">
      <Typography variant="h6" color="primary">
        AI Conformity Declaration
      </Typography>
      <img src="/icons/logos/pAIper-dark.png" alt="pAIper-one" />
    </Box>
  );
};

export default PrintCaseHeaderComponent;
