import React from "react";
import "./onboarding-category.component.scoped.scss";

const OnboardingCategory = ({
  name,
  src,
  activeSrc,
  onclick,
  categoryKey,
  active,
  borderNum = 5,
  number,
}: any) => {
  return (
    <div
      className={`category-wrapper ${
        active ? "active-border-" + borderNum : "inactive-border"
      }`}
      onClick={onclick && onclick.bind(null, categoryKey)}
    >
      {src && activeSrc && (
        <img
          src={`/icons/onboarding/${active ? activeSrc : src}`}
          className="category-img mx-2 "
          alt="category"
        />
      )}
      {number && <span className="number">{number}</span>}
      <div className="mr-1 category-name">{name}</div>
    </div>
  );
};

export default OnboardingCategory;
