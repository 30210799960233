import React, { useState, useEffect } from "react";
import "./dashboard-third-block.component.scoped.scss";
import { useTranslation } from "react-i18next";
import DashboardTableCard from "../dashboard-table-card/dashboard-table-card.component";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { DashboardService } from "../../../../services/dashboard/dashboard.service";
import { MapperService } from "../../../../services/mapper/mapper.service";

export default function DashboardThirdBlock(props: any) {
  const dashboardService = new DashboardService();
  const mapperService = new MapperService();
  const [t] = useTranslation();
  const emptyArr: any = [];
  const [cards, setCards] = useState(emptyArr);

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const orderedCards = reorder(
      cards,
      result.source.index,
      result.destination.index
    );
    setCards(orderedCards);
    props.onCardsChange(orderedCards);
  };

  async function fetchData() {
    const latestInternalComments = await dashboardService.getLatestInternalComments();
    const latestCases = await dashboardService.getLatestCases();
    const tableCards = mapperService.mapTableCards(
      latestInternalComments,
      latestCases
    );
    setCards(tableCards);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list" direction="horizontal">
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="row"
            style={{ rowGap: "30px" }}
          >
            {cards.map((card: any, index: number) => {
              return (
                <Draggable key={card.id} draggableId={card.id} index={index}>
                  {(
                    provided: DraggableProvided,
                    snapshot: DraggableStateSnapshot
                  ) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="col-6 card-container"
                    >
                      <DashboardTableCard
                        title={t(card.title)}
                        subTitle={t(card.subTitle)}
                        columns={card.columns}
                        tableName={card.tableName}
                        data={card.data}
                        isDraggable={true}
                        isDragging={snapshot.isDragging}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
