import { TableColumn } from "../../types/table-column";

export const newCasesColumns: TableColumn[] = [
  {
    type: "case",
    fieldName: "AI_PRODUCT",
    value: "title",
    secondaryValue: "internalId",
    width: "27%",
  },
  {
    type: "case_role",
    fieldName: "ROLE",
    value: "role",
    width: "18%",
  },
  {
    type: "release",
    fieldName: "RELEASE",
    value: "release",
    width: "12%",
  },
  {
    type: "string",
    fieldName: "STATUS",
    value: "status",
    width: "20%",
  },
  {
    type: "date",
    fieldName: "DATE",
    value: "created_at",
    width: "13%",
  },
];
