export const dummyUseCase = {
  ai_activate: 0,
  ai_explination: 0,
  algorithmId: 0,
  algorithm: null,
  amount: null,
  amountId: null,
  amountUpdatedAdmin: null,
  amountUpdatedAt: null,
  areasIds: [0, 0, 0, 0],
  areas: [],
  branch: "",
  branchId: 0,
  branchUpdatedAt: null,
  brief: "",
  briefUpdateAdmin: null,
  briefUpdatedAt: null,
  caseOngoing: 0,
  caseStatusId: 0,
  category: "",
  categoryId: 0,
  categoryUpdateAdmin: null,
  categoryUpdatedAt: null,
  createdAt: "",
  description: "",
  descriptionUpdateAdmin: null,
  descriptionUpdatedAt: null,
  dueDate: "",
  endDate: null,
  externalUsers: 0,
  id: 0,
  internalId: "",
  internalUsers: 0,
  involvedCompany: null,
  involvedCompanyUpdatedAdmin: null,
  involvedCompanyUpdatedAt: null,
  involvedDepartment: null,
  involvedDepartmentUpdatedAdmin: null,
  involvedDepartmentUpdatedAt: null,
  involvedPersons: null,
  involvedPersonsUpdatedAdmin: null,
  involvedPersonsUpdatedAt: null,
  isAnonymous: 0,
  labels: [],
  labelsUpdateAdmin: null,
  labelsUpdatedAt: null,
  land: "",
  landId: 0,
  landUpdateAdmin: null,
  landUpdatedAt: null,
  link: "",
  machineLearningIds: [0, 0, 0, 0],
  machineLearning: [],
  mainProcessor: "",
  mainProcessorId: "",
  noticeFieldsLastUpdateDate: null,
  noticeFieldsUpdateAdmin: null,
  optionalFieldsLastUpdateDate: null,
  optionalFieldsUpdateAdmin: null,
  originalAmount: null,
  originalBranch: "",
  originalBranchId: 0,
  originalBrief: "",
  originalCaseOngoing: 0,
  originalCategory: "",
  originalDescription: "",
  originalEndDate: null,
  originalInvolvedCompany: null,
  originalInvolvedDepartment: null,
  originalInvolvedPersons: null,
  originalLand: "",
  originalLandId: 0,
  originalRelationship: null,
  originalStartDate: null,
  phoneNumber: null,
  relationship: null,
  relationshipId: null,
  relationshipUpdatedAdmin: null,
  relationshipUpdatedAt: null,
  reporterActivated: 0,
  reporterEmail: null,
  reporterName: null,
  resolution: null,
  resolutionComment: null,
  role: "",
  scenarioDescription: "",
  startAndEndDateUpdatedAdmin: null,
  startAndEndLastChangeUserId: null,
  startAndEndUpdatedAt: null,
  startDate: null,
  status: "",
  statusKey: "",
  techniques: [],
  techniquesIds: [0, 0, 0, 0],
  title: "",
  version: 0,
  major: 0,
  minor: 0,
  workflowCompleted: false,
  keyContactUser: {},
  clientName: "",
};
