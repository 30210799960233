import React, { useContext, useState } from "react";
import "./add-branch-content.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { Button, Chip, TextField } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { SettingsService } from "../../../../../services/settings/settings.service";
import { Branch } from "../../../../../types/branch";
import AddIcon from "@material-ui/icons/Add";
import { showToastMsg } from "../../../../../helpers";
import { GlobalContext } from "../../../../../store";

export default function AddBranchContent(props: any) {
  const settingsService: SettingsService = new SettingsService();
  const { onBranchesChange } = props;
  const [t] = useTranslation();
  const emptyArr: any[] = [];

  const { branches } = useContext(GlobalContext);

  const [newBranches, setNewBranches] = useState(emptyArr);
  const [branch, setBranch] = useState("");
  const [branchError, setBranchError] = useState(false);
  const [branchesChange, setBranchesChange] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleAddBranchClick = () => {
    if (branch.length > 0) {
      const checkBranches = [...branches, ...newBranches].find(
        (item: any) => item.name === branch
      );
      if (checkBranches) {
        setBranchError(true);
        return;
      }
      const branchesCopy: Branch[] = [...newBranches];
      branchesCopy.push({ name: branch });
      setNewBranches(branchesCopy);
      setBranchesChange(true);
      setBranch("");
    }
  };

  const handleBranchDelete = (name: string) => {
    const filterBranches = newBranches.filter(
      (item: any) => item.name !== name
    );
    if (!filterBranches.length) {
      setBranchesChange(false);
    }
    setNewBranches(filterBranches);
  };

  const submit = async () => {
    setSaving(true);
    await settingsService.addBranches(newBranches);
    setSaving(false);
    setBranchesChange(false);
    setBranch("");
    setNewBranches([]);
    onBranchesChange(true);
    showToastMsg("success", t("branches added successfully"));
  };

  const cancel = () => {
    setNewBranches([]);
    setBranch("");
    setBranchesChange(false);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleAddBranchClick();
    }
  };

  return (
    <div className="branches">
      <div className="section-info">
        <h3>{t("add new branches")}</h3>
        <p>{t("here you can add more departments or locations")}</p>
      </div>
      <section className="branches-section mb-3">
        <div className="d-flex flex-wrap justify-content-start align-items-center p-3 mb-4">
          <div className="">
            <TextField
              style={{ width: "320px", marginRight: "90px" }}
              label={t("branch")}
              variant="filled"
              name="branch"
              value={branch}
              onChange={(e: any) => {
                setBranch(e.target.value);
                setBranchError(false);
              }}
              onKeyDown={handleKeyDown}
            />
            {branchError && (
              <p className="error-msg">{t("branch already exist")}</p>
            )}
          </div>
          <div onClick={handleAddBranchClick} className="add-btn">
            <AddIcon style={{ marginRight: "7px" }} />
            {t("add branch")}
          </div>
          <div className="w-100 d-flex flex-wrap">
            {newBranches.map((item: any, index: number) => {
              return (
                <div key={index} className="mt-3 mx-2">
                  <Chip
                    label={item.name}
                    onDelete={handleBranchDelete.bind(null, item.name)}
                    variant="outlined"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <div className="d-flex justify-content-end mb-4">
        <div className="footer mt-3">
          <Button
            variant="outlined"
            color="primary"
            className="btn-cancel"
            onClick={cancel}
            disabled={!branchesChange}
          >
            {t("cancel")}
          </Button>
          <Button
            className="btn-primary-theme btn-save"
            variant="contained"
            color="primary"
            onClick={submit}
            disabled={saving || !branchesChange}
          >
            <CheckIcon /> {t("save changes")}
          </Button>
        </div>
      </div>
    </div>
  );
}
