import { Box, Typography } from "@material-ui/core";
import "./create-case-general-step.component.scoped.scss";
import React from "react";
import TabContentHeadComponent from "../tab-content-head/tab-content-head.component";
import { useTranslation } from "react-i18next";

const CreateCaseGeneralStepComponent = () => {
  const [t] = useTranslation();
  return (
    <Box className="content mt-4">
      <TabContentHeadComponent
        title={t("USECASE_CREATE.AI_GENERAL_TITLE")}
        subTitle={t("USECASE_CREATE.AI_GENERAL_SUB_TITLE")}
      />
      <Typography variant="subtitle2" style={{ color: "var(--grey-text)" }}>
        {t("USECASE_CREATE.AI_GENERAL_DESCRIPTION")}
      </Typography>
    </Box>
  );
};
export default CreateCaseGeneralStepComponent;
