import React, { useContext, useState } from "react";
import "./case-actions.component.scoped.scss";
import { Box, Button } from "@material-ui/core";
import { icons } from "../../../../../statics/icons";
import { useTranslation } from "react-i18next";
import PrintIcon from "@material-ui/icons/Print";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SaveIcon from "@material-ui/icons/Save";

import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { CaseContext, GlobalContext } from "../../../../../store";
// import { saveAs } from "file-saver";
import { CasesService } from "../../../../../services/cases/cases.service";
import { showToastMsg } from "../../../../../helpers";
import { history } from "react-router-guard";
import { ConfirmDialog } from "../../../../../dialogs/confirm-dialog/confirm-dialog";
import { htmlTagRegex } from "../../../../../statics/validators";
import { Dropdown } from "react-bootstrap";
// import { jsPDF } from "jspdf";
// import html2canvas from "html2canvas";
const CaseActionsComponent = () => {
  const [t] = useTranslation();
  const casesServices = new CasesService();
  const { user } = useContext(GlobalContext);
  const {
    caseDetails,
    updatedCaseDetails,
    setUpdatedCase,
    setCase,
  } = useContext(CaseContext);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showConfirmVersionModal, setShowConfirmVersionModal] = useState(false);
  const [releaseData, setReleaseData] = useState({
    major: 0,
    minor: 0,
    version: 0,
  });
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogBody, setDialogBody] = useState("");

  const [saving, setSaving] = useState(false);

  const updateCase = async () => {
    setSaving(true);
    const formattedDate = {
      version: ++updatedCaseDetails.version,
      title: updatedCaseDetails.title,
      brief: updatedCaseDetails.brief,
      description: updatedCaseDetails.description,
      link: updatedCaseDetails.link,
      scenarioDescription: updatedCaseDetails.scenarioDescription,
      algorithmId: updatedCaseDetails.algorithmId,
      operationId: updatedCaseDetails.operationId,
      techniquesIds:
        JSON.stringify(caseDetails.techniquesIds.sort()) ===
        JSON.stringify(updatedCaseDetails.techniquesIds.sort())
          ? []
          : updatedCaseDetails.techniquesIds,
      machineLearningIds:
        JSON.stringify(caseDetails.machineLearningIds.sort()) ===
        JSON.stringify(updatedCaseDetails.machineLearningIds.sort())
          ? []
          : updatedCaseDetails.machineLearningIds,
      areasIds:
        JSON.stringify(caseDetails.areasIds.sort()) ===
        JSON.stringify(updatedCaseDetails.areasIds.sort())
          ? []
          : updatedCaseDetails.areasIds,
    };
    const res = await casesServices.update(caseDetails.id, formattedDate);
    if (res.message === "Success") {
      setCase({ ...caseDetails, ...updatedCaseDetails });
      showToastMsg("success", t("CASE_UPDATED_SUCCESSFULLY"));
    } else {
      showToastMsg("error", t("SOME_THING_WENT_WRONG"));
    }
    setSaving(false);
  };

  const onUpdateVersion = async (type: "major" | "minor") => {
    const data = {
      version: caseDetails.version,
      minor: caseDetails.minor,
      major: caseDetails.major,
    };

    if (type === "major") {
      data.major = ++data.major;
      data.minor = 0;
      data.version = 0;
    } else {
      data.minor = ++data.minor;
      data.version = 0;
    }

    const version = `${data.major.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
    })}.${data.minor.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
    })}.${data.version.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
    })}`;

    setReleaseData(data);
    setDialogTitle(`${t("PUBLISH_VERSION", { type })}`);
    setDialogBody(
      `${t("REALLY_YOU_WANT_PUBLISH_VERSION_TYPE", { type, version })}`
    );
    setShowConfirmVersionModal(true);
  };

  const updateVersion = async (value: boolean) => {
    setShowConfirmVersionModal(false);
    if (value) {
      setSaving(true);
      const res = await casesServices.updateVersions(
        caseDetails.id,
        releaseData
      );
      if (res.message === "Success") {
        setCase({ ...caseDetails, ...releaseData });
        setUpdatedCase({
          ...updatedCaseDetails,
          version: releaseData.version,
        });
        showToastMsg("success", t("CASE_UPDATED_SUCCESSFULLY"));
      } else {
        showToastMsg("error", t("SOME_THING_WENT_WRONG"));
      }
      setSaving(false);
    }
  };

  // const downloadPdf = async () => {
  //   const doc = new jsPDF("p");
  //   doc.setFontSize(15);
  //   let lineHeight = 10;

  //   lineHeight += 3;

  //   doc.text(
  //     `${t("category")}: ${caseDetails.category}`,
  //     10,
  //     (lineHeight += 10)
  //   );

  //   doc.text(`${t("country")}: ${caseDetails.land}`, 10, (lineHeight += 10));
  //   doc.text(
  //     `${t("amount")}: ${caseDetails.amount ? caseDetails.amount : ""}`,
  //     10,
  //     (lineHeight += 10)
  //   );
  //   doc.text(
  //     `${t("ongoing-case")}: ${caseDetails.caseOngoing ? t("yes") : t("No")}`,
  //     10,
  //     (lineHeight += 10)
  //   );

  //   doc.text(
  //     `${t("relation")}: ${
  //       caseDetails.relationship ? caseDetails.relationship : ""
  //     }`,
  //     10,
  //     (lineHeight += 10)
  //   );
  //   doc.text(
  //     `${t("start date")}: ${
  //       caseDetails.startDate ? caseDetails.startDate : ""
  //     }`,
  //     10,
  //     (lineHeight += 10)
  //   );
  //   doc.text(
  //     `${t("end date")}: ${caseDetails.endDate ? caseDetails.endDate : ""}`,
  //     10,
  //     (lineHeight += 10)
  //   );
  //   // set line-height for description
  //   doc.setLineHeightFactor(1.5);
  //   const description = doc.splitTextToSize(
  //     `${t("description")}: ${caseDetails.description.replace(/<[^>]+>/g, "")}`,
  //     180
  //   );
  //   // add new page with condition
  //   const pageHeight = doc.internal.pageSize.getHeight();
  //   description.forEach((text: any) => {
  //     if (lineHeight + 10 > pageHeight) {
  //       lineHeight = 10;
  //       doc.addPage();
  //     }
  //     doc.text(text, 10, (lineHeight += 10));
  //   });
  //   doc.setProperties({ title: `${t("case")}-${caseDetails.internalId}` });
  //   let blobPDF = doc.output("blob");
  //   const blob = new Blob([blobPDF], {
  //     type: "pdf/plain;charset=utf-8",
  //   });
  //   saveAs(blob, `${t("case")}.pdf`);
  // };

  const deleteCase = async (value: any) => {
    setShowConfirmDeleteModal(false);
    if (value) {
      const res = await casesServices.deleteCase(caseDetails.id);
      if (res.message === "Success") {
        showToastMsg("success", t("CASE_DELETED_SUCCESSFULLY"));
        if (caseDetails.rold === "DEVELOPER") {
          history.push("/ai-products");
        } else if (caseDetails.role === "USER") {
          history.push("/ai-demand");
        } else if (
          caseDetails.role === "DISTRIBUTER" ||
          caseDetails.role === "IMPORTER"
        ) {
          history.push("/ai-inventory");
        } else {
          history.push("/landscape");
        }
      } else {
        showToastMsg("error", t("SOME_THING_WENT_WRONG"));
      }
    }
  };

  const caseUpdated = () => {
    if (
      caseDetails.title === updatedCaseDetails.title &&
      caseDetails.brief === updatedCaseDetails.brief &&
      caseDetails.description === updatedCaseDetails.description &&
      caseDetails.scenarioDescription ===
        updatedCaseDetails.scenarioDescription &&
      caseDetails.algorithmId === updatedCaseDetails.algorithmId &&
      caseDetails.operationId === updatedCaseDetails.operationId &&
      JSON.stringify(caseDetails.techniquesIds.sort()) ===
        JSON.stringify(updatedCaseDetails.techniquesIds.sort()) &&
      JSON.stringify(caseDetails.machineLearningIds.sort()) ===
        JSON.stringify(updatedCaseDetails.machineLearningIds.sort()) &&
      JSON.stringify(caseDetails.areasIds.sort()) ===
        JSON.stringify(updatedCaseDetails.areasIds.sort())
    ) {
      return true;
    } else if (
      (caseDetails.role !== "USER" &&
        (!updatedCaseDetails.techniquesIds.length ||
          !updatedCaseDetails.machineLearningIds.length)) ||
      !updatedCaseDetails.areasIds.length ||
      updatedCaseDetails.scenarioDescription.replace(htmlTagRegex, "").length >
        800 ||
      updatedCaseDetails.description.replace(htmlTagRegex, "").length > 800
    ) {
      return true;
    } else {
      return false;
    }
  };

  // const downloadUseCasePDF = () => {
  //   // const input = document.getElementById("printUseCase") as any;
  //   const input = inputRef.current as any;

  //   console.log(input);
  //   html2canvas(input, {
  //     useCORS: true,
  //   }).then((canvas) => {
  //     console.log(canvas);
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF({
  //       orientation: "portrait",
  //     });
  //     const imgProps = pdf.getImageProperties(imgData);
  //     console.log(imgProps);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();
  //     console.log(pdfHeight, pdfWidth);
  //     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight + 32);
  //     pdf.save(`${caseDetails.internalId}.pdf`);
  //   });
  // };

  return (
    <Box className="content">
      <Box className="left-action">
        {(user.type === "superAdmin" ||
          (user.type === "superUser" && user.role === "coordinator")) && (
          <Button
            className="btn-secondary-theme mb-0"
            variant="outlined"
            color="primary"
            size="medium"
            onClick={setShowConfirmDeleteModal.bind(null, true)}
          >
            <DeleteIcon style={{ cursor: "pointer" }} />
            <span className="ml-1">{t("DELETE")}</span>
          </Button>
        )}
      </Box>
      <Box className="right-action">
        <Button
          className="btn-secondary-theme mb-0"
          variant="outlined"
          color="primary"
          size="medium"
          onClick={() => window.print()}
        >
          <PrintIcon />
          <span className="ml-1">{t("PRINT")}</span>
        </Button>

        <Button
          className="btn-secondary-theme mb-0"
          variant="outlined"
          color="primary"
          size="medium"
          // onClick={downloadUseCasePDF}
          onClick={() => window.print()}
        >
          <img
            width={16}
            height={16}
            src={icons.downloadLight}
            alt="download"
          />
          <span className="ml-1">{t("DOWNLOAD")}</span>
        </Button>

        <Button
          className="btn-secondary-theme mb-0"
          variant="contained"
          color="primary"
          size="medium"
          onClick={updateCase}
          disabled={saving || caseUpdated()}
        >
          <SaveIcon />
          <span className="ml-1">{t("SAVE")}</span>
        </Button>
        <Dropdown className="version-dropdown mb-0">
          <Dropdown.Toggle disabled={saving}>
            <span className="ml-1">{t("PUBLISH")}</span>
            <ExpandMoreIcon />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              disabled={saving}
              onClick={onUpdateVersion.bind(false, "major")}
            >
              {t("PUBLISH")} Major
            </Dropdown.Item>
            <Dropdown.Item
              disabled={saving}
              onClick={onUpdateVersion.bind(false, "minor")}
            >
              {t("PUBLISH")} Minor
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Box>

      {showConfirmDeleteModal && (
        <ConfirmDialog
          show={showConfirmDeleteModal}
          onClose={deleteCase}
          dialogTitle={t("CONFIRM_DELETE_CASE")}
          dialogBody={t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_CASE")}
          confirmBtnText={t("DELETE")}
          cancelBtn={true}
        />
      )}

      {showConfirmVersionModal && (
        <ConfirmDialog
          show={showConfirmVersionModal}
          onClose={updateVersion}
          dialogTitle={dialogTitle}
          dialogBody={dialogBody}
          confirmBtnText={t("PUBLISH")}
          cancelBtn={true}
        />
      )}
    </Box>
  );
};

export default CaseActionsComponent;
