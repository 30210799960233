import { Box } from "@material-ui/core";
import "./profile-picture.component.scoped.scss";
import React, { useContext } from "react";
import UploadPhoto from "../../../../../dialogs/upload-photo/upload-photo.component";
import { icons } from "../../../../../statics/icons";
import { imageTypes, maxFileSize } from "../../../../../statics/file-types";
import { getFileExtension, showToastMsg } from "../../../../../helpers";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../../../store";

const ProfilePictureComponent: React.FunctionComponent<{
  onUploadSuccess: (profilePicKey: string, imageStream: string) => void;
  onDelete: () => void;
}> = ({ onUploadSuccess, onDelete }) => {
  const [t] = useTranslation();
  const { user } = useContext(GlobalContext);
  const [openCropImage, setOpenCropImage] = React.useState(false);
  const imageRef = React.useRef<HTMLInputElement>(null);
  const [image, setImage] = React.useState<
    string | ArrayBuffer | null | undefined
  >(undefined);
  const [imageType, setImageType] = React.useState<string>("");
  const [imageName, setImageName] = React.useState<string>("");

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target && e.target.files && e.target.files[0].type) {
      validateImageType(e.target.files[0]);
    }
    if (imageRef && imageRef.current) {
      imageRef.current.value = "";
    }
  };

  const validateImageType = (file: File) => {
    const fileExtension = getFileExtension(file.type);
    if (file.size > maxFileSize) {
      showToastMsg("error", t("INVALID_IMAGE_SIZE"));
      setImage(undefined);
    } else if (!fileExtension) {
      showToastMsg("error", t("INVALID_IMAGE_FORMAT"));
      setImage(undefined);
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setImageType(file.type);
        setImageName(file.name);
        setOpenCropImage(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddOrRemoveImg = () => {
    if (user.profilePicURL) {
      setImage(null);
      onDelete();
    } else {
      imageRef?.current?.click();
    }
  };

  return (
    <Box>
      <Box className="avatar-upload">
        <Box className="avatar-edit" onClick={handleAddOrRemoveImg}>
          <input
            onChange={(event) => handleFileUpload(event)}
            type="file"
            ref={imageRef}
            accept={imageTypes}
          />
          {user.profilePicURL ? (
            <img src={icons.close} alt="" />
          ) : (
            <img src={icons.edit} alt="" />
          )}
        </Box>
        <Box className="avatar-preview">
          <Box id="imagePreview">
            <img
              src={user.profilePicURL || icons.PLACEHOLDER.user}
              alt={user.firstName + " " + user.lastName}
              title={user.firstName + " " + user.lastName}
              onError={(e: any) => (e.target.src = icons.PLACEHOLDER.user)}
            />
          </Box>
        </Box>
      </Box>

      {openCropImage && (
        <UploadPhoto
          handleClose={() => setOpenCropImage(false)}
          image={image}
          imageType={imageType}
          imageName={imageName}
          onUploadSuccess={(fileKey: string, imageStream: string) => {
            onUploadSuccess(fileKey, imageStream);
            setImage(imageStream);
          }}
          open={openCropImage}
        />
      )}
    </Box>
  );
};
export default ProfilePictureComponent;
