import { IconOption } from "../types/iconOption";
import { icons } from "./icons";

export const OperationsIconOptions: IconOption[] = [
  {
    key: "FINANCE",
    name: "finance",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.FINANCE,
  },
  {
    key: "HR",
    name: "HR",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.HR,
  },
  {
    key: "R_D",
    name: "R&D",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.R_D,
  },
  {
    key: "SALES",
    name: "sales",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.SALES,
  },
];
