import React from "react";
import "./workflow-comment.component.scoped.scss";
import { Box } from "@material-ui/core";
import WorkflowCommentsListComponent from "../workflow-comments-list/workflow-comments-list.component";
import { Comment } from "../../../../../../../../types/comment";
import WorkflowAddComment from "../workflow-add-comment/workflow-add-comment.component";

interface WorkflowCommentProps {
  answer: number;
  comments: any[];
  workflowLoading: boolean;
  user: any;
  caseQuestionId: number;
  onDeleteComment: (index: number) => void;
  onUpdateComments: (todo: any) => void;
}

const WorkflowComment = (props: WorkflowCommentProps) => {
  const {
    answer,
    comments,
    workflowLoading,
    user,
    caseQuestionId,
    onDeleteComment,
    onUpdateComments,
  } = props;

  return (
    <Box className="comments-wrapper position-relative px-4 py-3">
      {answer === null && user.role !== "auditor" ? (
        <WorkflowAddComment
          user={user}
          questionId={caseQuestionId}
          updateComments={(newComment: string) => onUpdateComments(newComment)}
        />
      ) : null}

      <Box className="d-flex flex-column w-100 mt-4">
        {comments.map((comment: Comment, index: any) => {
          return (
            <WorkflowCommentsListComponent
              comment={comment.content}
              commentIndex={index}
              user={comment.user}
              date={comment.createdAt}
              files={comment.files}
              id={comment.id}
              key={index}
              loading={workflowLoading}
              isAuthorizedUser={
                user.keycloakUserId === comment.userId ||
                user.type === "superAdmin" ||
                user.role === "coordinator"
              }
              questionId={caseQuestionId}
              caseQuestionId={props.caseQuestionId}
              deleteComment={(deletedIndex: number) =>
                onDeleteComment(deletedIndex)
              }
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default WorkflowComment;
