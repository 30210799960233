import axios from "axios";
import { config } from "../../config";

export class TodoService {
  public list = async (params: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + `todo`, { params });
      return response.data;
    } catch (err) {
      return [];
    }
  };

  public listByCase = async (caseId: number): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + `todo/case/${caseId}`);
      return response.data;
    } catch (err) {
      return [];
    }
  };

  public add = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(serverUrl + `todo`, data);
      return response.data;
    } catch (err: any) {
      return err.errors[0].message;
    }
  };

  public update = async (id: number, data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(serverUrl + `todo/${id}`, data);
      return response.data;
    } catch (err) {
      return [];
    }
  };

  public delete = async (id: number): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.delete(serverUrl + `todo/${id}`);
      return response.data;
    } catch (err: any) {
      return err.errors[0].message;
    }
  };
}
