import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CommonService } from "../../../services/common/common.service";
import { GlobalContext } from "../../../store";
// import CategoriesContent from "../../../modules/settings/components/roles-management/categories-content/categories-content.component";
import CoordinatorsContent from "../../../modules/settings/components/roles-management/coordinators-content/coordinators-content.component";
import SettingsHead from "../../../modules/settings/shared/settings-head/settings-head.component";

export default function RolesManagementPage(props: any) {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  const { user } = useContext(GlobalContext);
  commonService.setTitle(t("role management"));

  return (
    <>
      <div className="role-contain">
        <div className="content">
          <SettingsHead
            title={t("role management")}
            subTitle={t("organize the permissions of your users here")}
          />
          {/* {(user.type === "superAdmin" ||
            (user.type === "superUser" && user.role === "coordinator")) && (
            <CategoriesContent />
          )} */}
          {(user.type === "superAdmin" ||
            (user.type === "superUser" && user.role === "coordinator")) && (
            <CoordinatorsContent />
          )}
        </div>
      </div>
    </>
  );
}
