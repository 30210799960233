const files = () => {
  const files = [1, 2, 3].map(() => {
    return {
      name: "",
    };
  });
  return files;
};

export const dummyFiles = files();

// export const dummyFiles = [
//   {
//     status: "Planning / Strategy",
//     files: files(),
//   },
//   {
//     status: "Concept",
//     files: files(),
//   },
//   {
//     status: "Data acquisition and preparation",
//     files: files(),
//   },
//   {
//     status: "Model development",
//     files: files(),
//   },
//   {
//     status: "Model training",
//     files: files(),
//   },
//   {
//     status: "Model validation",
//     files: files(),
//   },
//   {
//     status: "Model testing",
//     files: files(),
//   },
//   {
//     status: "Deployment and integration",
//     files: files(),
//   },
//   {
//     status: "Release / Go-life",
//     files: files(),
//   },
//   {
//     status: "Monitoring (incl. controls?)",
//     files: files(),
//   },
//   {
//     status: "Maintenance - Keep the system up to date",
//     files: files(),
//   },
//   {
//     status: "Retirement and/or redefinition",
//     files: files(),
//   },
//   {
//     status: "Redefinition",
//     files: files(),
//   },
// ];
