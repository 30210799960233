import React, { useContext, useEffect } from "react";
import "./AI-team-step.component.scoped.scss";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import TabContentHeadComponent from "../tab-content-head/tab-content-head.component";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { getIn, useFormik } from "formik";
import { CreateUseCaseContext } from "../../../../../store";
import { emailRegex } from "../../../../../statics/validators";

interface User {
  firstName: string;
  lastName: string;
  email: string;
  group: string;
  role: string;
  keyContact: number;
}

const initUser: User = {
  firstName: "",
  lastName: "",
  email: "",
  group: "",
  role: "",
  keyContact: 0,
};

interface FormUsers {
  users: User[];
}

const validationSchema = yup.object().shape({
  users: yup.array().of(
    yup.object().shape({
      firstName: yup.string().max(30).required(),
      lastName: yup.string().max(30).required(),
      email: yup.string().email().matches(emailRegex, "").required(),
      group: yup.string().required(),
      role: yup.string().required(),
      keyContact: yup.number().required().oneOf([1, 2]),
    })
  ),
});

const AITeamStepComponent = (props: any) => {
  const { onError } = props;
  const [t] = useTranslation();
  const { useCase, setUseCase } = useContext(CreateUseCaseContext);
  const { ...usersForm } = useFormik({
    initialValues: {
      users: [initUser as User],
    },
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: (values: FormUsers) => {
      // Handle form submission
      console.log("Form submitted with values:", values);
    },
  });

  const handleAddUser = () => {
    const hasKeyContact = usersForm.values.users.some(
      (user: User) => user.keyContact === 1
    );
    initUser.keyContact = hasKeyContact ? 2 : 1;
    usersForm.setValues({
      ...usersForm.values,
      users: [...usersForm.values.users, initUser],
    });
  };

  const handleRemoveUser = (index: number) => {
    const newUsers = [...usersForm.values.users];
    newUsers.splice(index, 1);
    usersForm.setValues({
      ...usersForm.values,
      users: newUsers.length ? newUsers : [initUser],
    });
  };

  const hasError = (
    attr: "firstName" | "lastName" | "email" | "role" | "group" | "keyContact",
    index: number
  ) => {
    const hasError =
      usersForm.touched.users &&
      usersForm.touched.users[index] &&
      usersForm.touched.users[index][attr] &&
      usersForm.errors.users &&
      usersForm.errors.users[index] &&
      getIn(usersForm.errors, `users[${index}][${attr}]`)
        ? true
        : false;
    return hasError;
  };

  const onUpdateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const event = { ...e };
    event.target.value = e.target.value.replace(/^\s+/g, "");
    usersForm.handleChange(event);
  };

  useEffect(() => {
    if (
      usersForm.isValid &&
      usersForm.values.users.some((user: User) => user.keyContact === 1)
    ) {
      setUseCase({ ...useCase, users: usersForm.values.users });
      onError(false);
    } else {
      onError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersForm.isValid, usersForm.values]);

  const hasAdmin = () => {
    return usersForm.values.users.some((user: User) => user.keyContact === 1);
  };

  return (
    <Box className="content">
      <TabContentHeadComponent
        title={t("USECASE_CREATE.AI_TEAM_TITLE")}
        subTitle={t("USECASE_CREATE.AI_TEAM_SUB_TITLE")}
      />
      <Box className="mb-4">
        <Box className="d-flex justify-content-between align-items-center">
          <Typography variant="h5" color="secondary">
            {t("USECASE_CREATE.YOUR_AI_TEAM")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className="d-flex align-items-center text-capitalize"
            onClick={handleAddUser}
            disabled={!usersForm.isValid}
          >
            <AddIcon style={{ marginRight: "5px" }} />
            {t("ADD")}
          </Button>
        </Box>

        {/* form array */}
        {usersForm.values.users.map((user: User, index: number) => (
          <Box className="form mx-2 mt-4" key={index}>
            <FormControl className="select-input select-grey w-100">
              <InputLabel id="role">{t("ROLE")}</InputLabel>
              <Select
                labelId="role"
                id="damage-amount-select"
                value={user.role}
                onChange={usersForm.handleChange}
                onBlur={usersForm.handleBlur}
                name={`users[${index}].role`}
                error={hasError("role", index)}
                IconComponent={ExpandMoreIcon}
              >
                <MenuItem value={"editor"}>{t("EDITOR")}</MenuItem>
                <MenuItem value={"auditor"}>{t("AUDITOR")}</MenuItem>
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label={t("FIRST_NAME")}
              value={user.firstName}
              onChange={onUpdateInput}
              onBlur={usersForm.handleBlur}
              name={`users[${index}].firstName`}
              variant="filled"
              error={hasError("firstName", index)}
            />

            <TextField
              fullWidth
              label={t("LAST_NAME")}
              value={user.lastName}
              onChange={onUpdateInput}
              onBlur={usersForm.handleBlur}
              name={`users[${index}].lastName`}
              error={hasError("lastName", index)}
              variant="filled"
            />
            <TextField
              fullWidth
              label={t("EMAIL")}
              value={user.email}
              onChange={onUpdateInput}
              onBlur={usersForm.handleBlur}
              name={`users[${index}].email`}
              error={hasError("email", index)}
              variant="filled"
            />

            <FormControl className={"select-input select-grey w-100 "}>
              <InputLabel id="group">{t("GROUP")}</InputLabel>
              <Select
                labelId="group"
                id="group"
                value={user.group}
                onChange={usersForm.handleChange}
                onBlur={usersForm.handleBlur}
                name={`users[${index}].group`}
                error={hasError("group", index)}
                IconComponent={ExpandMoreIcon}
              >
                <MenuItem value={"internal"}>{t("INTERNAL")}</MenuItem>
                <MenuItem value={"external"}>{t("EXTERNAL")}</MenuItem>
              </Select>
            </FormControl>

            <FormControl className={"select-input select-grey w-100 "}>
              <InputLabel id="key-contact">{t("KEY_CONTACT")}</InputLabel>
              <Select
                labelId="key-contact"
                id="key-contact"
                value={user.keyContact || ""}
                onChange={usersForm.handleChange}
                onBlur={usersForm.handleBlur}
                name={`users[${index}].keyContact`}
                error={hasError("keyContact", index)}
                IconComponent={ExpandMoreIcon}
                disabled={hasAdmin() && user.keyContact !== 1}
              >
                <MenuItem value={1}>{t("YES")}</MenuItem>
                <MenuItem value={2}>{t("NO")}</MenuItem>
              </Select>
            </FormControl>
            <Box
              className="remove-user"
              onClick={handleRemoveUser.bind(null, index)}
            >
              <DeleteOutlineIcon />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default AITeamStepComponent;
