export default (state: any, action: any) => {
  switch (action.type) {
    case "SET_USE_CASE":
      return {
        ...state,
        useCase: action.payload.useCase,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    case "SET_LANDS":
      return {
        ...state,
        lands: action.payload.lands,
      };
    case "SET_BRANCHES":
      return {
        ...state,
        branches: action.payload.branches,
      };

    case "SET_AREAS":
      return {
        ...state,
        areas: action.payload.areas,
      };

    case "SET_ALGORITHMS":
      return {
        ...state,
        algorithms: action.payload.algorithms,
      };
    case "SET_OPERATIONS":
      return {
        ...state,
        operations: action.payload.operations,
      };
    case "SET_TECHNIQUES":
      return {
        ...state,
        techniques: action.payload.techniques,
      };
    case "SET_MACHINE_LEARNING_TYPES":
      return {
        ...state,
        machineLearningTypes: action.payload.machineLearningTypes,
      };
    case "SET_CATEGORIES":
      return {
        ...state,
        categories: action.payload.categories,
      };

    default:
      return state;
  }
};
