import React from "react";
import HeaderComponent from "../../../components/header/header.component";
import { CasesProvider } from "../../../store";
import { CommonService } from "../../../services/common/common.service";
import { useTranslation } from "react-i18next";
import AIDemandContentComponent from "../../../modules/case/components/list/ai-demand-content/ai-demand-content.component";

const AIDemandPage = () => {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  commonService.setTitle(t("PAGE_META_TITLES.AI_DEMAND"));

  return (
    <CasesProvider>
      <HeaderComponent />
      <AIDemandContentComponent />
    </CasesProvider>
  );
};

export default AIDemandPage;
