import "./table-footer.component.scoped.scss";
import React, { useState, useEffect } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  makeStyles,
  Grid,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { dummyPages, pageItems } from "../../../statics/pagination";

const useStyles = makeStyles({
  select: {
    bottom: "10px",
    top: "unset !important",
  },
});

export const TableFooterComponent = (props: any) => {
  const [t] = useTranslation();
  const { paginate, pagination } = props;
  const classes = useStyles();
  const [selectedItems, setTotalRows] = useState(pagination.items);
  const [pages, setPages] = useState(dummyPages);

  const handlePagesTotal = (total: number) => {
    const pages: any = [];
    if (total === 0) {
      pages.push(1);
    } else {
      for (let i = 1; i <= total; i++) {
        pages.push(i);
      }
    }
    setPages(pages);
  };

  const handleItemsChange = (event: any) => {
    setTotalRows(event.target.value);
    return paginate({
      items: event.target.value,
      currentPage: 1,
    });
  };

  const handlePageChange = (event: any) => {
    return paginate({
      items: pagination.items,
      currentPage: event.target.value,
    });
  };

  const nextPage = () => {
    if (
      pagination.currentPage === pagination.totalPages ||
      pagination.totalPages === 0
    ) {
      return;
    }
    return paginate({
      items: pagination.items,
      currentPage: pagination.currentPage + 1,
    });
  };

  const prevPage = () => {
    if (pagination.currentPage === 1) {
      return;
    }
    return paginate({
      items: pagination.items,
      currentPage: pagination.currentPage - 1,
    });
  };

  useEffect(() => {
    handlePagesTotal(pagination.totalPages);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handlePagesTotal(pagination.totalPages);
    // eslint-disable-next-line
  }, [pagination.totalPages]);

  return (
    <Grid
      className="table-footer"
      container
      direction="row"
      justify="space-between"
      spacing={1}
    >
      <Grid className="items" item xs={4} sm={3}>
        <FormControl variant="outlined" className="items-pagination">
          <Select
            MenuProps={{ classes: { paper: classes.select } }}
            labelId="items-select-label"
            id="items-select"
            value={selectedItems}
            onChange={handleItemsChange}
            label="items"
            IconComponent={ExpandMoreIcon}
          >
            {pageItems.map((item: any, index: any) => {
              return (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <div className="label ml-15px">{t("rows per page")}</div>
      </Grid>
      <Grid className="page" item xs={4} sm={6}>
        <div
          className={
            "page-prev " + (pagination.currentPage === 1 ? "disabled" : "")
          }
          onClick={prevPage}
        >
          <NavigateBeforeIcon />
        </div>
        <div className="page-text">
          {pagination.currentPage} {t("of")}{" "}
          {pagination.totalPages ? pagination.totalPages : 1}
        </div>
        <div
          className={
            "page-next " +
            (pagination.currentPage === pagination.totalPages ||
            pagination.totalPages === 0
              ? "disabled"
              : "")
          }
          onClick={nextPage}
        >
          <NavigateNextIcon />
        </div>
      </Grid>
      <Grid className="go-page" item xs={4} sm={3}>
        <div className="label mr-15px text-right">{t("go to page")}</div>
        <FormControl variant="outlined" className="items-pagination">
          <Select
            labelId="items-select-label"
            id="pages-select"
            value={pagination.currentPage}
            onChange={handlePageChange}
            label="pages"
            MenuProps={{ classes: { paper: classes.select } }}
            IconComponent={ExpandMoreIcon}
          >
            {pages.map((item: any, index: any) => {
              return (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
