import "./table-row.component.scoped.scss";
import React, { useState, useEffect } from "react";
import { TableRowProps } from "../../../types/table-row-props";
import { TableColumn } from "../../../types/table-column";
import { history } from "react-router-guard";
import { formatDate, showToastMsg } from "../../../helpers";
import { icons } from "../../../statics/icons";
import {
  withStyles,
  MenuItem,
  ListItemText,
  Menu,
  IconButton,
  Checkbox,
  Box,
  Tooltip,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { LabelRoundComponent } from "../../label-round/label-round.component";
import { UsersService } from "../../../services/users/users.service";
import { ConfirmDialog } from "../../../dialogs/confirm-dialog/confirm-dialog";
import { LocalStorageService } from "../../../services/local-storage/local-storage";
import { useTranslation } from "react-i18next";
import { AIRolesByKey } from "../../../modules/case/types/AI-roles";
import AddNewUserModal from "../../../dialogs/add-newUser-modal/add-new-user";

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "var(--primary)",
    color: "#ffffff",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  // @ts-ignore
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export const TableRowComponent = (props: TableRowProps) => {
  const [t] = useTranslation();
  const {
    columns,
    data,
    clickableRow,
    refresh,
    selectedCase,
    tableName,
  } = props;
  const localStorageService: LocalStorageService = new LocalStorageService();
  const usersService: UsersService = new UsersService();

  const [row, setRow]: any = useState(data);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogBody, setDialogBody] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [userImageError, setUserImageError] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);

  const handleDialogClose = (value: any) => {
    setTimeout(() => {
      setShowConfirmModal(false);
    }, 0);
    if (value) {
      if (dialogTitle === "confirm delete") {
        handleDeleteUser();
      } else if (dialogTitle === "confirm deactivate") {
        handleDeactivateUser();
      } else if (dialogTitle === "confirm activate") {
        handleReactivateUser();
      }
    }
    setDialogTitle("");
    setDialogBody("");
  };

  const deleteUser = () => {
    handleClose();
    setDialogTitle("confirm delete");
    setDialogBody("are you sure you want to delete the user");
    setShowConfirmModal(true);
  };

  const handleDeleteUser = async () => {
    const res = await usersService.delete(row.id);
    if (res && res.message === "deleted") {
      refresh("delete");
      showToastMsg("success", t("user deleted successfully"));
    } else if (res && res.message) {
      showToastMsg("error", t(res.message));
    }
  };

  const deactivateUser = () => {
    handleClose();
    setDialogTitle("confirm deactivate");
    setDialogBody("are you sure you want to deactivate the user");
    setShowConfirmModal(true);
  };

  const handleDeactivateUser = async () => {
    const res = await usersService.deactivate(row.id);
    if (res && res.message === "deactivated") {
      refresh();
      showToastMsg("success", t("user deactivated successfully"));
    } else if (res.message) {
      showToastMsg("error", t(res.message));
    } else {
      showToastMsg("error", t("SOME_THING_WENT_WRONG"));
    }
  };

  const reactivateUser = () => {
    handleClose();
    setDialogTitle("confirm activate");
    setDialogBody("are you sure you want to activate the user");
    setShowConfirmModal(true);
  };

  const handleReactivateUser = async () => {
    const res = await usersService.activate(row.id);
    if (res && res.message === "activated") {
      refresh();
      showToastMsg("success", t("user activated successfully"));
    }
  };

  const inviteUser = async () => {
    await usersService.renewTokenValidity({
      keycloakUserId: row.keycloakUserId,
      email: row.email,
      name: row.name,
      tenantId: localStorageService.getData("tenantId"),
      link: `${window.location.origin}`,
    });
    showToastMsg("success", t("An email has been sent to the user"));
  };

  const handleModalUserClose = (value: any) => {
    setTimeout(() => {
      setShowUserModal(false);
    }, 0);
    if (value) {
      refresh();
    }
  };

  useEffect(() => {
    setRow(props.data);
    // eslint-disable-next-line
  }, [props.data]);

  const navigator = (type: string) => {
    history.push(type);
  };

  const tableNavigator = (event: any) => {
    event.stopPropagation();
    if (clickableRow && event.target.id !== "checkbox") {
      if (tableName === "cases") {
        const [page, path] = clickableRow;
        const completePath = data[path];
        navigator(`${page}/${completePath}`);
      } else if (tableName === "users") {
        setShowUserModal(true);
      }
    }
  };

  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={"table-row"} onClick={tableNavigator}>
      {columns.map((column: TableColumn, index: number) => {
        const { secondaryValue }: any = column;
        return (
          <Box
            className={
              "one-column" +
              (column.type === "actions"
                ? " actions"
                : column.type === "checkbox"
                ? " checkbox-column"
                : "")
            }
            style={{ minWidth: `${column.width || 15}%` }}
            key={index}
          >
            {column.type === "string" ? (
              <Box className={secondaryValue ? "double-column" : ""}>
                <Box className="title">{row[column.value]}</Box>
                {secondaryValue && (
                  <Box className="hint">{row[secondaryValue]}</Box>
                )}
              </Box>
            ) : column.type === "object" ? (
              <Box className="object">
                <img
                  src={
                    row[column.value].iconKey
                      ? icons.PAGES.SETTINGS.ICON_OPTIONS[
                          row[column.value].iconKey
                        ]
                      : icons.categoryPrimary
                  }
                  alt=""
                />
                <span className="name">{row[column.value].name}</span>
              </Box>
            ) : column.type === "arrayIcons" ? (
              <Box className="items">
                {row[column.value].map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      {row[column.value].length > 1 ? (
                        <StyledTooltip title={item.name} placement="bottom">
                          <img
                            src={
                              item.iconKey
                                ? icons.PAGES.SETTINGS.ICON_OPTIONS[
                                    item.iconKey
                                  ]
                                : icons.categoryPrimary
                            }
                            alt=""
                          />
                        </StyledTooltip>
                      ) : (
                        <Box className="object">
                          <img
                            src={
                              item.iconKey
                                ? icons.PAGES.SETTINGS.ICON_OPTIONS[
                                    item.iconKey
                                  ]
                                : icons.categoryPrimary
                            }
                            alt=""
                          />
                          <span className="name">{item.name}</span>
                        </Box>
                      )}
                    </React.Fragment>
                  );
                })}
              </Box>
            ) : column.type === "email" ? (
              <Box className="user-cell">{row[column.value]}</Box>
            ) : column.type === "version" ? (
              <Box>{row.version}</Box>
            ) : column.type === "release" ? (
              <Box>
                {row.major.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                })}
                .
                {row.minor.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                })}
                .
                {row.version.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                })}
              </Box>
            ) : column.type === "dueDate" ? (
              <Box className="date">
                {row.isDueDate && <img src="icons/alert.svg" alt="" />}
                <Box>{formatDate(row[column.value], "DD.MM.YYYY")}</Box>
              </Box>
            ) : column.type === "statusDate" ? (
              <Box className="date">
                <Box>{formatDate(row[column.value], "DD.MM.YYYY")}</Box>
              </Box>
            ) : column.type === "date" ? (
              <Box className="date log">
                <Box>{formatDate(row[column.value], "DD.MM.YYYY")}</Box>
              </Box>
            ) : column.type === "time" ? (
              <Box className="time">
                <Box>{formatDate(row[column.value], "HH:mm:ss")}</Box>
              </Box>
            ) : column.type === "key" ? (
              <Box className="key">
                <Box>{t(row[column.value])}</Box>
              </Box>
            ) : column.type === "role" ? (
              <Box className="role">
                <Box>{t(AIRolesByKey[row[column.value]].title)}</Box>
              </Box>
            ) : column.type === "logID" ? (
              <Box className="id log">{row.value[column.value]}</Box>
            ) : column.type === "logKey" ? (
              <Box className="key">
                {t(row[column.value], { obj: row.value })}
              </Box>
            ) : column.type === "user" ? (
              <Box className="user-col">
                <Box className="profile-picture">
                  <img src={row["img"]} alt="" className="user-image" />
                </Box>
                <Box>
                  <Box>{row[column.value]}</Box>
                  <Box className="second-value">{row[secondaryValue]}</Box>
                </Box>
              </Box>
            ) : column.type === "client" ? (
              <Box
                className="d-flex align-items-center"
                style={{ gap: ".5rem" }}
              >
                <Box className="profile-pic">
                  {row.profilePicURL && !userImageError ? (
                    <img
                      src={row.profilePicURL}
                      alt=""
                      onError={setUserImageError.bind(null, true)}
                    />
                  ) : (
                    <span className="text-uppercase">
                      {row.firstName[0] + row.lastName[0]}
                    </span>
                  )}
                </Box>
                <Box>
                  <Box className="user-cell">
                    {row.firstName + " " + row.lastName}
                  </Box>
                  <Box className="second-value">
                    {(row.type === "superUser"
                      ? t("COORDINATOR")
                      : row.role === "auditor"
                      ? t("AUDITOR")
                      : row.role === "contributor"
                      ? t("USER")
                      : t("EDITOR")) +
                      "/" +
                      t(row[secondaryValue])}
                  </Box>
                </Box>
              </Box>
            ) : column.type === "status" ? (
              row.type !== "admin" && (
                <Box
                  className={
                    "status-col d-flex align-items-center justify-content-center " +
                    row[column.value]
                  }
                >
                  {row[column.value] === "invited" && (
                    <img src={icons.inviteWhite} alt="" className="mr-1" />
                  )}
                  {row[column.value] === "verified" && (
                    <img src={icons.checkWhite} alt="" className="mr-1" />
                  )}
                  {row[column.value] === "deactivated" && (
                    <img src={icons.closeWhite} alt="" className="mr-1" />
                  )}
                  <Box className="status-cell">{t(row[column.value])}</Box>
                </Box>
              )
            ) : column.type === "task" ? (
              <Box>
                {row.tasks === 0
                  ? t("NEW")
                  : `${row.completedTasks}/${row.tasks}`}
              </Box>
            ) : column.type === "categories" ? (
              <Box className="categories-col d-flex align-items-center">
                {row[column.value].map((category: any, index: number) => (
                  <Box className="category" key={index}>
                    {category && <LabelRoundComponent label={category} />}
                  </Box>
                ))}
              </Box>
            ) : column.type === "questions_status" ? (
              <Box className="questions-status">
                {row.answeredQuestions === 0 ? (
                  <Box className="new">
                    <img src={icons.PAGES.CASES.PROCCESS.NEW} alt="" />
                    <span>{t("NEW")}</span>
                  </Box>
                ) : row.totalQuestions === row.answeredQuestions ? (
                  <Box className="done">
                    <img src={icons.PAGES.CASES.PROCCESS.DONE} alt="" />
                    <span>{t("PUBLISHED")}</span>
                  </Box>
                ) : (
                  <Box className="ongoing">
                    <img src={icons.PAGES.CASES.PROCCESS.ONGOING} alt="" />
                    <span>
                      {`${row.answeredQuestions} ${t("OF")} ${
                        row.totalQuestions
                      }`}
                    </span>
                  </Box>
                )}
              </Box>
            ) : column.type === "checkbox" ? (
              <Box className="checkbox-global" id="checkbox">
                <Checkbox
                  id="checkbox"
                  checked={row.checked}
                  onChange={() => selectedCase(!row.checked, row)}
                  name="auditor"
                  size="small"
                />
              </Box>
            ) : column.type === "actions" ? (
              row.type !== "admin" && (
                <Box className="buttons">
                  {column.value === "users" && (
                    <Box>
                      <IconButton
                        onClick={handleClick}
                        style={{ padding: "5px" }}
                      >
                        <MoreVertIcon className="menu-btn" />
                      </IconButton>
                      <StyledMenu
                        // @ts-ignore
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        {(row.status === "invited" ||
                          row.status === "deactivated") && (
                          <MenuItem onClick={deleteUser}>
                            <ListItemText primary={t("delete")} />
                          </MenuItem>
                        )}
                        {row.status === "verified" && (
                          <MenuItem onClick={deactivateUser}>
                            <ListItemText primary={t("deactivate")} />
                          </MenuItem>
                        )}
                        {row.status === "deactivated" && (
                          <MenuItem onClick={reactivateUser}>
                            <ListItemText primary={t("reactivate")} />
                          </MenuItem>
                        )}
                        {row.status === "invited" && (
                          <MenuItem onClick={inviteUser}>
                            <ListItemText primary={t("invite again")} />
                          </MenuItem>
                        )}

                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setShowUserModal(true);
                          }}
                          // onClick={() => updateRole(row)}
                        >
                          <ListItemText primary={t("EDIT_ROLE")} />
                        </MenuItem>
                        {showUserModal && (
                          <AddNewUserModal
                            show={showUserModal}
                            user={row}
                            onClose={(value: any) =>
                              handleModalUserClose(value)
                            }
                          />
                        )}
                      </StyledMenu>
                    </Box>
                  )}
                </Box>
              )
            ) : (
              <Box className={column.type}>{row[column.value]}</Box>
            )}
          </Box>
        );
      })}
      {showConfirmModal && (
        <ConfirmDialog
          show={showConfirmModal}
          onClose={handleDialogClose}
          dialogTitle={t(dialogTitle)}
          dialogBody={t(dialogBody)}
          confirmBtnText={t("submit")}
          cancelBtn={true}
        />
      )}
    </Box>
  );
};
