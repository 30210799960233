import React from "react";
import "./reset-password.page.scoped.scss";
import { SignUpLayout } from "../../layouts/signup/sign-up.layout";
import { useTranslation } from "react-i18next";
import { CommonService } from "../../services/common/common.service";
import ResetPasswordContentCompenent from "../../modules/auth/components/reset-password-content/reset-password-content.component";

const commonService: CommonService = new CommonService();

const ResetPasswordPage = (props: any) => {
  const [t] = useTranslation();
  commonService.setTitle(t("Case Management"));

  const token = props.match.params.id;

  return (
    <SignUpLayout>
      <ResetPasswordContentCompenent token={token} />
    </SignUpLayout>
  );
};

export default ResetPasswordPage;
