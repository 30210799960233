import packageJson from "../../package.json";

export const localEnvironmentConfig = {
  // Local
  environment: "staging",
  domain: "paiper.one",
  serverUrl: "http://localhost:4000/",
  company: "ACT4AI",
  appVersion: packageJson.version,
  predictUrl:
    "https://case-classsifier-staging.compliance2b.com/api/tasks/predict",
};
