import "./table-header.component.scoped.scss";
import React, { useEffect, useState } from "react";
import { TableHeaderProps } from "../../../types/table-header-props";
import { useTranslation } from "react-i18next";
import { SorterComponent } from "../sorter/sorter.component";
import { Box, Checkbox } from "@material-ui/core";

export const TableHeaderComponent = (props: TableHeaderProps) => {
  const [t] = useTranslation();
  const { columns, selectCases, loading } = props;
  const { sort } = props.tools;
  const { sortDirection, sortColumn } = props.options;
  const { withSort } = props.options;

  const [casesChecked, setCasesChecked] = useState(false);

  useEffect(() => {
    setCasesChecked(false);
  }, [loading]);

  return (
    <Box className="table-header">
      {columns.map((column, index) => {
        return (
          <Box
            className={
              "one-column" +
              (column.type === "actions"
                ? " actions"
                : column.type === "checkbox"
                ? " checkbox-column"
                : "")
            }
            style={{ minWidth: `${column.width || 15}%` }}
            key={index}
          >
            <Box className="w-100">
              <Box className="upper">
                <Box className={`name ${column.value} ${column.component}`}>
                  {column.type !== "checkbox" ? (
                    t(column.fieldName)
                  ) : (
                    <Box className="checkbox-global">
                      <Checkbox
                        checked={casesChecked}
                        onChange={() => {
                          selectCases(!casesChecked);
                          setCasesChecked(!casesChecked);
                        }}
                        name="cases"
                        size="small"
                      />
                    </Box>
                  )}
                </Box>
                {withSort &&
                  column.type !== "checkbox" &&
                  column.type !== "actions" &&
                  column.type !== "categories" &&
                  column.type !== "release" &&
                  // column.type !== "questions_status" &&
                  // column.type !== "object" &&
                  // column.type !== "task" &&
                  column.type !== "arrayIcons" && (
                    <SorterComponent
                      isSorting={column.value === sortColumn}
                      direction={sortDirection}
                      sortColumn={sortColumn}
                      fieldName={column.value}
                      sort={sort}
                    />
                  )}
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
