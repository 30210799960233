import React from "react";
import HeaderComponent from "../../../components/header/header.component";
import { CasesProvider } from "../../../store";
import { CommonService } from "../../../services/common/common.service";
import { useTranslation } from "react-i18next";
import AIProductsContentComponent from "../../../modules/case/components/list/ai-products-content/ai-products-content.component";

const AIProductsPage = () => {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  commonService.setTitle(t("PAGE_META_TITLES.AI_PRODUCTS"));
  return (
    <CasesProvider>
      <HeaderComponent />
      <AIProductsContentComponent />
      {/* <CasesContent /> */}
    </CasesProvider>
  );
};

export default AIProductsPage;
