import { Box, Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { GlobalContext } from "../../../store";
import { MenuTabs } from "../../../types/onboarding";
import SupportContacts from "../../../modules/workspaces/components/create/onboarding-support-contacts/support-contacts.component";
import OnboardingCompanyDetails from "../../../modules/workspaces/components/create/onboarding-company-details/company-details.component";
import OnboardingWorkspace from "../../../modules/workspaces/components/create/onboarding-workspace/onboarding-workspace.component";
import OnboardingCategories from "../../../modules/workspaces/components/create/onboarding-categories/onboarding-categories.component";
import OnboardingAdminAccount from "../../../modules/workspaces/components/create/onboarding-admin-account/onboarding-admin-account.component";
import OnboardingSideMenu from "../../../modules/workspaces/components/create/OnboardingSideMenu/OnboardingSideMenu";

const CreateWorkSpacePage = () => {
  const { activeOnboardingStep, setActiveOnboardingStep } = useContext(
    GlobalContext
  );
  const scrollTop = () => {
    const scrollable: HTMLBodyElement | null = document.querySelector(
      ".contentPane"
    );
    if (scrollable) {
      scrollable.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };
  const nextTab = () => {
    scrollTop();
    setActiveOnboardingStep(activeOnboardingStep + 1);
    if (activeOnboardingStep === menuTabs.length - 1) {
      //  const custom
    } else {
      //   test
    }
  };
  const prevTab = () => {
    scrollTop();
    if (activeOnboardingStep === 0) {
      return;
    } else {
      setActiveOnboardingStep(activeOnboardingStep - 1);
    }
  };

  const menuTabs: MenuTabs[] = [
    {
      label: "Support Contacts",
      component: <SupportContacts nextTab={nextTab} />,
    },
    {
      label: "Company Details",
      component: (
        <OnboardingCompanyDetails nextTab={nextTab} prevTab={prevTab} />
      ),
    },
    {
      label: "Workspace",
      component: <OnboardingWorkspace nextTab={nextTab} prevTab={prevTab} />,
    },
    {
      label: "Categories",
      component: <OnboardingCategories nextTab={nextTab} prevTab={prevTab} />,
    },
    {
      label: "Coordinator account",
      component: <OnboardingAdminAccount nextTab={nextTab} prevTab={prevTab} />,
    },
  ];
  const RightSidePaneComponent = menuTabs[activeOnboardingStep].component;

  return (
    <Box height={"100vh"} overflow={"hidden"} bgcolor="#ffffff">
      <Box height={"100%"} display="flex">
        <Box width={"480px"}>
          <OnboardingSideMenu
            menuTabs={menuTabs}
            selectedTab={activeOnboardingStep}
          />
        </Box>
        <Box width={"calc(100% - 480px)"}>
          <Grid
            className="contentPane"
            container
            justify="center"
            style={{ overflowY: "scroll", height: "100%" }}
          >
            <Grid item xs={10}>
              <Box mt={20}>{RightSidePaneComponent}</Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateWorkSpacePage;
