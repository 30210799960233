import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import "./translation-management-content.component.scoped.scss";
import "ace-builds";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-json5";
import { SettingsService } from "../../../../../services/settings/settings.service";
import { showToastMsg } from "../../../../../helpers";
import moment from "moment";
import { TranslationData } from "../../../../../types/translation-data";
import {
  TranslationValue,
  translationValues,
} from "../../../types/translation-values";
import SelectionBox from "../../../../../components/selection-box/selection-box.component";
import IconsListComponent from "../icons-list/icons-list.component";

export const TranslationManagementContent = () => {
  const [t] = useTranslation();
  const settingsService = new SettingsService();
  const [jsonList, setJsonList] = useState("");
  const [originalJsonList, setOriginalJsonList] = useState("");
  const [translationData, setTranslationData] = useState(new TranslationData());
  const [activeKey, setActiveKey] = useState("");
  const [invalidJsonFile, setInvalidJsonFile] = useState(false);
  const [jsonChanged, setJsonChanged] = useState(false);
  const [showIconsOption, setShowIconsOption] = useState(false);
  const [mouseHover, setMouseHover] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const getTranslationJson = async (key: string, showIcons = false) => {
    const jsonData = await settingsService.getTranslationJson(key);
    setShowIconsOption(showIcons);
    setActiveKey(key);
    setErrorMessage("");
    setTranslationData(jsonData);
    setJsonList(JSON.stringify(jsonData.data, null, "\t"));
    setOriginalJsonList(JSON.stringify(jsonData.data, null, "\t"));
  };

  const onChange = (newValue: any) => {
    setJsonChanged(true);
    setJsonList(newValue);
    try {
      JSON.parse(newValue);
      setInvalidJsonFile(false);
    } catch (e) {
      setInvalidJsonFile(true);
    }
  };

  useEffect(() => {
    getTranslationJson(
      translationValues[0].key,
      translationValues[0].showIconsOption
    );
    setActiveKey(translationValues[0].key);
    // eslint-disable-next-line
  }, []);

  const cancel = () => {
    setJsonList(originalJsonList);
    setJsonChanged(false);
  };

  const submit = async () => {
    setJsonChanged(false);
    setErrorMessage("");
    const res = await settingsService.updateTranslation(
      JSON.parse(jsonList),
      activeKey
    );
    if (res && res.message === "Success") {
      showToastMsg("success", t("translation updated successfully"));
      getTranslationJson(activeKey, showIconsOption);
      setOriginalJsonList(jsonList);
    } else {
      setErrorMessage(res);
    }
  };

  return (
    <>
      <section className="json-management">
        <Box className="section-info">
          <h3>{t("translations for database parameters")}</h3>
          <p>{t("you can translate database parameters here")}</p>
        </Box>
        <Box className="json-content">
          <Box className="items">
            {translationValues.map((item: TranslationValue, i: any) => {
              return (
                <React.Fragment key={i}>
                  {
                    <Box
                      onMouseEnter={() => setMouseHover(i)}
                      onMouseLeave={() => setMouseHover(null)}
                    >
                      <SelectionBox
                        itemKey={item.key}
                        title={t(item.name)}
                        icon={item.icon}
                        activeIcon={item.activeIcon}
                        active={mouseHover === i || activeKey === item.key}
                        variant="outlined"
                        onBoxClick={() =>
                          getTranslationJson(item.key, item.showIconsOption)
                        }
                      />
                    </Box>
                  }
                </React.Fragment>
              );
            })}
          </Box>
          <Box className="editor">
            <Box className="data">
              <AceEditor
                placeholder={t("workflow specifications")}
                mode="json"
                theme="tomorrow"
                name="testchjhjks"
                // onLoad={this.onLoad}
                onChange={onChange}
                fontSize={12}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                style={{ width: "100%", height: "480px" }}
                value={`${jsonList}`}
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                  enableSnippets: true,
                  showLineNumbers: true,
                  tabSize: 4,
                }}
              />
              {translationData.user_updated && (
                <p>
                  {t("last changed")}: {translationData.user_updated},{" "}
                  {moment(translationData.updated_at).format(
                    "DD.MM.YYYY/h:mm a"
                  )}
                </p>
              )}
              {errorMessage && <p className="error-msg">{errorMessage}</p>}
            </Box>
            <Box className="actions">
              <Box className="right-action">
                <Button
                  variant="outlined"
                  color="primary"
                  className="btn-cancel"
                  onClick={cancel}
                  disabled={!jsonChanged}
                >
                  {t("cancel")}
                </Button>
                <Button
                  className="btn-primary-theme btn-save"
                  variant="contained"
                  color="primary"
                  onClick={submit}
                  disabled={!jsonChanged || !jsonList || invalidJsonFile}
                >
                  <CheckIcon />
                  {t("save changes")}
                </Button>
              </Box>
            </Box>
            {showIconsOption && (
              <Box className="mt-5">
                <IconsListComponent />
              </Box>
            )}
          </Box>
        </Box>
      </section>
    </>
  );
};
