import React, { useContext, useEffect, useState } from "react";
import "./AI-product-step.component.scoped.scss";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import TabContentHeadComponent from "../tab-content-head/tab-content-head.component";
import SelectionBox from "../selection-box/selection-box.component";
import { AIRole, AIRolesList, ROLES } from "../../../types/AI-roles";
import { useTranslation } from "react-i18next";
import { CreateUseCaseContext } from "../../../../../store";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { history } from "react-router-guard";

const AIProductStepComponent = (props: any) => {
  const { onError } = props;
  const [t] = useTranslation();
  const { setUseCase, useCase, lands, branches } = useContext(
    CreateUseCaseContext
  );
  const [roleHover, setRoleHover] = useState(-1);

  useEffect(() => {
    if (
      !useCase.branchId ||
      !useCase.landId ||
      !useCase.role.length ||
      !useCase.title.length ||
      !useCase.version.length ||
      !useCase.brief.length
    ) {
      onError(true);
    } else {
      onError(false);
    }
  }, [useCase, onError]);

  useEffect(() => {
    const matchingRolesLength = AIRolesList.filter((role) =>
      history.location.search.includes(role.role)
    );
    if (matchingRolesLength.length === 1) {
      setUseCase({ ...useCase, role: matchingRolesLength[0].key as ROLES });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box className="content">
      <TabContentHeadComponent
        title={t("USECASE_CREATE.AI_PRODUCT_TITLE")}
        subTitle={t("USECASE_CREATE.AI_PRODUCT_SUB_TITLE")}
      />
      <Box className="mb-4">
        <Typography variant="h5" color="secondary">
          {t("USECASE_CREATE.SELECT_YOUR_BRANCH_AND_YOUR_COMPANY")}
        </Typography>
        <Box className="select-option mx-3 mt-2">
          <FormControl className="select-input select-grey w-100">
            <InputLabel id="branch">{t("BRANCHES")}</InputLabel>
            <Select
              labelId="branch"
              id="branch-select"
              name="branchId"
              value={useCase.branchId || ""}
              onChange={(e: any) =>
                setUseCase({ ...useCase, branchId: e.target.value })
              }
              IconComponent={ExpandMoreIcon}
            >
              {branches.map((item: any, index: any) => {
                return (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl className="select-input select-grey w-100">
            <InputLabel id="country">{t("COUNTRIES")}</InputLabel>
            <Select
              labelId="country"
              id="country-select"
              name="landId"
              value={useCase.landId || ""}
              onChange={(e: any) =>
                setUseCase({ ...useCase, landId: e.target.value })
              }
              IconComponent={ExpandMoreIcon}
            >
              {lands.map((item: any, index: any) => {
                return (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box className="mb-4">
        <Typography variant="h5" color="secondary">
          {t("USECASE_CREATE.YOUR_ROLE")}
        </Typography>
        <Box className="items mx-3 mt-2">
          {AIRolesList.map((role: AIRole, index: number) => {
            return (
              <React.Fragment key={role.id}>
                {history.location.search.includes(role.role) && (
                  <Box
                    onMouseOver={() => setRoleHover(index)}
                    onMouseLeave={() => setRoleHover(-1)}
                  >
                    <SelectionBox
                      title={t(role.title)}
                      subTitle={t(role.subTitle)}
                      icon={role.icon}
                      active={useCase.role === role.key || roleHover === index}
                      activeIcon={role.activeIcon}
                      itemKey={role.key}
                      disabled={role.disabled}
                      onBoxClick={(item: number | string) =>
                        setUseCase({ ...useCase, role: item as ROLES })
                      }
                    />
                  </Box>
                )}
              </React.Fragment>
            );
          })}
        </Box>
        <span className="hint mx-3">
          {t("USECASE_CREATE.SELECT_ROLE_HINT")}
        </span>
      </Box>
      <Box className="product mb-4">
        <Typography variant="h5" color="secondary">
          {t("USECASE_CREATE.YOUR_AI_PRODUCT")}
        </Typography>
        <Box className="form mx-3 mt-2">
          <TextField
            className="w-100"
            label={t("USECASE_CREATE.NAME_OF_YOUR_AI_PRODUCT")}
            variant="filled"
            name="ai-product"
            value={useCase.title}
            onChange={(e: any) =>
              setUseCase({
                ...useCase,
                title: e.target.value.replace(/^\s+/g, ""),
              })
            }
            inputProps={{
              maxLength: 30,
            }}
          />
          {/* <TextField
            className="w-100"
            label={t("VERSION")}
            variant="filled"
            name="Version"
            value={useCase.version}
            onChange={(e: any) =>
              setUseCase({
                ...useCase,
                version: e.target.value.replace(/^\s+/g, ""),
              })
            }
            inputProps={{
              maxLength: 30,
            }}
          />
          <Button color="primary" variant="contained">
            <img src={icons.searchWhite} alt="search" />
            {t("USECASE_CREATE.SEARCH_PAIPER")}
          </Button> */}
        </Box>
      </Box>
      <Box className="mb-4">
        <Typography variant="h5" color="secondary">
          {t("SHORT_DESCRIPTION")}
        </Typography>
        <Box className="mx-3 mt-2">
          <TextField
            id="filled-multiline-flexible"
            value={useCase.brief}
            label={t("SHORT_DESCRIPTION")}
            onChange={(e: any) =>
              setUseCase({
                ...useCase,
                brief: e.target.value.replace(/^\s+/g, ""),
              })
            }
            variant="filled"
            className="text-area-multiline w-100"
            fullWidth={true}
            rows={5}
            multiline
            inputProps={{
              maxLength: 250,
            }}
          />
          <Box className="hint d-flex justify-content-between align-items-center">
            <span>{t("USECASE_CREATE.SHORT_DESCRIPTION_HINT")}</span>
            <span>
              {useCase.brief.length}/{250} {t("character")}
            </span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AIProductStepComponent;
