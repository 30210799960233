import { IconOption } from "../types/iconOption";
import { icons } from "./icons";

export const AreasIconOptions: IconOption[] = [
  {
    key: "CHATBOT",
    name: "chatbot",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.CHATBOT,
  },
  {
    key: "IMAGE_GENERATION",
    name: "image-generation",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.IMAGE_GENERATION,
  },
  {
    key: "IMAGE_RECOGNITION",
    name: "image-recognition",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.IMAGE_RECOGNITION,
  },
  {
    key: "IMAGE_SEGMENTATION",
    name: "image-segmentation",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.IMAGE_SEGMENTATION,
  },
  {
    key: "OBJECT_DETECTION",
    name: "object-detection",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.OBJECT_DETECTION,
  },
  {
    key: "PROCESS_AUTOMATION",
    name: "process-automation",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.PROCESS_AUTOMATION,
  },
  {
    key: "RECOMMENDATION_SYSTEM",
    name: "recommendation-system",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.RECOMMENDATION_SYSTEM,
  },
  {
    key: "ROBOTICS",
    name: "robotics",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.ROBOTICS,
  },
  {
    key: "SEMANTIC_SEARCH",
    name: "semantic-search",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.SEMANTIC_SEARCH,
  },
  {
    key: "SENTIMENT_ANALYSIS",
    name: "sentiment-analysis",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.SENTIMENT_ANALYSIS,
  },
  {
    key: "SPEECH_RECOGNITION",
    name: "speech-recognition",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.SPEECH_RECOGNITION,
  },
  {
    key: "TEXT_GENERATION",
    name: "text-generation",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.TEXT_GENERATION,
  },

  {
    key: "TRANSLATION",
    name: "translation",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.TRANSLATION,
  },
];
