import React, { useContext, useState } from "react";
import "./print-case-content.component.scoped.scss";
import { CaseContext } from "../../../../../store";
import { Box, Typography } from "@material-ui/core";
import PrintCaseHeaderComponent from "../print-case-header/print-case-header.component";
import PrintCaseFooterComponent from "../print-case-footer/print-case-footer.component";
import { AIRolesByKey } from "../../../types/AI-roles";
import { useTranslation } from "react-i18next";
import { MachineLearningType } from "../../../types/machine-learning-types";
import { AIArea } from "../../../types/AI-areas";
import { icons } from "../../../../../statics/icons";
import { formatDate } from "../../../../../helpers";
import { AITechnique } from "../../../types/AI-techniques";

interface printCaseContentProps {}

const PrintCaseContentComponent = (props: printCaseContentProps) => {
  const [t] = useTranslation();
  const { caseDetails } = useContext(CaseContext);
  const [userImageError, setUserImageError] = useState(false);

  return (
    <Box style={{ minHeight: "329mm" }}>
      <PrintCaseHeaderComponent />
      <Box className="content">
        <Box className="head">
          <Box className="title">
            <Typography variant="body2" color="primary">
              {caseDetails.internalId}
            </Typography>
            <Typography
              variant="h1"
              color="primary"
              className="text-uppercase"
              style={{ fontSize: "40px" }}
            >
              {caseDetails.title}
            </Typography>
            <Typography
              className="mt-2"
              variant="body2"
              color="textSecondary"
              style={{
                minHeight: 60,
                wordBreak: "break-word",
              }}
            >
              {caseDetails.brief}
            </Typography>
          </Box>
          <Box className="info">
            <Box className="role">
              <Typography
                className="text-uppercase"
                variant="body1"
                style={{ color: "var(--grey-text)" }}
              >
                {AIRolesByKey[caseDetails.role]?.name} {t("VERSION")}
              </Typography>

              <img
                src={AIRolesByKey[caseDetails.role]?.icon}
                alt={AIRolesByKey[caseDetails.role]?.title}
                title={AIRolesByKey[caseDetails.role]?.title}
              />
            </Box>
            <Box className="category mt-3">
              <Typography
                variant="body1"
                style={{ fontWeight: 400, color: "#fff" }}
              >
                {t("RISK_LEVEL")}
              </Typography>

              <Typography
                variant="subtitle2"
                style={{ fontWeight: 400, color: "#fff" }}
                className="mt-2"
              >
                {caseDetails.category.name}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="primary"
              className="text-right mt-2"
              style={{ fontWeight: 400 }}
            >
              {t("VERSION")} {caseDetails.version}
              <br />
              {t("RELEASE")},{" "}
              {caseDetails.major.toLocaleString("en-US", {
                minimumIntegerDigits: 2,
              })}
              .
              {caseDetails.minor.toLocaleString("en-US", {
                minimumIntegerDigits: 2,
              })}
              .
              {caseDetails.version.toLocaleString("en-US", {
                minimumIntegerDigits: 2,
              })}
            </Typography>
          </Box>
        </Box>
        <Box className="body">
          <Box className="details">
            <Typography variant="h5" color="primary">
              {t("USECASE_DETAILS.AI_CLASSIFICATION")}
            </Typography>
            <Box className="classification mt-2">
              <Box>
                <Box className="techniques">
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    style={{ fontWeight: 400 }}
                  >
                    {t("USECASE_DETAILS.AI_TECHNIQUES_AND_APPROACHES")}
                  </Typography>
                  <Box className="items">
                    {caseDetails.techniques.map(
                      (item: AITechnique, index: number) => (
                        <Box key={index} className="item">
                          {item.name}
                        </Box>
                      )
                    )}
                  </Box>
                </Box>
                <Box className="machine mt-3">
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    style={{ fontWeight: 400 }}
                  >
                    {t("USECASE_DETAILS.MACHINE_LEARNING_TYPES")}
                  </Typography>
                  <Box className="items">
                    {caseDetails.machineLearning.map(
                      (item: MachineLearningType, index: number) => (
                        <Box key={index} className="item">
                          {item.name}
                        </Box>
                      )
                    )}
                  </Box>
                </Box>
                <Box className="algorithm mt-3">
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    style={{ fontWeight: 400 }}
                  >
                    {t("AI_ALGORITHM")}
                  </Typography>
                  <Box className="items">
                    <Box className="item">{caseDetails?.algorithm?.name}</Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{ fontWeight: 400 }}
                >
                  {t("AI_TRAINING_DATA_DESCRIPTION")}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ padding: "10px 15px", color: "var(--grey-text)" }}
                  dangerouslySetInnerHTML={{
                    __html: caseDetails.description,
                  }}
                ></Typography>
              </Box>
            </Box>
            <Box className="areas">
              <Typography
                variant="subtitle1"
                color="textSecondary"
                style={{ fontWeight: 400 }}
              >
                {t(
                  "USECASE_DETAILS.NAME_THE_MAIN_AREAS_OF_APPLICATION_FOR_THE_AI_PRODUCT"
                )}
              </Typography>
              <Box className="items">
                {caseDetails.areas.map((item: AIArea, index: number) => (
                  <Box key={index} className="item">
                    <img src={icons.categoryWhite} alt={item.name} />
                    {item.name}
                  </Box>
                ))}
              </Box>
            </Box>

            <Box className="mt-4">
              <Typography variant="h5" color="primary">
                {t("USE_CASE_DESCRIPTION")}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  columnCount: 2,
                  color: "var(--grey-text)",
                  columnGap: "20px",
                  margin: "auto",
                  padding: "10px 15px",
                }}
                dangerouslySetInnerHTML={{
                  __html: caseDetails.scenarioDescription,
                }}
              ></Typography>
            </Box>
          </Box>
          <Box className="sidenav">
            <Typography
              variant="h5"
              style={{ fontWeight: 400 }}
              color="primary"
              className="text-center"
            >
              {t("KEY_CONTACT")}
            </Typography>

            <Box className="user-info">
              <Box className="user-img">
                {caseDetails.keyContactUser.profilePicURL && !userImageError ? (
                  <img
                    src={caseDetails.keyContactUser.profilePicURL}
                    title={
                      caseDetails.keyContactUser.firstName +
                      " " +
                      caseDetails.keyContactUser.lastName
                    }
                    alt=""
                    onError={setUserImageError.bind(null, true)}
                  />
                ) : (
                  <span className="text-uppercase">
                    {caseDetails.keyContactUser.firstName
                      ? caseDetails.keyContactUser.firstName[0] +
                        caseDetails.keyContactUser.lastName[0]
                      : ""}
                  </span>
                )}
              </Box>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                className="mt-2"
                style={{ fontWeight: 400 }}
              >
                {caseDetails.keyContactUser.firstName +
                  " " +
                  caseDetails.keyContactUser.lastName}
              </Typography>
              <a
                href={`mailto:${caseDetails.keyContactUser.email}`}
                style={{ textDecoration: "underline" }}
              >
                {caseDetails.keyContactUser.email}
              </a>
            </Box>
            <Typography
              variant="h5"
              className="mt-5 text-center"
              style={{ fontWeight: 400 }}
              color="primary"
            >
              {t("HARD_FACTS")}
            </Typography>

            <Box className="mt-3">
              <Typography variant="body2" style={{ color: "var(--grey-text)" }}>
                {t("CREATED_DATE")}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{ fontWeight: 400 }}
                color="textSecondary"
              >
                {formatDate(caseDetails.createdAt, "DD/MM/YYYY")}
              </Typography>
            </Box>
            <Box className="mt-3">
              <Typography variant="body2" style={{ color: "var(--grey-text)" }}>
                {t("CURRENT_STATE")}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{ fontWeight: 400 }}
                color="textSecondary"
              >
                {caseDetails.status}
              </Typography>
            </Box>

            <Box className="mt-3">
              <Typography variant="body2" style={{ color: "var(--grey-text)" }}>
                {t("COMPANY")}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{ fontWeight: 400 }}
                color="textSecondary"
              >
                {caseDetails.clientName}
              </Typography>
            </Box>

            <Box className="mt-3">
              <Typography variant="body2" style={{ color: "var(--grey-text)" }}>
                {t("COUNTRY")}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{ fontWeight: 400 }}
                color="textSecondary"
              >
                {caseDetails.land}
              </Typography>
            </Box>

            <Box className="mt-3">
              <Typography variant="body2" style={{ color: "var(--grey-text)" }}>
                {t("BRANCH")}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{ fontWeight: 400 }}
                color="textSecondary"
              >
                {caseDetails.branch}
              </Typography>
            </Box>

            <img
              className="float-img"
              src="/icons/create-case/AI-logo.svg"
              alt="AI"
            />
          </Box>
        </Box>
      </Box>
      <PrintCaseFooterComponent />
    </Box>
  );
};

export default PrintCaseContentComponent;
