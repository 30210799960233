import axios from "axios";
import { LocalStorageService } from "../services/local-storage/local-storage";
import { history } from "react-router-guard";

export const setupInterceptors = () => {
  const localStorageService: LocalStorageService = new LocalStorageService();
  // const authService: AuthService = new AuthService();

  axios.interceptors.request.use(
    (req) => {
      // Do something before request is sent
      const tenantId = localStorageService.getData("tenantId");
      if (
        !req.url?.includes("token") &&
        !req.url?.includes("amazonaws.com") &&
        !req.url?.includes("verify-new-user") &&
        !req.url?.includes("can-set-password") &&
        !req.url?.includes("https://storage") &&
        !req.headers.Authorization
      ) {
        const token = localStorageService.getData("token");
        // const lang = langService.getCurrentLang();
        req.headers.Authorization = "Bearer " + token.access_token;

        if (!req.url?.includes("keycloak") && !req.url?.includes("identity")) {
          req.headers.lang = localStorageService.getData("lang") || "de";
        }

        if (
          !req.url?.includes("keycloak") &&
          !req.url?.includes("me/tenants") &&
          !req.url?.includes("login") &&
          !req.url?.includes("add-user-to-tenant") &&
          !req.url?.includes("add-user") &&
          !req.url?.includes("identity") &&
          !req.url?.includes("https://storage-")
        ) {
          if (req.params) {
            req.params.tenantId = tenantId;
          } else {
            req.params = { tenantId };
          }
        }
      }
      return req;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (res) => res,
    async (err) => {
      // handle keycloack token expiration.
      if (err.response.status === 403) {
        localStorageService.clearAll();
        history.push("/login");
        // handle refresh token
        // try {
        // const token = await authService.refreshToken();
        // err.response.config.headers['Authorization'] = 'Bearer ' + token.access_token;
        // return axios(err.response.config);
        // } catch (error) {
        // }
      }

      // handle unauthorized requests - token expiration.
      if (err.response.status === 401) {
        const url = err.response.config.url;
        if (url.includes("cases/")) {
          history.push("/landscape");
        } else {
          const tenantId = localStorageService.getData("tenantId");
          const tenantName = localStorageService.getData("tenantName");
          const tenantIcon = localStorageService.getData("tenantIcon");
          localStorageService.clearAll();
          localStorageService.setData("tenantId", tenantId);
          localStorageService.setData("tenantName", tenantName);
          localStorageService.setData("tenantIcon", tenantIcon);
          history.push("/login");
        }
      }
      throw err.response.data;
    }
  );
};
