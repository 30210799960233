import { IconOption } from "../types/iconOption";
import { icons } from "./icons";

export const AlgorithmsIconOptions: IconOption[] = [
  {
    key: "ALGORITHM",
    name: "algorithm",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.ALGORITHM,
  },
  {
    key: "ANN",
    name: "ANN",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.ANN,
  },
  {
    key: "APRIORI",
    name: "apriori",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.APRIORI,
  },
  {
    key: "CUSTOM",
    name: "custom",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.CUSTOM,
  },
  {
    key: "DEEPLEARNING_CNN",
    name: "deepLearning-CNN",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.DEEPLEARNING_CNN,
  },
  {
    key: "DEEPLEARNING_RNN",
    name: "deepLearning-RNN",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.DEEPLEARNING_RNN,
  },
  {
    key: "KMEANS",
    name: "k-means",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.KMEANS,
  },
  {
    key: "LSTM",
    name: "LSTM",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.LSTM,
  },
  {
    key: "PCA",
    name: "PCA",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.PCA,
  },
  {
    key: "RANDOM_FOREST_V2",
    name: "random-forest-V2",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.RANDOM_FOREST_V2,
  },
  {
    key: "SVMV2",
    name: "SVMV2",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.SVMV2,
  },
  {
    key: "TRANSFORMATION",
    name: "transformation",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.TRANSFORMATION,
  },
  {
    key: "XG_BOOST",
    name: "XG-boost",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.XG_BOOST,
  },
];
