import { newCasesColumns } from "./table-columns/new-cases-columns";
import { newCommentsColumns } from "./table-columns/new-comments-columns";

export const TableCards = [
  {
    id: "1",
    title: "COMMENTS",
    subTitle: "newest internal comments",
    tableName: "internal comments",
    columns: newCommentsColumns,
  },
  {
    id: "2",
    title: "NEW_AI_APPLICATIONS",
    subTitle: "newest cases",
    tableName: "cases",
    columns: newCasesColumns,
  },
];
