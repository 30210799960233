export const DefaultCategories = [
  {
    name: "High Risk Provider",
    translation: "Anbieter mit hohem Risiko",
    slug: "high-risk-provider",
    prefix: "HPR",
    key: "HPROV",
    src: "betrug.svg",
    activeSrc: "betrug_active.svg",
  },
  {
    name: "High Risk Distributer",
    translation: "Verteiler mit hohem Risiko",
    slug: "high-risk-distributer",
    prefix: "HDI",
    key: "HDIST",
    src: "marktmanipulation.svg",
    activeSrc: "marktmanipultaion_active.svg",
  },
  {
    name: "High Risk User",
    translation: "Benutzer mit hohem Risiko",
    slug: "high-risk-user",
    prefix: "HUR",
    key: "HUSER",
    src: "sexuelleBelaestigung.svg",
    activeSrc: "sexuelleBelaestigung_active.svg",
  },
  {
    name: "High Risk Importer",
    translation: "Hohes Risiko Importeur",
    slug: "high-risk-importer",
    prefix: "HIM",
    key: "HIMPO",
    src: "mobbing.svg",
    activeSrc: "mobbing_active.svg",
  },
  {
    name: "Limited Risk Provider",
    translation: "Begrenztes Risiko Anbieter",
    slug: "limited-risk-provider",
    prefix: "LPR",
    key: "LPROV",
    src: "datenschutz.svg",
    activeSrc: "datenschutz_active.svg",
  },
  {
    name: "Limited Risk Distributer",
    translation: "Begrenztes Risiko Verteiler",
    slug: "limited-risk-distributer",
    prefix: "LDI",
    key: "LDIST",
    src: "money-laundering.svg",
    activeSrc: "money-laundering_active.svg",
  },
  {
    name: "Limited Risk User",
    translation: "Begrenztes Risiko Benutzer",
    slug: "limited-risk-user",
    prefix: "LUR",
    key: "LUSER",
    src: "diebstahl.svg",
    activeSrc: "diebstahl_active.svg",
  },
  {
    name: "Limited Risk Importer",
    translation: "Importeur mit begrenztem Risiko",
    slug: "limited-risk-importer",
    prefix: "LIM",
    key: "LIMPO",
    src: "umweltschutz.svg",
    activeSrc: "umweltschutz_active.svg",
  },
  {
    name: "Generative AI",
    translation: "Generative KI",
    slug: "generative-ai",
    prefix: "GAI",
    key: "GENAI",
    src: "prozessoptimierung.svg",
    activeSrc: "prozessoptimierung_active.svg",
  },
  {
    name: "Low Risk",
    translation: "Geringes Risiko",
    slug: "low-risk",
    prefix: "LRI",
    key: "LRISK",
    src: "sicherheitsluecke.svg",
    activeSrc: "sicherheitsluecke_active.svg",
  },
  {
    name: "Unacceptable Risk",
    translation: "Inakzeptables Risiko",
    slug: "unacceptable-risk",
    prefix: "UNR",
    key: "URISK",
    src: "verbesserungsvorschlag.svg",
    activeSrc: "verbesserungsvorschlag_active.svg",
  },
  {
    name: "Sonstiges / Other",
    translation: "Sonstiges / Sonstiges",
    slug: "sonstiges-other",
    prefix: "STO",
    key: "SONS",
    src: "patientenschutz.svg",
    activeSrc: "patientenschutz_active.svg",
  },
];
