import React from "react";
import "./forgot-password.page.scoped.scss";
import { SignUpLayout } from "../../layouts/signup/sign-up.layout";
import { useTranslation } from "react-i18next";
import { CommonService } from "../../services/common/common.service";
import ForgotPasswordContentComponent from "../../modules/auth/components/forgot-password-content/forgot-password-content.component";

const commonService: CommonService = new CommonService();

const ForgotPasswordPage = () => {
  const [t] = useTranslation();
  commonService.setTitle(t("Case Management"));

  return (
    <SignUpLayout>
      <ForgotPasswordContentComponent />
    </SignUpLayout>
  );
};

export default ForgotPasswordPage;
