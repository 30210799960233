import React, { useContext, useEffect, useState } from "react";
import "./AI-scenario-step.component.scoped.scss";
import { Box, Typography } from "@material-ui/core";
import TabContentHeadComponent from "../tab-content-head/tab-content-head.component";
import { CreateUseCaseContext } from "../../../../../store";
import { useTranslation } from "react-i18next";
// import { urlRegex } from "../../../../../statics/validators";
import AIFileUploadSection from "../AI-file-upload-section/AI-file-upload-section.component";
import TextEditorComponent from "../../../../../components/text-editor/text-editor.component";
import { htmlTagRegex } from "../../../../../statics/validators";

const AIScenarioStepComponent = (props: any) => {
  const [t] = useTranslation();

  const { onError } = props;
  const { useCase, setUseCase } = useContext(CreateUseCaseContext);
  const [textEditor, setTextEditor] = useState(useCase.scenarioDescription);
  // const [linkError, setLinkError] = useState(false);

  useEffect(() => {
    if (
      !useCase.scenarioDescription.replace(htmlTagRegex, "").length ||
      useCase.scenarioDescription.replace(htmlTagRegex, "").length > 800
    ) {
      onError(true);
    } else {
      onError(false);
    }
  }, [useCase, onError]);

  useEffect(() => {
    return () => {
      setTextEditor(useCase.scenarioDescription);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box className="content">
      <TabContentHeadComponent
        title={t("USECASE_CREATE.AI_SCENARIO_TITLE")}
        subTitle={t("USECASE_CREATE.AI_SCENARIO_SUB_TITLE")}
      />
      <Box className="mb-4">
        <Typography variant="h5" color="secondary">
          {t("SUMMARY")}
        </Typography>
        <Box className="mx-3 mt-2">
          <TextEditorComponent
            id="scenario_description"
            value={textEditor}
            onChange={(e: any) =>
              setUseCase({
                ...useCase,
                scenarioDescription: e,
              })
            }
            error={
              useCase.scenarioDescription.replace(htmlTagRegex, "").length > 800
            }
          />
          <Box className="hint d-flex justify-content-between align-items-center">
            <span>{t("USECASE_CREATE.SCENARIO_DESCRIPTION_HINT")}</span>
            <span>
              {useCase.scenarioDescription.replace(htmlTagRegex, "").length}/
              {800} {t("character")}
            </span>
          </Box>
        </Box>
        {/* <Box className="mx-3 mt-4">
          <TextField
            className="w-100"
            label={`Link (${t("OPTIONAL")})`}
            placeholder="e.g. https://www.atlassian.com"
            variant="filled"
            error={linkError}
            name="ai-product"
            value={useCase.link}
            onChange={(e: any) => {
              setUseCase({ ...useCase, link: e.target.value });
              e.target.value
                ? setLinkError(!urlRegex.test(e.target.value))
                : setLinkError(false);
            }}
          />
          <span className="hint">{t("USECASE_CREATE.SCENARIO_LINK_HINT")}</span>
        </Box> */}
      </Box>
      <AIFileUploadSection />
    </Box>
  );
};
export default AIScenarioStepComponent;
