import "./sidenav.component.scoped.scss";
import React, { useContext } from "react";
import { SideNavItemsComponent } from "../sidenav-items/sidenav-items.component";
import { GlobalContext } from "../../store";
import { icons } from "../../statics/icons";
import { history } from "react-router-guard";
import { LocalStorageService } from "../../services/local-storage/local-storage";

export const SideNavComponent = () => {
  const { fullNav } = useContext(GlobalContext);
  const localStorageService = new LocalStorageService();
  const tenantName = localStorageService.getData("tenantName");

  const handleLogoClick = () => {
    history.replace("/landscape");
  };

  return (
    <div className={`main-nav ${fullNav ? "full" : ""}`}>
      <div className="sidenav">
        <div onClick={handleLogoClick} className="main-logo">
          <img src={icons.logo} alt="paladin" />
          {fullNav && <div className="app-title">{tenantName}</div>}
        </div>
        <SideNavItemsComponent />
      </div>
    </div>
  );
};
