import React, { useCallback } from "react";
import "./files-drop-zone.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";

const FilesDropZone = (props: any) => {
  const [t] = useTranslation();
  const onDrop = useCallback((acceptedFiles) => {
    props.onDropFiles(acceptedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    accept: props.acceptTypes,
  });

  return (
    <div {...getRootProps({ className: "dropzone h-100" })}>
      {isDragActive && !props.isSaving ? (
        <div className="dropzone-overlay">
          <div className="drop-img">
            <img src="/file-upload.png" alt="" />
          </div>
          <h3 className="drop-title">{t("upload files")}</h3>
          <p className="drop-text">{t("release the file to upload")}</p>
        </div>
      ) : null}
      {props.children}
    </div>
  );
};

export default FilesDropZone;
