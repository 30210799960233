import React, { useContext, useState } from "react";
import "./case-tabs.component.scoped.scss";
import { Box } from "@material-ui/core";
import { CaseTabItem, caseTabItems } from "../../../types/case-tabs";
import { useTranslation } from "react-i18next";
import { icons } from "../../../../../statics/icons";
import { Skeleton } from "@material-ui/lab";
import { CaseContext } from "../../../../../store";

const CaseTabsComponent: React.FunctionComponent<{
  onChangeTab: (tab: number) => void;
}> = ({ onChangeTab }) => {
  const [t] = useTranslation();
  const [activeTab, setActiveTab] = useState(1);
  const { loading, caseDetails } = useContext(CaseContext);

  const onClickTab = (item: CaseTabItem) => {
    if (!loading) {
      setActiveTab(item.tab);
      onChangeTab(item.tab);
    }
  };

  return (
    <Box className="content">
      <Box className="items">
        {/* <Box
          className="item first"
          onClick={() => {
            setActiveTab(1);
            onChangeTab(1);
          }}
        >
          {loading ? (
            <Skeleton variant="circle" height={25} width={25} />
          ) : (
            <img src={icons.logoWhite} width={30} alt="logo" />
          )}
          {loading ? (
            <Skeleton variant="text" height={20} width={100} />
          ) : (
            <span className="title">{t("TASKS")}</span>
          )}
        </Box> */}

        {/* {item.permission.includes(caseDetails.role) && ( */}

        {caseTabItems.map((item: CaseTabItem, index: number) => (
          <React.Fragment key={index}>
            {loading ? (
              <Box className="item">
                <Skeleton variant="circle" height={25} width={25} />
                <Skeleton variant="text" height={20} width={120} />
              </Box>
            ) : (
              <React.Fragment>
                {item.roles.includes(caseDetails.role) && (
                  <Box
                    onClick={onClickTab.bind(null, item)}
                    className={`item ${item.tab === activeTab ? "active" : ""}`}
                    key={index}
                  >
                    {item.tab === 1 ? (
                      <img src={icons.logo} width={30} alt="logo" />
                    ) : (
                      <Box
                        className={`circle ${
                          item.tab === 6 &&
                          caseDetails.tasks - caseDetails.completedTasks !==
                            0 &&
                          "bg-danger"
                        }`}
                      >
                        {item.tab === 4 &&
                        caseDetails.totalQuestions !==
                          caseDetails.answeredQuestions ? (
                          <span className="count">
                            {caseDetails.answeredQuestions}
                          </span>
                        ) : item.tab === 6 ? (
                          <span className="tasks">
                            {caseDetails.tasks - caseDetails.completedTasks ===
                            0 ? (
                              <span>{caseDetails.tasks}</span>
                            ) : (
                              <span className="task-alert">
                                {caseDetails.tasks - caseDetails.completedTasks}
                              </span>
                            )}
                          </span>
                        ) : (
                          <img
                            src={
                              item.tab === activeTab
                                ? item.activeIcon
                                : item.icon
                            }
                            alt=""
                          />
                        )}
                      </Box>
                    )}

                    <span
                      className={`title ${
                        caseDetails.tasks - caseDetails.completedTasks > 0 &&
                        item.tab === 6 &&
                        "task-alert"
                      }`}
                    >
                      {t(item.name)}{" "}
                      {item.tab === 4 &&
                        `(${caseDetails.answeredQuestions}/${caseDetails.totalQuestions})`}
                    </span>
                  </Box>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default CaseTabsComponent;
