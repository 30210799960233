import { TableColumn } from "../../types/table-column";

export const AIDemandColumns: TableColumn[] = [
  // {
  //   type: "checkbox",
  //   fieldName: "checkbox",
  //   value: "id",
  //   width: 2,
  // },
  {
    type: "string",
    fieldName: "AI_SCENARIO",
    value: "title",
    secondaryValue: "internalId",
    width: 14,
  },
  {
    type: "object",
    fieldName: "USED_IN",
    value: "operation",
    width: 10,
  },
  {
    type: "arrayIcons",
    fieldName: "APPLICATION_AREAS",
    value: "areas",
    width: 16,
  },
  {
    type: "release",
    fieldName: "RELEASE",
    value: "release",
    width: 5,
  },
  {
    type: "object",
    fieldName: "RISK",
    value: "category",
    width: 12,
  },
  {
    type: "questions_status",
    fieldName: "PROGRESS",
    value: "answeredQuestions",
    width: 9,
  },
  {
    type: "string",
    fieldName: "CONTACT",
    value: "keyContact",
    width: 10,
  },
  {
    type: "date",
    fieldName: "LAST_MODIFIED",
    value: "updatedAt",
    width: 8,
  },
];
