import React, { useState } from "react";
import { Button, Chip, CircularProgress, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import "./create-workspace-content.component.scoped.scss";
import { Land } from "../../types/land";
import { Relationship } from "../../types/relationship";
import { CategoryItem } from "../../types/category-item";
import { Resolution } from "../../types/resolution";
import { Branch } from "../../types/branch";
import { ContactInfo } from "../../types/contact-info";
import { amountsValues } from "../../statics/amounts";
import { statusesValues } from "../../statics/statuses";
import { WorkspacesService } from "../../services/workspaces/workspaces.service";

const workspacesService: WorkspacesService = new WorkspacesService();

const CreateWorkspaceContent = () => {
  const [t] = useTranslation();
  const [workspaceName, setWorkspaceName] = useState("");
  const [defaultPass, setDefaultPass] = useState("");
  const [lands, setLands] = useState<Land[]>([]);
  const [land, setLand] = useState("");
  const [branches, setBranches] = useState<Branch[]>([]);
  const [branch, setBranch] = useState("");
  const [relationships, setRelationships] = useState<Relationship[]>([]);
  const [relationship, setRelationship] = useState("");
  const [relationshipTranslation, setRelationshipTranslation] = useState("");
  const [categories, setCategories] = useState<CategoryItem[]>([]);
  const [category, setCategory] = useState("");
  const [categoryPrefix, setCategoryPrefix] = useState("");
  const [categoryTranslation, setCategoryTranslation] = useState("");
  const [resolutions, setResolutions] = useState<Resolution[]>([]);
  const [resolution, setResolution] = useState("");
  const [resolutionTranslation, setResolutionTranslation] = useState("");
  const [contactInfo, setContactInfo] = useState(new ContactInfo());
  const [saving, setSaving] = useState(false);

  const emptyArr: any = [];
  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleAddLandClick = () => {
    const newLand = { name: land };
    const landsCopy: Land[] = [...lands];
    landsCopy.push(newLand);
    setLands(landsCopy);
    setLand("");
  };

  const handleLandDelete = (name: string) => {
    const newLands = lands.filter((item) => item.name !== name);
    setLands(newLands);
  };

  const handleAddRelationshipClick = () => {
    const newRelationship = {
      name: relationship,
      translation: relationshipTranslation,
    };
    const RelationshipsCopy: Relationship[] = [...relationships];
    RelationshipsCopy.push(newRelationship);
    setRelationships(RelationshipsCopy);
    setRelationship("");
    setRelationshipTranslation("");
  };

  const handleRelationshipDelete = (name: string) => {
    const newRelationships = relationships.filter((item) => item.name !== name);
    setRelationships(newRelationships);
  };

  const handleAddCategoryClick = () => {
    const newCategory = {
      name: category,
      prefix: categoryPrefix,
      translation: categoryTranslation,
    };
    const CategoriesCopy: CategoryItem[] = [...categories];
    CategoriesCopy.push(newCategory);
    setCategories(CategoriesCopy);
    setCategory("");
    setCategoryPrefix("");
    setCategoryTranslation("");
  };

  const handleCategoryDelete = (name: string) => {
    const newCategorys = categories.filter((item) => item.name !== name);
    setCategories(newCategorys);
  };

  const handleAddBranchClick = () => {
    const newBranch = {
      name: branch,
    };
    const branchesCopy: Branch[] = [...branches];
    branchesCopy.push(newBranch);
    setBranches(branchesCopy);
    setBranch("");
  };

  const handleBranchDelete = (name: string) => {
    const newBranches = branches.filter((item) => item.name !== name);
    setBranches(newBranches);
  };

  const handleAddResolutionClick = () => {
    const newResolution = {
      name: resolution,
      translation: resolutionTranslation,
    };
    const ResolutionsCopy: Resolution[] = [...resolutions];
    ResolutionsCopy.push(newResolution);
    setResolutions(ResolutionsCopy);
    setResolution("");
    setResolutionTranslation("");
  };

  const handleResolutionDelete = (name: string) => {
    const newResolutions = resolutions.filter((item) => item.name !== name);
    setResolutions(newResolutions);
  };

  const handleContactInfoChange = (event: any) => {
    const value = event.target.value;
    const name = event.target.name;
    const obj: any = {};
    obj[name] = value;
    setContactInfo({ ...contactInfo, ...obj });
  };

  const submit = async () => {
    setSaving(true);
    const bodyObj = {
      workspace: workspaceName.replace(/ /g, "_"),
      contactInfo,
      lands,
      amounts: amountsValues,
      branches,
      categories,
      status: statusesValues,
      relationships,
      resolutions,
      defaultPassword: defaultPass,
      clientName: workspaceName,
    };
    await workspacesService.addNewWorkspace(bodyObj);
    setResolutions(emptyArr);
    setRelationships(emptyArr);
    setCategories(emptyArr);
    setBranches(emptyArr);
    setLands(emptyArr);
    setContactInfo(new ContactInfo());
    setWorkspaceName("");
    setDefaultPass("");
    setSaving(false);
  };

  return (
    <div className="wrapper p-5">
      <section>
        <h3 className="section-title">{t("users")}</h3>
        <div className="row">
          <div className="col-6 mb-40px">
            <div className="profile-card">
              <div className="card-wrapper">
                <div className="card-content">
                  <div className="mt-3 mb-4">
                    <div className="card-header">{t("main data")}</div>
                    <div className="row no-gutters flex-wrap justify-content-between">
                      <div className="w-100 p-3">
                        <TextField
                          className="w-100"
                          label={t("Workspace name")}
                          variant="filled"
                          name="workspaceName"
                          value={workspaceName}
                          onChange={(e: any) =>
                            setWorkspaceName(e.target.value.replace(/ /g, "_"))
                          }
                        />
                      </div>
                      <div className="w-100 p-3">
                        <TextField
                          className="w-100"
                          label={t("Default password")}
                          variant="filled"
                          name="defaultPassword"
                          value={defaultPass}
                          onChange={(e: any) => setDefaultPass(e.target.value)}
                        />
                      </div>
                      {/* ///////// land //////////// */}
                      <div className="d-flex flex-wrap justify-content-between align-items-center w-100 p-3">
                        <div className="">
                          <TextField
                            style={{ width: "270px" }}
                            label={t("Land")}
                            variant="filled"
                            name="workspace name"
                            value={land}
                            onChange={(e: any) =>
                              setLand(capitalize(e.target.value))
                            }
                          />
                        </div>
                        <div className="">
                          <Button
                            onClick={handleAddLandClick}
                            className="btn-primary-theme filter-btn"
                            style={{ width: "200px" }}
                            variant="contained"
                            color="primary"
                            disabled={!land}
                          >
                            {t("Add land")}
                          </Button>
                        </div>
                        <div className="w-100 d-flex flex-wrap">
                          {lands.map((item: any, index) => {
                            return (
                              <div key={index} className="mt-3 mx-2">
                                <Chip
                                  label={item.name}
                                  onDelete={handleLandDelete.bind(
                                    null,
                                    item.name
                                  )}
                                  // className={classes.chip}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* ////////////branches////////// */}
                      <div className="d-flex flex-wrap justify-content-between align-items-center w-100 p-3 ">
                        <div className="">
                          <TextField
                            style={{ width: "270px" }}
                            label={t("Branch")}
                            variant="filled"
                            name="branch"
                            value={branch}
                            onChange={(e: any) =>
                              setBranch(capitalize(e.target.value))
                            }
                          />
                        </div>
                        <div className="">
                          <Button
                            onClick={handleAddBranchClick}
                            className="btn-primary-theme filter-btn"
                            style={{ width: "200px" }}
                            variant="contained"
                            color="primary"
                            disabled={!branch}
                          >
                            {t("Add branch")}
                          </Button>
                        </div>
                        <div className="w-100 d-flex flex-wrap">
                          {branches.map((item: any, index) => {
                            return (
                              <div key={index} className="mt-3 mx-2">
                                <Chip
                                  // icon={icon}
                                  label={item.name}
                                  onDelete={handleBranchDelete.bind(
                                    null,
                                    item.name
                                  )}
                                  // className={classes.chip}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6 mb-40px">
            <div className="company-card">
              <div className="card-wrapper">
                <div className="card-content">
                  <div className="mt-3 mb-4">
                    <div className="card-header">{t("contact info")}</div>
                    <div className="row no-gutters flex-wrap justify-content-between">
                      <div className="contact-info d-flex flex-wrap">
                        <div className="w-50 p-3">
                          <TextField
                            className="w-100"
                            label={t("external admin name")}
                            variant="filled"
                            name="externalAdminName"
                            value={contactInfo.externalAdminName}
                            onChange={handleContactInfoChange}
                          />
                        </div>

                        <div className="w-50 p-3">
                          <TextField
                            className="w-100"
                            label={t("external admin email")}
                            variant="filled"
                            name="externalAdminEmail"
                            value={contactInfo.externalAdminEmail}
                            onChange={handleContactInfoChange}
                          />
                        </div>

                        <div className="w-50 p-3">
                          <TextField
                            className="w-100"
                            label={t("external admin number")}
                            variant="filled"
                            name="externalAdminNumber"
                            value={contactInfo.externalAdminNumber}
                            onChange={handleContactInfoChange}
                          />
                        </div>

                        <div className="w-50 p-3">
                          <TextField
                            className="w-100"
                            label={t("internal admin name")}
                            variant="filled"
                            name="internalAdminName"
                            value={contactInfo.internalAdminName}
                            onChange={handleContactInfoChange}
                          />
                        </div>

                        <div className="w-50 p-3">
                          <TextField
                            className="w-100"
                            label={t("internal admin email")}
                            variant="filled"
                            name="internalAdminEmail"
                            value={contactInfo.internalAdminEmail}
                            onChange={handleContactInfoChange}
                          />
                        </div>

                        <div className="w-50 p-3">
                          <TextField
                            className="w-100"
                            label={t("internal admin number")}
                            variant="filled"
                            name="internalAdminNumber"
                            value={contactInfo.internalAdminNumber}
                            onChange={handleContactInfoChange}
                          />
                        </div>

                        <div className="w-50 p-3">
                          <TextField
                            className="w-100"
                            label={t("tech questions email")}
                            variant="filled"
                            name="techQuestionsEmail"
                            value={contactInfo.techQuestionsEmail}
                            onChange={handleContactInfoChange}
                          />
                        </div>

                        <div className="w-50 p-3">
                          <TextField
                            className="w-100"
                            label={t("organisation questions email")}
                            variant="filled"
                            name="organisationQuestionEmail"
                            value={contactInfo.organisationQuestionEmail}
                            onChange={handleContactInfoChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-40px">
            <div className="card-wrapper">
              <div className="card-content">
                <div className="card-text categories-label mt-3 mb-4">
                  <div className="card-header">{t("other data")}</div>

                  {/* ///////////////// categories /////////// */}
                  <div className="categories d-flex flex-wrap">
                    <div className="p-3">
                      <TextField
                        className="w-100"
                        label={t("Category name (EN)")}
                        variant="filled"
                        name="CategoryName"
                        value={category}
                        onChange={(e: any) => setCategory(e.target.value)}
                      />
                    </div>

                    <div className=" p-3">
                      <TextField
                        className="w-100"
                        label={t("Category prefix")}
                        variant="filled"
                        name="CategoryPrefix"
                        value={categoryPrefix}
                        onChange={(e: any) =>
                          setCategoryPrefix(e.target.value.toUpperCase())
                        }
                        inputProps={{
                          maxLength: 3,
                        }}
                      />
                    </div>

                    <div className=" p-3">
                      <TextField
                        className="w-100"
                        label={t("Category translation (DE)")}
                        variant="filled"
                        name="Category translation"
                        value={categoryTranslation}
                        onChange={(e: any) =>
                          setCategoryTranslation(e.target.value)
                        }
                      />
                    </div>

                    <div className="p-3">
                      <Button
                        onClick={handleAddCategoryClick}
                        className="btn-primary-theme filter-btn"
                        variant="contained"
                        color="primary"
                        disabled={
                          !categoryPrefix || !categoryTranslation || !category
                        }
                      >
                        {t("Add category")}
                      </Button>
                    </div>
                    <div className="w-100 d-flex flex-wrap">
                      {categories.map((item: any, index) => {
                        return (
                          <div key={index} className="m-3">
                            <Chip
                              // icon={icon}
                              label={`${item.name} - ${item.prefix} - ${item.translation}`}
                              onDelete={handleCategoryDelete.bind(
                                null,
                                item.name
                              )}
                              // className={classes.chip}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* /////////// relationships//////////// */}
                  <div className="relationships d-flex flex-wrap">
                    <div className="p-3">
                      <TextField
                        className="w-100"
                        label={t("relationship")}
                        variant="filled"
                        name="relationship"
                        value={relationship}
                        onChange={(e: any) =>
                          setRelationship(capitalize(e.target.value))
                        }
                      />
                    </div>

                    <div className=" p-3">
                      <TextField
                        className="w-100"
                        label={t("relationship translation")}
                        variant="filled"
                        name="relationship translation"
                        value={relationshipTranslation}
                        onChange={(e: any) =>
                          setRelationshipTranslation(capitalize(e.target.value))
                        }
                      />
                    </div>

                    <div className="p-3">
                      <Button
                        onClick={handleAddRelationshipClick}
                        className="btn-primary-theme filter-btn"
                        variant="contained"
                        color="primary"
                        disabled={!relationship || !relationshipTranslation}
                      >
                        {t("Add relationship")}
                      </Button>
                    </div>
                    <div className="w-100 d-flex flex-wrap">
                      {relationships.map((item: any, index) => {
                        return (
                          <div key={index} className="m-3">
                            <Chip
                              // icon={icon}
                              label={`${item.name} - ${item.translation}`}
                              onDelete={handleRelationshipDelete.bind(
                                null,
                                item.name
                              )}
                              // className={classes.chip}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* ///////////// resolutions //////////// */}
                  <div className="resolutions d-flex flex-wrap">
                    <div className="p-3">
                      <TextField
                        className="w-100"
                        label={t("Resolution")}
                        variant="filled"
                        name="Resolution"
                        value={resolution}
                        onChange={(e: any) =>
                          setResolution(capitalize(e.target.value))
                        }
                      />
                    </div>

                    <div className=" p-3">
                      <TextField
                        className="w-100"
                        label={t("Resolution translation")}
                        variant="filled"
                        name="Resolution"
                        value={resolutionTranslation}
                        onChange={(e: any) =>
                          setResolutionTranslation(capitalize(e.target.value))
                        }
                      />
                    </div>

                    <div className="p-3">
                      <Button
                        onClick={handleAddResolutionClick}
                        className="btn-primary-theme filter-btn"
                        variant="contained"
                        color="primary"
                        disabled={!resolution || !resolutionTranslation}
                      >
                        {t("Add resolution")}
                      </Button>
                    </div>
                    <div className="w-100 d-flex flex-wrap">
                      {resolutions.map((item: any, index) => {
                        return (
                          <div key={index} className="m-3">
                            <Chip
                              // icon={icon}
                              label={`${item.name} - ${item.translation}`}
                              onDelete={handleResolutionDelete.bind(
                                null,
                                item.name
                              )}
                              // className={classes.chip}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="d-flex justify-content-end p-4">
        <Button
          onClick={submit}
          style={{ width: "240px" }}
          className="btn-primary-theme filter-btn"
          variant="contained"
          color="primary"
          disabled={
            categories.length === 0 ||
            resolutions.length === 0 ||
            relationships.length === 0 ||
            lands.length === 0 ||
            branches.length === 0 ||
            !defaultPass ||
            !workspaceName ||
            Object.values(contactInfo).some((value) => value === "") ||
            saving
          }
        >
          {saving ? (
            <CircularProgress color="inherit" />
          ) : (
            <>{t("Add workspace")}</>
          )}
        </Button>
      </div>
    </div>
  );
};

export default CreateWorkspaceContent;
