export class LocalStorageService {
  public getData(key: string) {
    const data = localStorage.getItem("PAIPER-" + key);
    return data ? JSON.parse(data) : "";
  }
  public setData(key: string, data: any) {
    localStorage.setItem("PAIPER-" + key, JSON.stringify(data));
  }
  public removeData(key: string) {
    localStorage.removeItem("PAIPER-" + key);
  }
  public clearAll() {
    localStorage.clear();
  }
}
