import React from "react";
import "./create-case-sidemenu.component.scoped.scss";
import { SideMenuItem, sideMenuItems } from "../../../types/sidemenu-items";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const CreateCaseSidemenuComponent = (props: any) => {
  const [t] = useTranslation();
  const { activeTab, queryParams } = props;

  return (
    <Box className="content">
      <Box className="logo">
        <img
          src="/icons/logos/pAIper.png"
          alt="pAIper"
          title="pAIper"
          className="w-100"
        />
      </Box>
      <Box className="items mt-5">
        {sideMenuItems.map((item: SideMenuItem, index: number) => (
          <React.Fragment key={index}>
            {item.permission.some((param) => queryParams.includes(param)) && (
              <Box
                className={`item ${item.tab <= activeTab ? "active" : ""}`}
                key={index}
              >
                <Box className="circle">
                  {item.tab <= activeTab && (
                    <img
                      src={
                        item.tab === activeTab
                          ? item.iconCompleted
                          : item.iconActive
                      }
                      alt=""
                    />
                  )}
                </Box>
                <span className="title">{t(item.name)}</span>
              </Box>
            )}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};
export default CreateCaseSidemenuComponent;
