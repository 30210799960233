import React from "react";
import { CommonService } from "../../services/common/common.service";
import { useTranslation } from "react-i18next";
import HeaderComponent from "../../components/header/header.component";
import { UsersProvider } from "../../store";
import UsersContent from "../../modules/users/components/users-content/users-content.component";

export default function UsersPage() {
  const commonService = new CommonService();
  const [t] = useTranslation();
  commonService.setTitle(t("users"));
  return (
    <UsersProvider>
      <HeaderComponent />
      <UsersContent />
    </UsersProvider>
  );
}
