import { AlgorithmsIconOptions } from "../../../statics/algorithmsIconOptions";
import { AreasIconOptions } from "../../../statics/areasIconOptions";
import { CategoriesIconOptions } from "../../../statics/categoriesIconOptions";
import { OperationsIconOptions } from "../../../statics/operationsIconOptions";
import { IconOption } from "../../../types/iconOption";

enum IconGroups {
  AREAS = "areas",
  ALGORITHMS = "algorithms",
  OPERATIONS = "operations",
  RISK_LEVELS = "risk-levels",
}
export interface IconOptions {
  name: string;
  group: IconGroups;
  icons: IconOption[];
}

export const iconOptions: IconOptions[] = [
  {
    name: "AI_AREAS",
    group: IconGroups.AREAS,
    icons: AreasIconOptions,
  },
  {
    name: "OPERATIONS_AREAS",
    group: IconGroups.OPERATIONS,
    icons: OperationsIconOptions,
  },
  {
    name: "AI_ALGORITHM",
    group: IconGroups.ALGORITHMS,
    icons: AlgorithmsIconOptions,
  },
  {
    name: "RISK_LEVELS",
    group: IconGroups.RISK_LEVELS,
    icons: CategoriesIconOptions,
  },
];
