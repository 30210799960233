import React, { useContext, useState } from "react";
import "./workflow-change-status.component.scoped.scss";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CaseContext, GlobalContext } from "../../store";
import { CasesService } from "../../services/cases/cases.service";
import { MapperService } from "../../services/mapper/mapper.service";

const WorkflowChangeStatus = (props: any) => {
  const [t] = useTranslation();
  const casesService = new CasesService();
  const mapperService = new MapperService();
  const {
    caseDetails,
    caseStatuses,
    setCase,
    setCaseQuestions,
    setWorkflowLoading,
    setCaseStatuses,
    completedStatusId,
  } = useContext(CaseContext);
  const { user } = useContext(GlobalContext);
  const [saving, setSaving] = useState(false);

  async function fetchCaseDetails(id: number) {
    setSaving(true);
    const caseDetailsData = await casesService.details(id);
    const { mappedCase, mappedtimelineStatuses } = mapperService.mapCase(
      caseDetailsData,
      caseStatuses
    );
    setCase(mappedCase);
    setCaseStatuses(mappedtimelineStatuses);
    setSaving(false);
  }

  async function fetchCaseQuestions(id: number) {
    setSaving(true);
    setWorkflowLoading(true);
    const caseQuestions = await casesService.caseQuestions(id);
    setTimeout(() => {
      setCaseQuestions(caseQuestions);
      setWorkflowLoading(false);
    }, 100);
    setSaving(false);
  }

  const updateCase = () => {
    setSaving(true);
    fetchCaseDetails(caseDetails.id);
    setTimeout(() => {
      fetchCaseQuestions(caseDetails.id);
    }, 100);
    setSaving(false);
  };

  const changeStatus = async (status: any) => {
    setSaving(true);
    await casesService.updateWorkflowStatus(props.caseId, {
      statusId: status.id,
    });
    updateCase();
    setSaving(false);
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="status-change">
          <div className="text mr-4">{t("change of status")}</div>
          {props.prevStatus && (
            <Button
              className="btn-primary-theme"
              variant="contained"
              color="primary"
              size="medium"
              onClick={changeStatus.bind(null, props.prevStatus)}
              disabled={
                saving ||
                caseDetails.statusId === completedStatusId ||
                user.role === "auditor"
              }
            >
              <img src={props.prevStatus.icon} className="mr-2" alt="" />{" "}
              {props.prevStatus.name}
            </Button>
          )}

          {props.prevStatus && props.nextStatus && (
            <div className="text font-weight-normal mx-4">{t("or")}</div>
          )}

          {props.nextStatus && (
            <Button
              className="btn-secondary-theme"
              variant="outlined"
              color="primary"
              size="medium"
              disabled={
                saving ||
                user.role === "auditor" ||
                caseDetails.statusId === completedStatusId
              }
              onClick={changeStatus.bind(null, props.nextStatus)}
            >
              <img
                src={
                  user.role === "auditor"
                    ? props.nextStatus.iconGrey
                    : props.nextStatus.iconOrange
                }
                className="mr-2"
                alt=""
              />{" "}
              {props.nextStatus.name}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkflowChangeStatus;
