import { IconOption } from "../types/iconOption";
import { icons } from "./icons";

export const CategoriesIconOptions: IconOption[] = [
  {
    key: "HIGH_RISK",
    name: "high-risk",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.HIGH_RISK,
  },
  {
    key: "MIDDLE_RISK",
    name: "middle-risk",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.MIDDLE_RISK,
  },
  {
    key: "NO_RISK",
    name: "no-risk",
    path: icons.PAGES.SETTINGS.ICON_OPTIONS.NO_RISK,
  },
];
