export const defaultQuestions: any[] = [
  {
    STATUS: "PLANNING",
    TEXT: "1.1 Ist die Meldung plausibel?",
    ANSWER: "",
  },
  {
    STATUS: "PLANNING",
    TEXT: "1.2 Sind ausreichend Informationen für eine Bearbeitung vorhanden?",
    ANSWER: "",
  },
  {
    STATUS: "CONCEPT",
    TEXT:
      "2.1 Gab es in der Vergangenheit bereits Hinweise, die im Zusammenhang mit dieser Meldung stehen könnten?",
    ANSWER: "",
  },
  {
    STATUS: "CONCEPT",
    TEXT:
      "2.2 Sind weitere Informationen vom/von der Hinweisgeber:in notwendig?",
    ANSWER: "",
  },
  {
    STATUS: "ACQUISITION",
    TEXT:
      "3.1 Gab es in der Vergangenheit bereits Hinweise, die im Zusammenhang mit dieser Meldung stehen könnten?",
    ANSWER: "",
  },
  {
    STATUS: "ACQUISITION",
    TEXT:
      "3.2 Sind weitere Informationen vom/von der Hinweisgeber:in notwendig?",
    ANSWER: "",
  },
  {
    STATUS: "DEVELOPMENT",
    TEXT:
      "4.1 Sind weitere Informationen aus der/den betroffenen Abteilung/en notwendig?",
    ANSWER: "",
  },
  {
    STATUS: "DEVELOPMENT",
    TEXT:
      "4.2 Hat sich der Verdachtsfall durch die Sichtung weiterer, direkt verfügbarer Informationen und Dokumente erhärtet?",
    ANSWER: "",
  },
  {
    STATUS: "TRAINING",
    TEXT:
      "5.1 Sind weitere Informationen von anderen internen oder externen Abteilungen/Personen notwendig?",
    ANSWER: "",
  },
  {
    STATUS: "TRAINING",
    TEXT: "5.2 Ist der Betriebsrat zu informieren?",
    ANSWER: "",
  },
  {
    STATUS: "VALIDATION",
    TEXT: "6.1 Ist ein externer Rechtsanwalt einzubinden?",
    ANSWER: "",
  },
  {
    STATUS: "VALIDATION",
    TEXT:
      "6.2 Wurden alle Interviews mit dem/der Hinweisgeber:in und allen relevanten Personen geführt und dokumentiert?",
    ANSWER: "",
  },
  {
    STATUS: "TESTING",
    TEXT: "7.1 Sind alle Untersuchungsschritte und -ergebnisse dokumentiert?",
    ANSWER: "",
  },
  {
    STATUS: "TESTING",
    TEXT:
      "7.2 Konnten der Vorgang bzw. die Ursache des Vorfalls aufgeklärt werden?",
    ANSWER: "",
  },
  {
    STATUS: "DEPLOYMENT",
    TEXT:
      "8.1 Wurden Maßnahmen, die das Risiko von Gesetzesverletzungen minimieren, erkannt und umgesetzt?",
    ANSWER: "",
  },
  {
    STATUS: "DEPLOYMENT",
    TEXT:
      "8.2 Wurden weitere zuständige Stellen über den Vorgang unter Wahrung der Vertraulichkeit unterrichtet?",
    ANSWER: "",
  },
  {
    STATUS: "RELEASE",
    TEXT:
      "9.1 Wurde das zuständige Organ über den Vorgang im Sinne der einschlägigen Vorschriften unterrichtet?",
    ANSWER: "",
  },
  {
    STATUS: "RELEASE",
    TEXT: "9.2 Wurde eine Behörde über den Vorfall informiert?",
    ANSWER: "",
  },
  {
    STATUS: "MONITORING",
    TEXT: "10.1 Wird eine Klage über einen Rechtsanwalt eingereicht?",
    ANSWER: "",
  },
  {
    STATUS: "MONITORING",
    TEXT: "10.2 Werden Maßnahmen im Unternehmen umgesetzt?",
    ANSWER: "",
  },
  {
    STATUS: "MAINTENANCE",
    TEXT:
      "11.1 Wurden alle erhaltenen Informationen berücksichtigt und strukturiert im digitalen Akt abgelegt?",
    ANSWER: "",
  },
  {
    STATUS: "MAINTENANCE",
    TEXT:
      "11.2 Wurde der Vorgang ausreichend beschrieben und kann nun revisionssicher dokumentiert werden?",
    ANSWER: "",
  },
  {
    STATUS: "RETIREMENT",
    TEXT:
      "12.1 Wurde die Abschlussinformation an den/die Hinweisgeber:in formuliert und abgestimmt, sodass sie übersendet werden kann?",
    ANSWER: "",
  },
  {
    STATUS: "RETIREMENT",
    TEXT:
      "12.2 Wurden die vergebenen Berechtigungen in der Vorgangsbearbeitung einschränkt?",
    ANSWER: "",
  },
  {
    STATUS: "REDEFINITION",
    TEXT: "13.1 Wird eine Klage über einen Rechtsanwalt eingereicht?",
    ANSWER: "",
  },
  {
    STATUS: "REDEFINITION",
    TEXT: "13.2 Werden Maßnahmen im Unternehmen umgesetzt?",
    ANSWER: "",
  },
];
