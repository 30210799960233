import React, { useEffect, useRef, useState } from "react";
import "./wokflow-table.component.scoped.scss";
import { useTranslation } from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import { Box, InputAdornment, TextField } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { CasesService } from "../../../../../../../services/cases/cases.service";
import { isValidLength } from "../../../../../../../helpers";
import { Question } from "../../../../../../../types/question";
import WorkflowTableRow from "../workflow-table-row/workflow-table-row.component";

const WorkflowTable = (props: any) => {
  const {
    loading,
    statusId,
    caseQuestionId,
    files,
    selectedStatus,
    caseDetails,
    user,
  } = props;
  const [t] = useTranslation();
  const wrapperRef = useRef<HTMLInputElement>(null);
  const caseServices = new CasesService();
  const maxChar = 200;
  const [questions, setQuestions] = useState(props.questions);
  const [questionInput, setQuestionInput] = useState(false);
  const [question, setQuestion] = useState("");

  const handleFocus = (input: any) => {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 1);
    }
  };

  const submitQuestion = async () => {
    if (!isValidLength(question, maxChar)) return;
    const res = await caseServices.addQuestion({
      question,
      statusId,
      caseId: caseDetails.id,
    });

    if (res.message === "Success") {
      const questionsCopy = [...questions];
      questionsCopy.push(res.data);
      setQuestions(questionsCopy);
      setQuestionInput(false);
      setQuestion("");
      props.onChangeQuestion(questionsCopy);
    }
  };

  const deleteQuestion = async (index: number) => {
    const questionsCopy = [...questions];
    questionsCopy.splice(index, 1);
    setQuestions(questionsCopy);
    props.onChangeQuestion(questionsCopy);
  };

  useEffect(() => {
    // hide input if clicked on outside of input
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setQuestionInput(false);
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    setQuestions(props.questions);
  }, [props.questions]);

  return (
    <Box className="workflow-table-card">
      <Box className="questions">
        {questions.map((question: Question, index: any) => {
          return (
            <WorkflowTableRow
              key={index}
              id={question.id}
              questionId={question.questionId}
              index={index + 1}
              question={question.question}
              files={question.files}
              updatedAt={question.updated_at}
              answer={question.answer}
              brief={question.brief}
              comments={question.comments}
              todoList={question.todoList}
              loading={loading}
              caseQuestionId={caseQuestionId}
              caseFiles={files}
              custom={question.custom}
              user={user}
              onUpdateCaseWorkflow={() => props.onUpdateCase()}
              onDeleteQuestion={() => deleteQuestion(index)}
            />
          );
        })}
      </Box>
      {!loading &&
        selectedStatus &&
        (user.keycloakUserId === caseDetails.mainProcessorId ||
          user.type === "superAdmin" ||
          user.role === "coordinator") && (
          <Box className="custom-question">
            {!questionInput ? (
              <Box
                className="d-flex align-items-center"
                onClick={() => setQuestionInput(true)}
                style={{ cursor: "pointer" }}
              >
                <AddIcon style={{ marginRight: "3px", fontSize: "1.2rem" }} />
                <span>{t("CREATE_QUESTION")}</span>
              </Box>
            ) : (
              <div className="col" ref={wrapperRef}>
                <TextField
                  id="timeline-add-comment"
                  placeholder={t("ADD_NEW_QUESTION_TO_CURRENT_STATUS")}
                  variant="filled"
                  fullWidth
                  label={t("QUESTION")}
                  onChange={(e: any) => setQuestion(e.target.value)}
                  value={question}
                  name="question"
                  inputRef={handleFocus}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SendIcon
                          className={
                            isValidLength(question, maxChar)
                              ? "svg-active"
                              : "svg-grey"
                          }
                          onClick={submitQuestion}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          </Box>
        )}
    </Box>
  );
};

export default WorkflowTable;
