import React, { useState } from "react";
import "./question-brief.component.scoped.scss";
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import CheckIcon from "@material-ui/icons/Check";
import { Box, TextField } from "@material-ui/core";
import { CasesService } from "../../../../services/cases/cases.service";
import { showToastMsg } from "../../../../helpers";

export const QuestionBriefDialog: React.FunctionComponent<{
  show: boolean;
  id: string;
  answer: number;
  brief: string;
  onClose: (tab: boolean) => void;
}> = ({ show, id, answer, brief, onClose }) => {
  const [t] = useTranslation();
  const casesService = new CasesService();
  const [saving, setSaving] = useState(false);

  const [newBrief, setNewBrief] = useState(brief || "");

  const updateAnswer = async () => {
    setSaving(true);
    const res = await casesService.answerQuestion(id, {
      answer,
      brief: newBrief,
    });
    if (res.message === "Answered") {
      showToastMsg(
        "success",
        t("USECASE_DETAILS.QUESTION_ANSWERED_SUCCESSFULLY")
      );
      onClose(true);
    } else {
      showToastMsg("error", t("SOME_THING_WENT_WRONG"));
      onClose(false);
    }
    setSaving(false);
  };

  return (
    <Modal
      show={show}
      onHide={onClose.bind(null, false)}
      centered
      dialogClassName={"modal-lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t("USECASE_DETAILS.BRIEF_THE_ANSWER_OF_THE_QUESTION")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box className="mx-3">
          <TextField
            id="filled-multiline-flexible"
            value={newBrief}
            label={t("BRIEF")}
            onChange={(e: any) =>
              setNewBrief(e.target.value.replace(/^\s+/g, ""))
            }
            variant="filled"
            className="text-area-multiline w-100"
            fullWidth={true}
            rows={5}
            multiline
            inputProps={{
              maxLength: 250,
            }}
          />
          <Box className="hint d-flex justify-content-between align-items-center">
            <span>{t("USECASE_DETAILS.BRIEF_QUESTION_ANSWER_HINT")}</span>
            <span>
              {newBrief.length}/{250} {t("character")}
            </span>
          </Box>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outlined"
          color="primary"
          className="btn-secondary-theme"
          onClick={onClose.bind(null, false)}
          disabled={saving}
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={updateAnswer}
          className="btn-primary-theme"
          variant="contained"
          color="primary"
          disabled={saving}
        >
          <CheckIcon /> {t("ANSWER")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
