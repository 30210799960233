import { SideNavItem } from "../types/sidenav-item";

import { icons } from "./icons";

export const sideNavItems: SideNavItem[] = [
  {
    path: "/landscape",
    title: "landscape",
    name: "SIDE_MENU.AI_RISK_LANDSCAPE",
    icon: icons.PAGES.SIDE_NAVE.DashboardLight,
    iconHover: icons.PAGES.SIDE_NAVE.Dashboard,
    roles: ["superAdmin", "editor", "coordinator", "auditor", "contributor"],
  },
  {
    path: "/ai-products",
    title: "AI-Products",
    name: "SIDE_MENU.AI_PRODUCTS",
    icon: icons.PAGES.SIDE_NAVE.AIProductsLight,
    iconHover: icons.PAGES.SIDE_NAVE.AIProducts,
    roles: ["superAdmin", "editor", "coordinator", "auditor"],
  },
  {
    path: "/ai-inventory",
    title: "AI-Inventory",
    name: "SIDE_MENU.AI_INVENTORY",
    icon: icons.PAGES.SIDE_NAVE.AIInventoryLight,
    iconHover: icons.PAGES.SIDE_NAVE.AIInventory,
    roles: ["superAdmin", "editor", "coordinator", "auditor"],
  },
  {
    path: "/ai-demand",
    title: "AI-Demand",
    name: "SIDE_MENU.AI_DEMAND_MANAGER",
    icon: icons.PAGES.SIDE_NAVE.AIDemandLight,
    iconHover: icons.PAGES.SIDE_NAVE.AIDemand,
    roles: ["superAdmin", "editor", "coordinator", "auditor", "contributor"],
  },
  {
    path: "/ai-compliance-tracking",
    title: "AI-Compliance-Tracking",
    name: "SIDE_MENU.AI_COMPLIANCE_TRACKING",
    icon: icons.PAGES.SIDE_NAVE.SupportLight,
    iconHover: icons.PAGES.SIDE_NAVE.Support,
    roles: ["superAdmin", "editor", "coordinator", "auditor"],
  },
  {
    path: "/ai-risk-assessment",
    title: "AI-Risk-Assessment",
    name: "SIDE_MENU.AI_RISK_ASSESSMENT",
    icon: icons.PAGES.SIDE_NAVE.WarningLight,
    iconHover: icons.PAGES.SIDE_NAVE.Warning,
    roles: ["superAdmin", "editor", "coordinator", "auditor"],
  },
  {
    path: "/ai-tasks",
    title: "AI-Tasks",
    name: "SIDE_MENU.AI_TASK",
    icon: icons.PAGES.SIDE_NAVE.TodoLight,
    iconHover: icons.PAGES.SIDE_NAVE.Todo,
    roles: ["superAdmin", "editor", "coordinator", "contributor"],
  },
  // {
  //   name: "calender",
  //   title: "calender",
  //   path: "/calender",
  //   icon: icons.calenderLight,
  //   iconHover: icons.calender,
  //   permissions: ["superAdmin", "user", "superUser", "auditor"],
  // },
];
