import React, { useEffect, useRef, useState } from "react";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import CloseIcon from "@material-ui/icons/Close";
import Rotate90DegreesCcwIcon from "@material-ui/icons/Rotate90DegreesCcw";
import { Box, IconButton, styled } from "@material-ui/core";
import Cropper from "react-cropper";
import ArrowBackRounded from "@material-ui/icons/ArrowBackRounded";
import ArrowForwardRounded from "@material-ui/icons/ArrowForwardRounded";
import ArrowDownwardRounded from "@material-ui/icons/ArrowDownwardRounded";
import ArrowUpwardRounded from "@material-ui/icons/ArrowUpwardRounded";

import "cropperjs/dist/cropper.css";

interface CustomHTMLImageElement extends HTMLImageElement {
  cropper: Cropper;
}

interface ImageCropProps {
  image: string | ArrayBuffer | null | undefined;
  imageType?: string;
  onImageCrop: (url: string) => void;
  crop: boolean;
  imageCropCanvasData?: any;
  isSaving: boolean;
  cropShape?: "rectangle" | "circle";
}

const IconBtn = styled(IconButton)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  "&:hover": {
    background: theme.palette.primary.main,
  },
  "&.Mui-disabled, &[disabled]": {
    opacity: 0.4,
    background: theme.palette.primary.main,
    color: "#fff",
  },
  margin: "5px",
}));

export const CropImage: React.FunctionComponent<ImageCropProps> = (
  props: ImageCropProps
) => {
  const {
    image,
    onImageCrop,
    isSaving,
    imageType,
    cropShape = "circle",
  } = props;
  const cropperRef = useRef<HTMLImageElement>(null);
  const [cropper, setCropper] = useState<Cropper>();

  const getCropData = () => {
    const imageElement = cropperRef.current as CustomHTMLImageElement;
    const cropperElement: Cropper = imageElement.cropper;
    let croppedCanvas = cropperElement.getCroppedCanvas({
      imageSmoothingEnabled: true,
      imageSmoothingQuality: "high",
      maxWidth: 600,
      maxHeight: 600,
    });

    if (cropShape !== "rectangle") {
      croppedCanvas = cropperElement.getCroppedCanvas({
        fillColor: "#fff",
      });
    }

    const url =
      croppedCanvas &&
      croppedCanvas.toDataURL(imageType ? imageType : undefined);
    onImageCrop(url);
  };

  useEffect(() => {
    if (props.crop) {
      getCropData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.crop]);

  const zoomIn = () => {
    if (cropper) {
      cropper.zoom(0.1);
    }
  };

  const zoomOut = () => {
    if (cropper) {
      cropper.zoom(-0.1);
    }
  };

  const moveRight = () => {
    if (cropper) {
      cropper.move(10, 0);
    }
  };

  const moveLeft = () => {
    if (cropper) {
      cropper.move(-10, 0);
    }
  };

  const moveUp = () => {
    if (cropper) {
      cropper.move(0, -10);
    }
  };

  const moveDown = () => {
    if (cropper) {
      cropper.move(0, 10);
    }
  };

  const rotate = () => {
    if (cropper) {
      cropper.rotate(-90);
    }
  };

  const reset = () => {
    if (cropper) {
      cropper.reset();
    }
  };

  return (
    <>
      {image && (
        <Cropper
          src={image as string}
          style={{ height: "320px", width: "100%" }}
          aspectRatio={cropShape === "rectangle" ? 24 / 7 : 1}
          cropBoxResizable={true}
          guides={false}
          ref={cropperRef}
          dragMode="crop"
          viewMode={0}
          autoCrop={true}
          checkOrientation={false}
          minCropBoxHeight={cropShape === "rectangle" ? 100 : 200}
          disabled={isSaving}
          onInitialized={(instance: any) => {
            setCropper(instance);
          }}
        />
      )}
      <Box className="d-flex  justify-content-center align-items-center mt-4">
        <IconBtn onClick={rotate} disabled={!image || isSaving}>
          <Rotate90DegreesCcwIcon />
        </IconBtn>
        <IconBtn onClick={zoomIn} disabled={!image || isSaving}>
          <ZoomInIcon />
        </IconBtn>
        <IconBtn onClick={zoomOut} disabled={!image || isSaving}>
          <ZoomOutIcon />
        </IconBtn>
        <IconBtn onClick={reset} disabled={!image || isSaving}>
          <CloseIcon />
        </IconBtn>
      </Box>
      <Box className="d-flex justify-content-center align-items-center">
        <IconBtn onClick={moveDown} disabled={!image || isSaving}>
          <ArrowDownwardRounded />
        </IconBtn>
        <IconBtn onClick={moveRight} disabled={!image || isSaving}>
          <ArrowForwardRounded />
        </IconBtn>
        <IconBtn onClick={moveUp} disabled={!image || isSaving}>
          <ArrowUpwardRounded />
        </IconBtn>
        <IconBtn onClick={moveLeft} disabled={!image || isSaving}>
          <ArrowBackRounded />
        </IconBtn>
      </Box>
    </>
  );
};

export default CropImage;
