export const userTitlesValue: any[] = [
  "BA",
  "BEng",
  "BSc",
  "DI",
  "Ing.",
  "Dipl.-Ing.",
  "Mag. med. vet.",
  "Dr. med. univ.",
  "Dr. med. dent.",
  "Mag. rer. nat.",
  "Mag. phil.",
  "Mag. iur.",
  "M.A.I.S",
  "MA",
  "LLM",
  "MSc",
  "PhD",
  "Dr. nat. techn.",
  "Dr. scient. med.",
  "Dr. mont.",
  "Dr. rer. nat.",
  "Dr. phil.",
  "Dr. iur.",
  "Dr. rer. soc. oec.",
  "Dr. theol.",
  "M.E.S",
  "MAS",
  "MBA",
  "M.B.L.",
  "MIB",
  "MP",
];
