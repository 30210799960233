import { useState, useEffect, useContext } from "react";
import { CasesContext } from "../providers/cases.provider";
import { CasesService } from "../../services/cases/cases.service";
import { MapperService } from "../../services/mapper/mapper.service";
import { PAGE_ROLES } from "../../modules/case/types/AI-roles";

const useFetchCases = (role: PAGE_ROLES, params: Record<string, any>) => {
  const casesService = new CasesService();
  const mapperService = new MapperService();
  const { setPagination } = useContext(CasesContext);

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const emptyArr: any = [];

  /**
   * Fetch USECASES
   */
  const fetchData = async () => {
    try {
      setLoading(true);
      setData(emptyArr);
      // const params = new URLSearchParams(queryParams).toString();
      const queryParams = {
        ...params,
        role,
      };
      const casesDetails = await casesService.list(queryParams);
      const { readyCases, paginationObj } = mapperService.mapCases(
        casesDetails
      );
      setData(readyCases);
      setPagination(paginationObj);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [params]);

  return { loading, data };
};

export default useFetchCases;
