import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { icons } from "../../../../../statics/icons";
import { history } from "react-router-guard";
import { PAGE_ROLES } from "../../../types/AI-roles";

const CasesHeadComponent: React.FunctionComponent<{
  title: string;
  subTitle: string;
  logo: string;
  role: PAGE_ROLES;
}> = ({ title, subTitle, logo, role }) => {
  const [t] = useTranslation();

  const createUseCase = () => {
    history.push(`/create-case?role=${role}`);
  };

  return (
    <Box className="theme-container">
      <Box className="d-flex justify-content-between align-items-center mb-2">
        <Box className="title">
          <Typography variant="h1" color="primary">
            {title}
          </Typography>
          <Typography variant="subtitle2">{subTitle}</Typography>
        </Box>
        <img src={logo} alt={title} title={title} width={100} />
      </Box>

      <Box className="action text-center my-5">
        <Button
          className="btn-primary-theme mr-3"
          variant="contained"
          size="large"
          color="primary"
          onClick={createUseCase}
        >
          <img
            src={
              role === "PRODUCTS"
                ? icons.PAGES.CASES.AIProductsWhite
                : icons.PAGES.CASES.AIInventoryWhite
            }
            alt={title}
            title={title}
            className="mr-2"
          />
          {role === "PRODUCTS"
            ? t("PAGES_HEAD.AI_PRODUCT_BUTTON")
            : role === "INVENTORY"
            ? t("PAGES_HEAD.AI_INVENTORY_BUTTON")
            : t("PAGES_HEAD.AI_DEMAND_BUTTON")}
        </Button>
      </Box>
    </Box>
  );
};

export default CasesHeadComponent;
