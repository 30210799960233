import "./edit-noticeInfo.scoped.scss";
import React, { useState, useContext, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import { CasesService } from "../../services/cases/cases.service";
import { CaseContext } from "../../store";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";
import { icons } from "../../statics/icons";
import "moment/locale/de";
import InfoQuestion from "../../components/info-question/Info-question.component";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import LastUpdatedAt from "../../components/last-updated-at/last-updated.component";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MapperService } from "../../services/mapper/mapper.service";
import { LangService } from "../../services/lang/lang.service";
import { InputViewComponent } from "../../components/input-view/input-view.component";

const EditNoticeInfo = (props: any) => {
  const [t] = useTranslation();
  const casesService = new CasesService();
  const langService: LangService = new LangService();
  const mapperService = new MapperService();
  const locale = langService.getCurrentLang();
  moment.locale(locale);

  const {
    caseDetails,
    setLoading,
    setCase,
    caseStatuses,
    lands,
    branches,
    caseCategories,
  } = useContext(CaseContext);

  const [saving, setSaving] = useState(false);
  const [categoryChanged, setCategoryChanged] = useState(false);
  const [branchChanged, setBranchChanged] = useState(false);
  const [landChanged, setLandChanged] = useState(false);
  const [formData, setFormData] = useState({
    landId: caseDetails.landId,
    branchId: caseDetails.branchId,
    categoryId: caseDetails.categoryId,
    explanation: caseDetails.categoryExplanation,
    changeChecklist: false,
  });

  const {
    landId,
    branchId,
    categoryId,
    explanation,
    changeChecklist,
  } = formData;

  const formatRequestBody = (...args: any): any => {
    const body: any = {};
    for (let index = 0; index < args.length; index++) {
      const item = args[index];
      if (item.value !== caseDetails[item.name]) {
        body[item.name] = item.value;
      }
      if (item.name === "changeChecklist" && categoryChanged) {
        body[item.name] = item.value;
      }
    }
    return body;
  };

  const handleUpdate = async () => {
    setSaving(true);
    const bodyObject = formatRequestBody(
      { name: "landId", value: landId },
      { name: "branchId", value: branchId },
      { name: "categoryId", value: categoryId },
      { name: "categoryExplanation", value: explanation },
      { name: "changeChecklist", value: formData.changeChecklist }
    );
    const data = { ...bodyObject };
    await casesService.updateNoticeInfo(caseDetails.id, data);
    setSaving(false);

    fetchCaseDetails(caseDetails.id);
    handleClose(null);
  };

  const onChangeHandler = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.name === "changeChecklist"
          ? e.target.value === "true"
          : e.target.value,
    });
  };

  const handleClose = (value: any) => {
    props.onClose(value);
    if (!value) {
      setFormData({
        landId: caseDetails.landId,
        branchId: caseDetails.branchId,
        categoryId: caseDetails.categoryId,
        explanation: caseDetails.categoryExplanation,
        changeChecklist: false,
      });
    }
  };

  const fetchCaseDetails = async (id: number) => {
    setLoading(true);
    const caseDetailsData = await casesService.details(id);
    const { mappedCase } = mapperService.mapCase(caseDetailsData, caseStatuses);
    setCase(mappedCase);
    setLoading(false);
  };

  useEffect(() => {
    setFormData({
      landId: caseDetails.landId,
      branchId: caseDetails.branchId,
      categoryId: caseDetails.categoryId,
      explanation: caseDetails.categoryExplanation,
      changeChecklist: false,
    });
  }, [caseDetails]);

  return (
    <Modal
      show={props.show}
      onHide={handleClose.bind(null, false)}
      centered
      dialogClassName="modal-xl"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Box className="title">
            <Trans
              i18nKey="edit_case_notice_info"
              values={{ internalId: caseDetails.title }}
            ></Trans>
            {categoryChanged && (
              <Box className="hint">
                <img src={icons.warning} alt="" />
                <p>{t("CHANGE_CATEGORY_HINT")}</p>
              </Box>
            )}
          </Box>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box className="change-notice-dialog">
          <Box className="row">
            <Box className="col" />
            <Box className="col main-text mb-2">{t("original input")}</Box>
            <Box className="col main-text mb-2">{t("current input")}</Box>
          </Box>

          {/* Lands section */}
          <Box className="row mb-3">
            <InfoQuestion
              icon={icons.countryPrimary}
              label={t("country")}
              question={t("In which country did the incident take place?")}
            />

            <Box className="col">
              <Box className="mb-2">
                <InputViewComponent
                  value={caseDetails.originalLand}
                  label={t("country")}
                  icon={icons.chevronDown}
                  type="select"
                />
              </Box>
            </Box>
            <Box className="col">
              <FormControl className="select-input select-grey w-100">
                <InputLabel id="land">{t("country")}</InputLabel>
                <Select
                  labelId="land"
                  id="land-select"
                  name="landId"
                  value={landId || ""}
                  onChange={(e: any) => {
                    onChangeHandler(e);
                    setLandChanged(
                      e.target.value === caseDetails.landId ? false : true
                    );
                  }}
                  IconComponent={ExpandMoreIcon}
                >
                  {lands.map((item: any, index: any) => {
                    return (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <LastUpdatedAt
                mainProcessor={caseDetails.landUpdateAdmin}
                updatedAt={caseDetails.landUpdatedAt}
              />
            </Box>
          </Box>

          {/* branch section */}
          <Box className="row mb-3">
            <InfoQuestion
              icon={icons.companyPrimary}
              label={t("affected company")}
              question={t("what is the affected company?")}
            />

            <Box className="col">
              <Box className="mb-2">
                <InputViewComponent
                  value={caseDetails.originalBranch}
                  label={t("affected company")}
                  icon={icons.chevronDown}
                  type="select"
                />
              </Box>
            </Box>
            <Box className="col">
              <FormControl className="select-input select-grey w-100">
                <InputLabel id="branch">{t("affected company")}</InputLabel>
                <Select
                  labelId="branch"
                  id="branch-select"
                  name="branchId"
                  value={branchId || ""}
                  onChange={(e: any) => {
                    onChangeHandler(e);
                    setBranchChanged(
                      e.target.value === caseDetails.branchId ? false : true
                    );
                  }}
                  IconComponent={ExpandMoreIcon}
                >
                  {branches.map((item: any, index: any) => {
                    return (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box>

          {/* category section */}
          <Box className="row mb-3 ">
            <InfoQuestion
              icon={icons.category}
              label={t("RISK_LEVEL")}
              question={t("WHICH_RISK_LEVEL_DOES_THE_CASE_BELONG_TO")}
            />

            <Box className="col">
              <Box className="mb-2">
                <InputViewComponent
                  value={caseDetails.originalCategory}
                  label={t("RISK_LEVEL")}
                  icon={icons.chevronDown}
                  type="select"
                />
              </Box>
            </Box>

            <Box className="col">
              <FormControl className="select-input select-grey w-100">
                <InputLabel id="category">{t("RISK_LEVEL")}</InputLabel>
                <Select
                  labelId="category"
                  id="category-select"
                  name="categoryId"
                  value={categoryId ? categoryId : null}
                  onChange={(e: any) => {
                    onChangeHandler(e);
                    setCategoryChanged(
                      e.target.value === caseDetails.categoryId ? false : true
                    );
                  }}
                  IconComponent={ExpandMoreIcon}
                >
                  {caseCategories.map((item: any, index: any) => {
                    if (item.value === null) {
                      return (
                        <MenuItem value={item.id} key={index}>
                          0
                        </MenuItem>
                      );
                    }
                    return (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <LastUpdatedAt
                mainProcessor={caseDetails.categoryUpdateAdmin}
                updatedAt={caseDetails.categoryUpdatedAt}
              />
            </Box>
          </Box>
          {(caseDetails.categoryExplanation || categoryChanged) && (
            <Box className="row mb-3">
              {categoryChanged && (
                <Box className="col-8 ml-auto mb-3">
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="changeChecklist"
                      name="changeChecklist"
                      value={changeChecklist}
                      onChange={onChangeHandler}
                    >
                      <FormControlLabel
                        className="mb-0"
                        value={false}
                        control={<Radio />}
                        label={t("KEEP_CHECKLIST_AND_OLD_DATA")}
                      />
                      <FormControlLabel
                        className="mb-0"
                        value={true}
                        control={<Radio />}
                        label={t("CHANGE_CHECKLIST_AND_DELETE_OLD_DATA")}
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              )}
              <Box className="col-8 ml-auto">
                <Typography
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "12px",
                    fontWeight: 500,
                    height: "16px",
                    letterSpacing: "0.5px",
                    textTransform: "uppercase",
                  }}
                >
                  {t("EXPLANATION")}
                </Typography>
                {categoryChanged ? (
                  <TextField
                    className="text-area-multiline w-100 mt-2"
                    label={t("WHY_DO_YOU_WANT_TO_CHANGE_THE_RISK_LEVEL")}
                    variant="filled"
                    name="explanation"
                    multiline
                    rows={5}
                    rowsMax={5}
                    value={explanation || ""}
                    onChange={onChangeHandler}
                  />
                ) : (
                  <Typography
                    style={{
                      color: "var(--secondary-text)",
                      fontSize: "13px",
                      fontWeight: "normal",
                      height: "18px",
                      letterSpacing: "0px",
                      margin: ".5rem",
                    }}
                  >
                    {caseDetails.categoryExplanation}
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outlined"
          color="primary"
          className="btn-secondary-theme"
          onClick={handleClose.bind(null, false)}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="btn-primary-theme"
          onClick={handleUpdate}
          disabled={
            !(
              landChanged ||
              branchChanged ||
              (categoryChanged && formData.explanation)
            )
          }
        >
          {saving ? (
            <CircularProgress color="inherit" />
          ) : (
            <>
              <CheckIcon /> {t("save")}{" "}
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditNoticeInfo;

// <TextField
//   className="text-area-multiline w-100"
//   label={t("optional case specific comment")}
//   variant="filled"
//   name="comment"
//   multiline
//   rows={5}
//   rowsMax={5}
//   value={comment}
//   disabled={user.role === "auditor"}
//   onChange={(event: any) => handleChange(event)}
// />;
