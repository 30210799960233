import { TableColumn } from "../../types/table-column";

export const usersColumns: TableColumn[] = [
  {
    type: "client",
    fieldName: "NAME",
    value: "name",
    secondaryValue: "group",
    width: 20,
  },
  {
    type: "email",
    fieldName: "EMAIL",
    value: "email",
    width: 24,
  },
  {
    type: "date",
    fieldName: "INVITED_ON",
    value: "created_at",
    width: 14,
  },
  {
    type: "date",
    fieldName: "APPROVED_ON",
    value: "accept_invitation_at",
    width: 14,
  },
  {
    type: "status",
    fieldName: "STATUS",
    value: "status",
    width: 14,
  },
  {
    type: "actions",
    fieldName: "",
    value: "users",
    width: 1,
  },
];
