import axios, { AxiosError, CancelToken } from "axios";
import { config } from "../../config";
import { Land } from "../../types/land";
import { Branch } from "../../types/branch";
import { AIArea } from "../../modules/case/types/AI-areas";
import { AIAlgorithm } from "../../modules/case/types/AI-algorithm";
import { AITechnique } from "../../modules/case/types/AI-techniques";
import { MachineLearningType } from "../../modules/case/types/machine-learning-types";

export class LookupsService {
  /*********** Statues ***********/
  public listStatuses = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "case-status");
      return response.data;
    } catch (err) {
      return [];
    }
  };

  /*********** Categories ***********/
  public listCategories = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "case-categories");
      return response.data;
    } catch (err) {
      return [];
    }
  };

  /*********** Branches ***********/
  public listBranches = async (): Promise<Branch[]> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "branches");
      return response.data;
    } catch (error) {
      return [];
    }
  };

  /*********** AI Areas ***********/
  public listAIAreas = async (): Promise<AIArea[]> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "areas");
      return response.data;
    } catch (error) {
      return [];
    }
  };

  /*********** AI Algorithms ***********/
  public listAIAlgorithms = async (): Promise<AIAlgorithm[]> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "algorithms");
      return response.data;
    } catch (error) {
      return [];
    }
  };

  /*********** Operation ***********/
  public listOperation = async (): Promise<AIAlgorithm[]> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "operations");
      return response.data;
    } catch (error) {
      return [];
    }
  };

  /*********** AI Techniques ***********/
  public listAITechniques = async (): Promise<AITechnique[]> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "techniques");
      return response.data;
    } catch (error) {
      return [];
    }
  };

  /*********** AI Machine Learning Types ***********/
  public listMachineLearningTypes = async (): Promise<
    MachineLearningType[]
  > => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "machine-learning");
      return response.data;
    } catch (error) {
      return [];
    }
  };

  /*********** Lands ***********/
  public listLands = async (): Promise<Land[]> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "lands");
      return response.data;
    } catch (err) {
      return [];
    }
  };

  /*********** Resolutions ***********/
  public listResolutions = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "cases/resolutions");
      return response.data;
    } catch (error) {
      return [];
    }
  };

  /*********** Relations ***********/
  public listRelations = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "cases/relations");
      return response.data;
    } catch (error) {
      return [];
    }
  };

  /*********** Amounts ***********/
  public listAmounts = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "cases/amounts");
      return response.data;
    } catch (error) {
      return [];
    }
  };

  /*********** Periods ***********/
  public listPeriods = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "case-periods");
      return response.data;
    } catch (err) {
      return [];
    }
  };

  /*********** Labels ***********/
  public listLabels = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "cases/labels");
      return response.data;
    } catch (error) {
      return [];
    }
  };

  public searchLabels = async (id: string, name: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      const params = { name };
      const response = await axios.get(
        serverUrl + "cases/" + id + "/search-labels",
        { params }
      );
      return response.data;
    } catch (error) {
      return [];
    }
  };

  /*********** Request Signed URL ***********/
  public requestSignedUrl = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "storage/get-signed-url",
        data
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public uploadToSignedURL = async (
    url: string,
    file: any,
    onUploadProgress?: (progressEvent: any) => void,
    cancelToken?: CancelToken
  ): Promise<number> => {
    try {
      await axios.put<number>(url, file, {
        headers: {
          "Content-Type": "application/octet-stream",
        },
        onUploadProgress,
        cancelToken,
        timeout: 3000 * 60,
      });
      return 1;
    } catch (error) {
      return (error as AxiosError).message === "cancel" ? -1 : 0;
    }
  };
}
