import { Box, Button } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { history } from "react-router-guard";
import { CreateUseCaseContext } from "../../../../../store";
import { CasesService } from "../../../../../services/cases/cases.service";
import { showToastMsg } from "../../../../../helpers";
import axios from "axios";
import { UserService } from "../../../../../services/user/user.service";

interface ActionsProps {
  activeTab: number;
  buttonStatus: boolean;
  onChangeTab: (tab: number) => void;
}

const CreateCaseActionsComponent = (props: ActionsProps) => {
  const { buttonStatus, activeTab, onChangeTab } = props;
  const [t] = useTranslation();
  const caseServices = new CasesService();
  const userService = new UserService();
  const { useCase, categories } = useContext(CreateUseCaseContext);
  const [saving, setSaving] = useState(false);

  const submit = async () => {
    setSaving(true);
    const data = {
      // link: useCase.link,
      algorithmId: useCase.algorithmId,
      operationId: useCase.operationId,
      categoryId:
        useCase.role === "USER" ? categories[0].id : useCase.categoryId,
      branchId: useCase.branchId,
      brief: useCase.brief,
      description: useCase.description,
      landId: useCase.landId,
      role: useCase.role,
      scenarioDescription: useCase.scenarioDescription,
      title: useCase.title,
      version: useCase.version,
      areasIds: useCase.areasIds,
      techniquesIds: useCase.techniquesIds,
      machineLearningIds: useCase.machineLearningIds,
      users: useCase.users,
      keyContactUserId: 0,
    };

    if (!history.location.search.includes("USER")) {
      const users = [];
      let keyContactUserEmail = "";
      for (let user of useCase.users) {
        const formattedUsers: any = {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          group: user.group,
          role: user.role,
          defPassChecked: true,
        };
        keyContactUserEmail =
          user.keyContact === 1 ? user.email : keyContactUserEmail;
        users.push(formattedUsers);
      }
      const res = await userService.addUser({ users });
      const uniqueUsers = res.users.filter(
        (obj: any, index: number, array: any) =>
          array.findIndex((item: any) => item.user.id === obj.user.id) === index
      );
      const addedUsers = uniqueUsers.map((item: any) => item.user);
      data.users = addedUsers;
      const keyContactUser = res.users.find(
        (item: any) => item.user.email === keyContactUserEmail
      );
      data.keyContactUserId = keyContactUser.user.id;
    }
    const result = await caseServices.create(data);
    if (useCase.files.length) {
      const { caseId, internalId } = result.data;
      await uploadFiles(caseId, internalId);
    }
    if (result.message === "Success") {
      showToastMsg("success", t("USECASE_CREATED_SUCCESSFULLY"));
      if (history.location.search.includes("PRODUCTS")) {
        history.push("/ai-products");
      } else if (history.location.search.includes("INVENTORY")) {
        history.push("/ai-inventory");
      } else if (history.location.search.includes("USER")) {
        history.push("/ai-demand");
      }
    } else {
      showToastMsg("error", t("SOME_THING_WENT_WRONG"));
    }
    setSaving(false);
  };

  const uploadFiles = async (caseId: number, internalId: string) => {
    if (!saving && useCase.files.length <= 10) {
      setSaving(true);
      if (useCase.files.length) {
        useCase.files.forEach((file: any, index: number) => {
          caseServices
            .requestSignedUrl(
              file.name,
              file.type.substring(file.type.lastIndexOf("/") + 1),
              "files",
              internalId
            )
            .then((response: any) => {
              const { signedRequest, url } = response;
              const cancelTokenSource = axios.CancelToken.source();
              useCase.files[index].cancelTokenSource = cancelTokenSource;
              useCase.files[index].url = url;
              caseServices
                .uploadToSignedURL(
                  signedRequest,
                  useCase.files[index],
                  cancelTokenSource.token,
                  (event: any) => {}
                )
                .then(async (uploadResponse: any) => {
                  if (uploadResponse === "canceled") {
                    return;
                  }

                  const type = file.type.substring(
                    file.type.lastIndexOf("/") + 1
                  );
                  const name = file.name.split("." + type)[0];
                  const size = `${(file.size / 1024 / 1000).toFixed(2)} MB`;
                  const payload = {
                    caseId,
                    name,
                    type,
                    size,
                    url: file.url,
                  };
                  // push uploaded files to save in communication or notes tabs
                  await caseServices.saveFileAndComment(payload, "", caseId);
                });
            });
        });
      }
    }
  };

  ////////////////////////////////////
  return (
    <Box className="d-flex justify-content-between">
      {activeTab > 0 && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => onChangeTab(activeTab - 1)}
        >
          {t("BACK")}
        </Button>
      )}
      <Button
        className="ml-auto"
        variant="contained"
        color="primary"
        disabled={(buttonStatus || saving) && activeTab !== 0}
        onClick={() =>
          activeTab === 5 ||
          (history.location.search.includes("USER") && activeTab === 3)
            ? submit()
            : onChangeTab(activeTab + 1)
        }
      >
        {history.location.search.includes("USER") && activeTab === 3
          ? t("REQUEST_NOW")
          : activeTab === 5
          ? t("FINISH")
          : t("NEXT")}
      </Button>
    </Box>
  );
};

export default CreateCaseActionsComponent;
