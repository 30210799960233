import { icons } from "../../../statics/icons";

export enum ROLES {
  DEVELOPER = "DEVELOPER",
  DISTRIBUTER = "DISTRIBUTER",
  IMPORTER = "IMPORTER",
  USER = "USER",
}

export enum PAGE_ROLES {
  PRODUCTS = "PRODUCTS",
  INVENTORY = "INVENTORY",
  USER = "USER",
}

export interface AIRole {
  id: string;
  title: string;
  subTitle: string;
  name: ROLES;
  icon: string;
  activeIcon: string;
  key: ROLES;
  disabled?: boolean;
  role?: PAGE_ROLES;
}

interface AIRoleKeys {
  DEVELOPER: AIRole;
  DISTRIBUTER: AIRole;
  IMPORTER: AIRole;
  USER: AIRole;
}

export const AIRolesByKey: any = {
  DEVELOPER: {
    title: "USECASE_CREATE.AI_DEVELOPER",
    subTitle: "USECASE_CREATE.DEVELOPER_OF_THE_AI_PRODUCT",
    name: ROLES.DEVELOPER,
    icon: icons.AIDeveloperPrimary,
    activeIcon: icons.AIDeveloperWhite,
    key: ROLES.DEVELOPER,
  },
  DISTRIBUTER: {
    title: "USECASE_CREATE.AI_DISTRIBUTER",
    subTitle: "USECASE_CREATE.DISTRIBUTES_AN_AI_PRODUCT",
    name: ROLES.DISTRIBUTER,
    icon: icons.AIDistributorPrimary,
    activeIcon: icons.AIDistributorWhite,
    key: ROLES.DISTRIBUTER,
  },
  IMPORTER: {
    title: "USECASE_CREATE.AI_IMPORTER",
    subTitle: "USECASE_CREATE.IMPORTER_AI_PRODUCT",
    name: ROLES.IMPORTER,
    icon: icons.AIImporterPrimary,
    activeIcon: icons.AIImporterWhite,
    key: ROLES.IMPORTER,
  },
  USER: {
    title: "USECASE_CREATE.AI_USER",
    subTitle: "USECASE_CREATE.COMPANIES_USING_AN_AI_PRODUCT",
    name: ROLES.USER,
    icon: icons.AIUserPrimary,
    activeIcon: icons.AIUserWhite,
    key: ROLES.USER,
  },
};

export const AIRolesList: AIRole[] = [
  {
    id: ROLES.DEVELOPER,
    title: "USECASE_CREATE.AI_DEVELOPER",
    subTitle: "USECASE_CREATE.DEVELOPER_OF_THE_AI_PRODUCT",
    name: ROLES.DEVELOPER,
    icon: icons.AIDeveloperPrimary,
    activeIcon: icons.AIDeveloperWhite,
    key: ROLES.DEVELOPER,
    role: PAGE_ROLES.PRODUCTS,
  },
  {
    id: ROLES.DISTRIBUTER,
    title: "USECASE_CREATE.AI_DISTRIBUTER",
    subTitle: "USECASE_CREATE.DISTRIBUTES_AN_AI_PRODUCT",
    name: ROLES.DISTRIBUTER,
    icon: icons.AIDistributorPrimary,
    activeIcon: icons.AIDistributorWhite,
    key: ROLES.DISTRIBUTER,
    role: PAGE_ROLES.INVENTORY,
  },
  {
    id: ROLES.IMPORTER,
    title: "USECASE_CREATE.AI_IMPORTER",
    subTitle: "USECASE_CREATE.IMPORTER_AI_PRODUCT",
    name: ROLES.IMPORTER,
    icon: icons.AIImporterPrimary,
    activeIcon: icons.AIImporterWhite,
    key: ROLES.IMPORTER,
    role: PAGE_ROLES.INVENTORY,
  },
  {
    id: ROLES.USER,
    title: "USECASE_CREATE.AI_USER",
    subTitle: "USECASE_CREATE.COMPANIES_USING_AN_AI_PRODUCT",
    name: ROLES.USER,
    icon: icons.AIUserPrimary,
    activeIcon: icons.AIUserWhite,
    key: ROLES.USER,
    role: PAGE_ROLES.USER,
  },
];
