import React, { useContext, useEffect, useState } from "react";
import "./scenario-tab-content.component.scoped.scss";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CaseContext } from "../../../../../store";
import { htmlTagRegex } from "../../../../../statics/validators";
import { Skeleton } from "@material-ui/lab";
import { FilesSectionComponent } from "./component/files-section/files-section.component";
import { TextEditorComponent } from "../../../../../components/text-editor/text-editor.component";

import "react-pdf/dist/umd/Page/AnnotationLayer.css";

import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ScenarioTabContentComponent = () => {
  const [t] = useTranslation();
  const {
    caseDetails,
    loading,
    updatedCaseDetails,
    setUpdatedCase,
    selectedFile,
  } = useContext(CaseContext);
  const [editMode, setEditMode] = useState(false);

  const ignoreUpdate = () => {
    updatedCaseDetails.title = caseDetails.title;
    updatedCaseDetails.brief = caseDetails.brief;
    updatedCaseDetails.scenarioDescription = caseDetails.scenarioDescription;
    updatedCaseDetails.link = caseDetails.link;
    setUpdatedCase(updatedCaseDetails);
    setEditMode(false);
  };

  const onChangeTextEditor = (scenarioDescription: string) => {
    setUpdatedCase({
      ...updatedCaseDetails,
      scenarioDescription,
    });
  };

  useEffect(() => {
    setEditMode(false);
  }, [caseDetails]);

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  return (
    <Box className="content">
      <Box className="h-100 left">
        <Box className="d-flex justify-content-between align-items-center">
          <Typography variant="h5" color="secondary">
            {t("USECASE_CREATE.SCENARIO_DESCRIPTION")}
          </Typography>
        </Box>
        <Box
          className={`details-section ${editMode ? "edit-mode" : ""}`}
          onClick={setEditMode.bind(null, true)}
        >
          <Box className="mt-3">
            {loading ? (
              <Skeleton variant="text" height={50} width="100%" />
            ) : (
              <>
                {editMode ? (
                  <TextField
                    className="w-100"
                    label={t("USECASE_CREATE.NAME_OF_YOUR_AI_PRODUCT")}
                    variant="filled"
                    name="ai-product"
                    value={updatedCaseDetails.title}
                    onChange={(e: any) =>
                      setUpdatedCase({
                        ...updatedCaseDetails,
                        title: e.target.value.replace(/^\s+/g, ""),
                      })
                    }
                    inputProps={{
                      maxLength: 30,
                    }}
                  />
                ) : (
                  <Box>
                    <Typography variant="subtitle2" style={{ fontWeight: 400 }}>
                      {t("USECASE_CREATE.NAME_OF_YOUR_AI_PRODUCT")}
                    </Typography>
                    <Typography variant="body1">{caseDetails.title}</Typography>
                  </Box>
                )}
              </>
            )}
          </Box>

          <Box className="mt-3">
            {loading ? (
              <Skeleton variant="text" height={200} width="100%" />
            ) : (
              <>
                {editMode ? (
                  <>
                    <TextField
                      id="filled-multiline-flexible"
                      value={updatedCaseDetails.brief}
                      label={t("SHORT_DESCRIPTION")}
                      onChange={(e: any) =>
                        setUpdatedCase({
                          ...updatedCaseDetails,
                          brief: e.target.value.replace(/^\s+/g, ""),
                        })
                      }
                      variant="filled"
                      className="text-area-multiline w-100"
                      fullWidth={true}
                      rows={5}
                      multiline
                      inputProps={{
                        maxLength: 250,
                      }}
                    />

                    <Box className="hint d-flex justify-content-between align-items-center">
                      {loading ? (
                        <Skeleton variant="text" height={20} width={300} />
                      ) : (
                        <span>
                          {t("USECASE_CREATE.SHORT_DESCRIPTION_HINT")}
                        </span>
                      )}
                      {loading ? (
                        <Skeleton variant="text" height={20} width={150} />
                      ) : (
                        <span>
                          {updatedCaseDetails.brief.length}/{250}{" "}
                          {t("character")}
                        </span>
                      )}
                    </Box>
                  </>
                ) : (
                  <Box>
                    <Typography variant="subtitle2" style={{ fontWeight: 400 }}>
                      {t("SHORT_DESCRIPTION")}
                    </Typography>
                    <Typography variant="body1">{caseDetails.brief}</Typography>
                  </Box>
                )}
              </>
            )}
          </Box>

          <Box className="mt-3">
            {editMode ? (
              <Box>
                <TextEditorComponent
                  id="senario-description"
                  value={caseDetails.scenarioDescription}
                  onChange={onChangeTextEditor}
                  error={
                    updatedCaseDetails.scenarioDescription.replace(
                      htmlTagRegex,
                      ""
                    ).length > 800
                  }
                />

                <Box className="hint d-flex justify-content-between align-items-center">
                  <span>{t("USECASE_CREATE.SCENARIO_DESCRIPTION_HINT")}</span>
                  <span>
                    {
                      updatedCaseDetails.scenarioDescription.replace(
                        htmlTagRegex,
                        ""
                      ).length
                    }
                    /{800} {t("character")}
                  </span>
                </Box>
              </Box>
            ) : (
              <>
                {loading ? (
                  <Skeleton variant="text" height={200} width="100%" />
                ) : (
                  <Box>
                    <Typography variant="subtitle2" style={{ fontWeight: 400 }}>
                      {t("SUMMARY")}
                    </Typography>
                    <Typography
                      variant="body1"
                      dangerouslySetInnerHTML={{
                        __html: caseDetails.scenarioDescription,
                      }}
                    ></Typography>
                  </Box>
                )}
              </>
            )}
          </Box>

          {/* <Box className="mt-3">
          {loading ? (
            <Skeleton variant="text" height={50} width="100%" />
          ) : (
            <>
              {editMode ? (
                <>
                  <TextField
                    className="w-100"
                    label="Link (optional)"
                    placeholder="e.g. https://www.atlassian.com"
                    variant="filled"
                    error={linkError}
                    name="ai-product"
                    value={updatedCaseDetails.link}
                    onChange={(e: any) => {
                      setUpdatedCase({
                        ...updatedCaseDetails,
                        link: e.target.value,
                      });
                      e.target.value
                        ? setLinkError(!urlRegex.test(e.target.value))
                        : setLinkError(false);
                    }}
                  />
                  <Box className="hint d-flex justify-content-between align-items-center">
                    {loading ? (
                      <Skeleton variant="text" height={20} width={300} />
                    ) : (
                      <span>{t("USECASE_CREATE.SCENARIO_LINK_HINT")}</span>
                    )}
                  </Box>
                </>
              ) : (
                <>
                  {caseDetails.link && (
                    <Box>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 400 }}
                      >
                        Link
                      </Typography>
                      <Typography variant="body1">
                        {caseDetails.link}
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </Box> */}
        </Box>

        {editMode && (
          <Box className="actions mt-3 text-right">
            <Button
              className="btn-secondary-theme"
              variant="outlined"
              color="primary"
              size="medium"
              onClick={ignoreUpdate}
            >
              {t("CANCEL")}
            </Button>
          </Box>
        )}
        <Box className="mt-4 files-section">
          <Typography variant="h5" color="secondary">
            {t("USECASE_DETAILS.AI_CONCEPT_FILE")}
          </Typography>
          <Box className="files h-100">
            <FilesSectionComponent />
          </Box>
        </Box>
      </Box>

      <Box className="h-100 right">
        {selectedFile && (
          <Box className="pdf-content">
            {selectedFile && selectedFile.type !== "pdf" ? (
              <img src={selectedFile.url} alt="" />
            ) : (
              <Document
                file={selectedFile.url}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={700}
                    className="mb-2 w-100"
                  />
                ))}
              </Document>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ScenarioTabContentComponent;
