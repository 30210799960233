import React, { useEffect, useContext } from "react";
import "./dashboard-content.component.scoped.scss";
// import { DashboardService } from "../../services/dashboard/dashboard.service";
import DashboardFirstBlock from "../dashboard-first-block/dashboard-first-block.component";
import DashboardSecondBlock from "../dashboard-second-block/dashboard-second-block.component";
import DashboardThirdBlock from "../dashboard-third-block/dashboard-third-block.component";
import { GlobalContext } from "../../../../store";

export default function DashboardContent() {
  const { helpData, setHelpContent } = useContext(GlobalContext);
  const handleCardsChange = (orderedCards: any[]) => {};

  useEffect(() => {
    setHelpContent(helpData.Dashboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="dashboard">
      <div className="theme-container dashboard-content">
        <DashboardFirstBlock onCardsChange={handleCardsChange} />
        <DashboardSecondBlock onCardsChange={handleCardsChange} />
        <DashboardThirdBlock onCardsChange={handleCardsChange} />
      </div>
    </div>
  );
}
