import React, { useContext, useEffect, useState } from "react";
import "./workflow-timeline.component.scoped.scss";
import {
  Button,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Box,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CaseContext, GlobalContext } from "../../../../../../../store";
import { useTranslation } from "react-i18next";
import { CasesService } from "../../../../../../../services/cases/cases.service";
import { MapperService } from "../../../../../../../services/mapper/mapper.service";
import { Question } from "../../../../../../../types/question";
import { TimelineStatusesComponent } from "../../../../../../../components/timeline-statuses/timeline-statuses.component";
import { CaseWorkflow } from "../../../../../../../types/case-workflow";
import WorkflowChangeStatus from "../../../../../../../components/workflow-change-status/workflow-change-status.component";
import WorkflowTable from "../workflow-table/workflow-table.component";

export const WorkflowTimelineComponent = () => {
  const {
    setCase,
    setWorkflowLoading,
    workflowLoading,
    caseDetails,
    caseStatuses,
    caseQuestions,
    setCaseQuestions,
    setCaseStatuses,
    caseFiles,
  } = useContext(CaseContext);
  const { user } = useContext(GlobalContext);
  const [t] = useTranslation();
  const casesService = new CasesService();
  const mapperService: MapperService = new MapperService();
  const [saving, setSaving] = useState(false);
  const [selectedStatues, setSelectedStatues] = useState("");

  // Next and Previous status Buttons in every status.
  const setAvailableStatus = (statuses: any[]) => {
    if (statuses && statuses.length) {
      statuses.forEach((item: any, index: number) => {
        item.prevStatus = index === 0 ? null : { ...statuses[index - 1] };
        item.nextStatus =
          index + 1 === statuses.length ? null : { ...statuses[index + 1] };
      });
    }
    return statuses;
  };

  const [timelineStatuses, setTimelineStatuses] = useState(
    setAvailableStatus(caseStatuses)
  );

  const isActiveStatus = (status: string): boolean => {
    return caseDetails.statusKey === status;
  };

  const expandStatusComments = (expand: boolean, index: number): void => {
    const timelineStatusesCopy = [...timelineStatuses];
    timelineStatusesCopy[index].expand = expand;
    setTimelineStatuses(timelineStatusesCopy);
  };

  const onUpdateQuestionsStatus = (questions: Question[], index: number) => {
    let caseQuestionCopy = [...caseQuestions];
    caseQuestionCopy[index].questionsStatus = questions.filter(
      (item: Question) => item.answer === null
    ).length
      ? 0
      : 1;
    caseQuestionCopy[index].questions = questions;
    setCaseQuestions(caseQuestionCopy);
  };

  const fetchCaseDetails = async (id: number) => {
    setSaving(true);
    const caseDetailsData = await casesService.details(id);
    const { mappedCase, mappedtimelineStatuses } = mapperService.mapCase(
      caseDetailsData,
      caseStatuses
    );
    setCase(mappedCase);
    setCaseStatuses(mappedtimelineStatuses);
    setSaving(false);
  };

  const fetchCaseQuestions = async (id: number) => {
    setSaving(true);
    setWorkflowLoading(true);
    const caseQuestions = await casesService.caseQuestions(id);
    setTimeout(() => {
      setCaseQuestions(caseQuestions);
      setWorkflowLoading(false);
    }, 100);
    setSaving(false);
  };

  const updateCase = () => {
    setSaving(true);
    fetchCaseDetails(caseDetails.id);
    setTimeout(() => {
      fetchCaseQuestions(caseDetails.id);
    }, 100);
    setSaving(false);
  };

  const changeStatus = async (status: any) => {
    setSaving(true);
    await casesService.updateWorkflowStatus(caseDetails.id, {
      statusId: status.id,
    });
    setSelectedStatues("");
    updateCase();
    setSaving(false);
  };

  useEffect(() => {
    setTimelineStatuses(setAvailableStatus(caseStatuses));
  }, [caseStatuses]);

  return (
    <div className="timeline">
      <div className="head">
        <Box className="title">
          {" "}
          {t("checklist")} {caseDetails.category.name}
        </Box>
        <Box className="right-side">
          {workflowLoading ? (
            <Skeleton variant="text" height={50} width="20rem" />
          ) : (
            <div className="statuses mt-2">
              <FormControl className="select-input" style={{ width: "18rem" }}>
                <InputLabel id="status">{t("SELECT_STATUS")}</InputLabel>
                <Select
                  labelId="status"
                  id="status-select"
                  value={selectedStatues}
                  name="status"
                  onChange={(e: any) => setSelectedStatues(e.target.value)}
                  IconComponent={ExpandMoreIcon}
                  required
                  disabled={user.role === "auditor"}
                >
                  {caseStatuses.map((item: any, index: any) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Button
                className="btn-primary-theme"
                size="medium"
                variant="contained"
                color="primary"
                onClick={() => changeStatus(selectedStatues)}
                disabled={saving || !selectedStatues || user.role === "auditor"}
              >
                {t("CHANGE_STATUS")}
              </Button>
            </div>
          )}
        </Box>
      </div>
      <div className="timelines-content">
        <div className="timeline-items">
          {caseQuestions.map((caseQuestion: CaseWorkflow, index: any) => {
            const questions: any[] =
              !timelineStatuses[index]?.expand && !workflowLoading
                ? []
                : caseQuestion.questions;
            return (
              <TimelineStatusesComponent
                key={caseQuestion.id}
                questionsStatus={caseQuestion.questionsStatus}
                activeStatus={isActiveStatus(caseQuestion.statusKey)}
                lastStatus={index === timelineStatuses.length - 1}
                icon={timelineStatuses[index]?.icon}
                status={caseQuestion.status}
                date={caseQuestion.date}
                loading={workflowLoading}
                expand={timelineStatuses[index]?.expand}
                handleExpand={(value: boolean) =>
                  expandStatusComments(value, index)
                }
                dimmed={timelineStatuses[index]?.dimmed}
                tab={"workflow"}
              >
                {questions && questions.length ? (
                  <WorkflowTable
                    questions={questions}
                    loading={workflowLoading}
                    caseQuestionId={caseQuestion.id}
                    files={caseFiles}
                    statusId={caseDetails.statusId}
                    caseDetails={caseDetails}
                    selectedStatus={
                      caseDetails.statusKey === caseQuestion.statusKey
                    }
                    onChangeQuestion={(value: Question[]) =>
                      onUpdateQuestionsStatus(value, index)
                    }
                    onUpdateCase={updateCase}
                    user={user}
                  />
                ) : null}
                {!workflowLoading &&
                questions &&
                questions.length &&
                caseDetails.statusKey === caseQuestion.statusKey ? (
                  <WorkflowChangeStatus
                    prevStatus={timelineStatuses[index]?.prevStatus}
                    nextStatus={timelineStatuses[index]?.nextStatus}
                    caseId={caseDetails.id}
                  />
                ) : null}
              </TimelineStatusesComponent>
            );
          })}
        </div>
      </div>
    </div>
  );
};
