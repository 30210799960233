import React from "react";
import "./setting-section-title.component.scoped.scss";
import { Box } from "@material-ui/core";

const SettingSectionTitleComponent: React.FunctionComponent<{
  title: string;
  subTitle: string;
}> = ({ title, subTitle }) => {
  return (
    <Box className="section-info">
      <h3>{title}</h3>
      <p>{subTitle}</p>
    </Box>
  );
};

export default SettingSectionTitleComponent;
