import React from "react";
import { useTranslation } from "react-i18next";
import { CommonService } from "../../../services/common/common.service";
import { TranslationManagementContent } from "../../../modules/settings/components/translation-management/translation-management-content/translation-management-content.component";
import SettingsHead from "../../../modules/settings/shared/settings-head/settings-head.component";

export default function TranslationManagementPage() {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  commonService.setTitle(t("database value management"));

  return (
    <>
      <div className="role-contain">
        <div className="content">
          <SettingsHead
            title={t("database value management")}
            subTitle={t("translate database entries")}
          />
          <TranslationManagementContent />
        </div>
      </div>
    </>
  );
}
