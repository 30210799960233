import React from "react";
import { useTranslation } from "react-i18next";
import { CommonService } from "../../../services/common/common.service";
import { WorkflowManagementContent } from "../../../modules/settings/components/workflow-management/workflow-management-content/workflow-management-content.component";
import SettingsHead from "../../../modules/settings/shared/settings-head/settings-head.component";

export default function WorkflowManagementPage() {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  commonService.setTitle(t("workflow management"));

  return (
    <>
      <div className="role-contain">
        <div className="content">
          <SettingsHead
            title={t("workflow management")}
            subTitle={t("organize your workflows here")}
          />
          <WorkflowManagementContent />
        </div>
      </div>
    </>
  );
}
