const comments = () => {
  const comments = [1, 2, 3].map(() => {
    return {
      user: "",
    };
  });
  return comments;
};

export const dummyCommunication = [
  {
    status: "Planning / Strategy",
    comments: comments(),
  },
  {
    status: "Concept",
    comments: comments(),
  },
  {
    status: "Data acquisition and preparation",
    comments: comments(),
  },
  {
    status: "Model development",
    comments: comments(),
  },
  {
    status: "Model training",
    comments: comments(),
  },
  {
    status: "Model validation",
    comments: comments(),
  },
  {
    status: "Model testing",
    comments: comments(),
  },
  {
    status: "Deployment and integration",
    comments: comments(),
  },
  {
    status: "Release / Go-life",
    comments: comments(),
  },
  {
    status: "Monitoring (incl. controls?)",
    comments: comments(),
  },
  {
    status: "Maintenance - Keep the system up to date",
    comments: comments(),
  },
  {
    status: "Retirement and/or redefinition",
    comments: comments(),
  },
  {
    status: "Redefinition",
    comments: comments(),
  },
];
