import React, { useState, useEffect } from "react";
import "./dashboard-second-block.component.scoped.scss";
import { useTranslation } from "react-i18next";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import DashboardGraphCard from "../dashboard-graph-card/dashboard-graph-card.component";
import { DashboardService } from "../../../../services/dashboard/dashboard.service";
import moment from "moment";

export default function DashboardSecondBlock(props: any) {
  const dashboardService = new DashboardService();
  const [t] = useTranslation();
  const emptyArr: any = [];
  const [cards, setCards] = useState(emptyArr);

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const orderedCards = reorder(
      cards,
      result.source.index,
      result.destination.index
    );
    setCards(orderedCards);
    props.onCardsChange(orderedCards);
  };

  const durationChange = async (duration: any, chartTitle: string) => {
    if (chartTitle === "RISK_LEVELS") {
      const newChartData = await dashboardService.getCategoriesChartData({
        from: duration.from,
        to: duration.to,
      });
      const newCards = [...cards];
      newCards.map((item: any) => {
        if (item.title === chartTitle) {
          item.chart.data = newChartData.chart.data;
          item.chart.labels = newChartData.chart.labels;
        }
        return item;
      });
      setCards(newCards);
    } else if (chartTitle === "status") {
      const newChartData = await dashboardService.getStatusChartData({
        from: duration.from,
        to: duration.to,
      });
      const newCards = [...cards];
      newCards.map((item: any) => {
        if (item.title === chartTitle) {
          item.chart.data = newChartData.chart.data;
          item.chart.labels = newChartData.chart.labels;
        }
        return item;
      });
      setCards(newCards);
    }
  };

  async function fetchData() {
    // const barChartData = await dashboardService.getBarChartData({
    //   from: moment().subtract(6, "months").format("YYYY-MM-DD"),
    //   to: moment().add(1, "days").format("YYYY-MM-DD"),
    // });

    const statusChartData = await dashboardService.getStatusChartData({
      from: moment().subtract(2, "months").format("YYYY-MM-DD"),
      to: moment().add(1, "days").format("YYYY-MM-DD"),
    });

    const categoriesChartData = await dashboardService.getCategoriesChartData({
      from: moment().subtract(2, "months").format("YYYY-MM-DD"),
      to: moment().add(1, "days").format("YYYY-MM-DD"),
    });
    setCards([statusChartData, categoriesChartData]);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list" direction="horizontal">
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="row mb-5"
          >
            {cards.map((card: any, index: number) => {
              return (
                <Draggable key={card.id} draggableId={card.id} index={index}>
                  {(
                    provided: DraggableProvided,
                    snapshot: DraggableStateSnapshot
                  ) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="col-6 card-container"
                    >
                      <DashboardGraphCard
                        title={t(card.title)}
                        subTitle={t(card.subTitle)}
                        chart={card.chart}
                        isDraggable={true}
                        isDragging={snapshot.isDragging}
                        type={card.type}
                        durationChange={(duration: any) =>
                          durationChange(duration, card.title)
                        }
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
