import { TableColumn } from "../../types/table-column";

export const teamColumns: TableColumn[] = [
  {
    type: "client",
    fieldName: "NAME",
    value: "name",
    secondaryValue: "group",
    width: "20%",
  },
  {
    type: "email",
    fieldName: "EMAIL",
    value: "email",
    width: "23%",
  },
  {
    type: "date",
    fieldName: "INVITATION_DATE",
    value: "created_at",
    width: "14%",
  },
  {
    type: "keyContact",
    fieldName: "KEY_CONTACT",
    value: "keyContact",
    width: "14%",
  },
];
