import "./change-password.scoped.scss";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";
import { UserService } from "../../services/user/user.service";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  passwordRegex,
  capitalChar,
  digitChar,
  specialChar,
} from "../../statics/validators";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import LockOutlined from "@material-ui/icons/LockOutlined";
import { GlobalContext } from "../../store";
import { showToastMsg } from "../../helpers";

const ChangePassword = (props: any) => {
  const [t] = useTranslation();
  const userService = new UserService();

  const { user } = useContext(GlobalContext);

  const [saving, setSaving] = useState(false);
  const password: any = {
    newPassword: "",
    confirmation: "",
  };
  const visibility: any = {
    newPassword: false,
    confirmation: false,
  };
  const [passwordVisibility, setPasswordVisibility] = useState({
    ...visibility,
  });
  const [passwordForm, setPasswordForm] = useState({ ...password });
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [capsLock, setCapsLock] = useState(false);

  const handleClose = (value: any) => {
    props.onClose(value);
    setPasswordVisibility({ ...visibility });
    setPasswordForm({ ...password });
    setPasswordMatchError(false);
    setCapsLock(false);
  };

  const handlePasswordChange = (event: any) => {
    if (event && event.target) {
      const name = event.target.name;
      const value = event.target.value;
      const obj: any = {};
      obj[name] = value;
      setPasswordForm({ ...passwordForm, ...obj });
      setPasswordMatchError(false);
      if (name === "confirmation" && value) {
        setPasswordMatchError(value !== passwordForm.newPassword);
      } else if (name === "newPassword" && value && passwordForm.confirmation) {
        setPasswordMatchError(value !== passwordForm.confirmation);
      }
    }
  };

  async function updatePassword() {
    if (saving) return;
    setSaving(true);
    const obj = {
      keycloakUserId: user.keycloakUserId,
      password: passwordForm.newPassword,
    };
    const response = await userService.changePassword(obj);
    setSaving(false);
    if (response && response.message === "success") {
      handleClose("updated");
    } else {
      showToastMsg("error", "Something went wrong");
    }
  }

  const togglePasswordVisibility = (type: string) => {
    const obj: any = {};
    obj[type] = !passwordVisibility[type];
    setPasswordVisibility({ ...passwordVisibility, ...obj });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onKeyDown = (e: any) => {
    if (e.getModifierState("CapsLock")) {
      setCapsLock(true);
    } else {
      setCapsLock(false);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={handleClose.bind(null, false)}
      centered
      dialogClassName="modal-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("change password")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="password-form">
          <div className="password-inputs">
            <div className="w-100 mb-3 input-content">
              <LockOutlined />
              <TextField
                className="w-100"
                variant="filled"
                type={passwordVisibility.newPassword ? "text" : "password"}
                label={t("new password")}
                value={passwordForm.newPassword}
                autoComplete="off"
                name="newPassword"
                onChange={handlePasswordChange}
                onKeyDown={onKeyDown}
                autoFocus={false}
                inputProps={{
                  minLength: 8,
                }}
                InputProps={{
                  autoComplete: "one-time-code",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className="toggle-password"
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility.bind(
                          null,
                          "newPassword"
                        )}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {passwordVisibility.newPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="w-100 input-content">
              <LockOutlined />
              <TextField
                className="w-100"
                variant="filled"
                type={passwordVisibility.confirmation ? "text" : "password"}
                label={t("confirm password")}
                value={passwordForm.confirmation}
                autoComplete="off"
                name="confirmation"
                onChange={handlePasswordChange}
                onKeyDown={onKeyDown}
                autoFocus={false}
                error={passwordMatchError ? true : false}
                inputProps={{
                  minLength: 8,
                }}
                InputProps={{
                  autoComplete: "one-time-code",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className="toggle-password"
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility.bind(
                          null,
                          "confirmation"
                        )}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {passwordVisibility.confirmation ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            {capsLock && (
              <div className="error-msg capsLock">
                {t("caps lock is turned on")}
              </div>
            )}
          </div>
          <div className="password-validation">
            <p
              style={{
                color: passwordForm.newPassword
                  ? passwordForm.newPassword.length < 8 ||
                    passwordForm.newPassword.length > 15
                    ? "#CA310B"
                    : "#a6ba8d"
                  : "",
              }}
            >
              <CheckCircleOutlineIcon />
              {t("length 8 to 15 characters")}
            </p>
            <p
              style={{
                color: passwordForm.newPassword
                  ? specialChar.test(passwordForm.newPassword)
                    ? "#a6ba8d"
                    : "#CA310B"
                  : "",
              }}
            >
              <CheckCircleOutlineIcon />
              {t("at least 1 special character")}
            </p>
            <p
              style={{
                color: passwordForm.newPassword
                  ? capitalChar.test(passwordForm.newPassword)
                    ? "#a6ba8d"
                    : "#CA310B"
                  : "",
              }}
            >
              <CheckCircleOutlineIcon />
              {t("at least 1 capital letter")}
            </p>
            <p
              style={{
                color: passwordForm.newPassword
                  ? digitChar.test(passwordForm.newPassword)
                    ? "#a6ba8d"
                    : "#CA310B"
                  : "",
              }}
            >
              <CheckCircleOutlineIcon />
              {t("at least 1 digit")}
            </p>
            <p
              style={{
                color: passwordForm.confirmation
                  ? passwordMatchError
                    ? "#CA310B"
                    : "#a6ba8d"
                  : "",
              }}
            >
              <CheckCircleOutlineIcon />
              {t("passwords do not match")}
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outlined"
          color="primary"
          className="btn-secondary-theme"
          onClick={handleClose.bind(null, false)}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="btn-primary-theme"
          onClick={updatePassword}
          disabled={
            passwordMatchError ||
            !(passwordForm.newPassword && passwordForm.confirmation) ||
            !passwordRegex.test(passwordForm.newPassword)
          }
        >
          {saving ? (
            <CircularProgress color="inherit" />
          ) : (
            <>
              <CheckIcon /> {t("save")}{" "}
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePassword;
