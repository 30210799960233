import { TableColumn } from "../../types/table-column";

export const newCommentsColumns: TableColumn[] = [
  {
    type: "case",
    fieldName: "AI_PRODUCT",
    value: "title",
    secondaryValue: "internalId",
    width: "25%",
  },
  {
    type: "string",
    fieldName: "USER",
    value: "name",
    width: "15%",
  },
  {
    type: "comment",
    fieldName: "LATEST_COMMENT",
    value: "comment",
    width: "40%",
  },
  {
    type: "date",
    fieldName: "DATE",
    value: "created_at",
    width: "13%",
  },
];
