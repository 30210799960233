import React, { useEffect, useState } from "react";
import "./sign-up-content.component.scoped.scss";
import { Trans, useTranslation } from "react-i18next";
import { InputComponent } from "../../../../components/input/input.component";
import { Button, CircularProgress } from "@material-ui/core";
import { CommonService } from "../../../../services/common/common.service";
import { history } from "react-router-guard";
import { UserService } from "../../../../services/user/user.service";
import {
  capitalChar,
  digitChar,
  specialChar,
  passwordRegex,
} from "../../../../statics/validators";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Checkbox from "@material-ui/core/Checkbox";

const commonService: CommonService = new CommonService();
const userService: UserService = new UserService();

export const SignUpContentComponent = ({ token, tenantId }: any) => {
  const [t] = useTranslation();
  commonService.setTitle(t("Case Management"));
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState("");
  const [tokenIsValid, setTokenIsValid] = useState(true);
  const [tokenValidityLoading, setTokenValidityLoading] = useState(false);
  const [capsLock, setCapsLock] = useState(false);

  const linkExpiredImage = "/Lost-connection.png";

  const inputPassword = (event: any) => {
    const value = event.target.value;
    setNewPassword(value);
    if (!passwordRegex.test(value)) {
      setNewPasswordError("Die Mindestlänge des Passworts beträgt 8 Zeichen");
    } else {
      setNewPasswordError("");
    }

    if (value && confirmation && value !== confirmation) {
      setPasswordMatchError(true);
    } else {
      setPasswordMatchError(false);
    }
  };

  const inputConfirmation = (event: any) => {
    const value = event.target.value;
    setConfirmation(value);
    if (value && newPassword && value !== newPassword) {
      setPasswordMatchError(true);
    } else {
      setPasswordMatchError(false);
    }
  };

  const setPassword = async () => {
    try {
      setLoading(true);
      await userService.verifyNewUser(
        { keycloakUserId: token, password: newPassword.toString() },
        tenantId
      );
      history.push("/login");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const canUserSetPassword = async (token: any) => {
    setTokenValidityLoading(true);
    const response = await userService.checkTokenValidity(
      {
        token: token,
      },
      tenantId
    );
    if (response && response.validity === "valid") {
      setTokenIsValid(true);
    } else if (response && response.validity === "invalid") {
      setTokenIsValid(false);
    } else if (
      response &&
      response.validity === "user already has a password"
    ) {
      await userService.verifyNewUser({ keycloakUserId: token }, tenantId);
      history.push("/login");
    } else {
      setTokenIsValid(false);
    }
    setTokenValidityLoading(false);
  };

  const handleKeyDown = (e: any) => {
    if (
      e.key === "Enter" &&
      !passwordMatchError &&
      !newPasswordError &&
      newPassword.length >= 8 &&
      confirmation.length >= 8 &&
      termsChecked
    ) {
      setPassword();
    }
  };

  useEffect(() => {
    canUserSetPassword(token);
    // eslint-disable-next-line
  }, []);

  if (tokenValidityLoading) {
    return <CircularProgress />;
  }

  const onKeyDown = (e: any) => {
    if (e.getModifierState("CapsLock")) {
      setCapsLock(true);
    } else {
      setCapsLock(false);
    }
  };

  return (
    <div className="normal-login">
      {!tokenValidityLoading && tokenIsValid ? (
        <>
          <div className="label">{t("Set Your Password")}</div>
          <form
            className="login-form"
            noValidate
            autoComplete="off"
            onKeyDown={handleKeyDown}
          >
            <InputComponent
              label="create password"
              icon="password"
              type="password"
              validate={inputPassword}
              focused={true}
              onKeyDown={onKeyDown}
              // error={newPasswordError}
            />
            <InputComponent
              label="password wiederholen"
              icon="password"
              type="password"
              validate={inputConfirmation}
              onKeyDown={onKeyDown}
              // error={confirmationError}
            />
            {capsLock && (
              <div className="error-msg capsLock">
                {t("caps lock is turned on")}
              </div>
            )}

            <div className="password-validation">
              <p
                style={{
                  color: newPassword
                    ? newPassword.length < 8 || newPassword.length > 15
                      ? "#CA310B"
                      : "#a6ba8d"
                    : "",
                }}
              >
                <CheckCircleOutlineIcon />
                {t("length 8 to 15 characters")}
              </p>
              <p
                style={{
                  color: newPassword
                    ? specialChar.test(newPassword)
                      ? "#a6ba8d"
                      : "#CA310B"
                    : "",
                }}
              >
                <CheckCircleOutlineIcon />
                {t("at least 1 special character")}
              </p>
              <p
                style={{
                  color: newPassword
                    ? capitalChar.test(newPassword)
                      ? "#a6ba8d"
                      : "#CA310B"
                    : "",
                }}
              >
                <CheckCircleOutlineIcon />
                {t("at least 1 capital letter")}
              </p>
              <p
                style={{
                  color: newPassword
                    ? digitChar.test(newPassword)
                      ? "#a6ba8d"
                      : "#CA310B"
                    : "",
                }}
              >
                <CheckCircleOutlineIcon />
                {t("at least 1 digit")}
              </p>
              <p
                style={{
                  color: confirmation
                    ? passwordMatchError
                      ? "#CA310B"
                      : "#a6ba8d"
                    : "",
                }}
              >
                <CheckCircleOutlineIcon />
                {t("passwords do not match")}
              </p>
            </div>
            <div className="terms">
              <Checkbox
                checked={termsChecked}
                onChange={(e) => setTermsChecked(!termsChecked)}
                name="termsConditions"
                size="small"
              />
              <p>
                <Trans i18nKey="privacy-policy-desc">
                  By creating an account, you automatically agree to the{" "}
                  <a
                    href="https://compliance2b.at/datenschutz/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </Trans>{" "}
                *
              </p>
            </div>
            <Button
              variant="contained"
              className="btn btn-primary-theme"
              color="primary"
              onClick={setPassword}
              disabled={
                passwordMatchError ||
                loading ||
                newPasswordError.length > 0 ||
                !newPassword ||
                !confirmation ||
                !termsChecked
              }
            >
              <span className="login-btn-content">
                {!loading && t("Save & login")}
                {loading && <CircularProgress color="primary" />}
              </span>
            </Button>
          </form>
        </>
      ) : (
        <>
          <img
            src={linkExpiredImage}
            className="link-expired-img"
            alt="outdated-link"
          />
          <p className="link-expired-text">
            Sorry, but this link is broken or outdated!
          </p>
        </>
      )}
    </div>
  );
};
